import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Link, useHistory, useParams } from 'react-router-dom'
import { bulkUpload } from '../../api'
import { useAuth } from '../../auth'
import { getTypes } from '../../api'
import TypeSelector from './TypeSelector'
import ChoiceSelector from './ChoiceSelector'
import { OrgScopeRequired, useOrgScope } from '../../org-scope'

const BulkUpload = ({ inputTypes = [] }) => {
  const { token } = useAuth()
  const history = useHistory()
  const { byID } = useOrgScope()
  const { scope } = useParams() // Scope that we're editing allow/deny for, e.g. merchant ID
  const [saving, setSaving] = useState(false)
  const [failed, setFailed] = useState(null)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState()
  const [choice, setChoice] = useState()
  const [ignoreHeader, setIgnoreHeader] = useState(false)
  const [file, setFile] = useState()
  const [types, setTypes] = useState(inputTypes)
  const [fileInputKey, setFileInputKey] = useState(Date.now())

  const fetchTypes = async () => {
    const result = await getTypes(token)
    setTypes(result)
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    if (types.length === 0) {
      setLoading(true)
      fetchTypes()
      setLoading(false)
    }
  }, [inputTypes])
  const bulkUploadEntries = async () => {
    setFailed(false)
    setSaving(true)
    try {
      const response = await bulkUpload(token, { scope, type, choice, ignoreHeader, file })
      // if there were any failures during the import show details here, otherwise route to entries page
      if (response?.total_failed && response?.total_failed !== 0) {
        setFileInputKey(Date.now()) // force rerender of file input clearing its current value
        setFile(null) // need to clear out selected file otherwise reported content sizes will be incorrect resulting in timeouts.
        setFailed(
          new Error(
            `There ${
              response?.total_failed === 1
                ? 'was 1 failed entry:'
                : `were ${response?.total_failed} failed entries:`
            } ${
              response.failed_matches
            }. Please try importing these individually for more details. ${
              response?.total_success === 1
                ? '1 entry was imported successfully.'
                : response?.total_success > 1
                ? `${response?.total_success} entries imported successfully.`
                : ''
            }`
          )
        )
      } else {
        history.push(`/settings/allowdeny/${response.scope}`)
      }
    } catch (e) {
      setFileInputKey(Date.now()) // force rerender of file input clearing its current value
      setFile(null) // need to clear out selected file otherwise reported content sizes will be incorrect resulting in timeouts.
      setFailed(e)
    }
    setSaving(false)
  }

  // we only support these types for now, so filtering out the rest
  const availableTypes =
    (types && [
      ...types.filter((t) => t.type === 'pan_trunc' || t.type === 'email'),
      { type: 'pan_full', description: 'Full PAN (added to vault)' },
    ]) ||
    []

  return (
    <OrgScopeRequired>
      <section className='allowdeny'>
        <Helmet>
          <title>Allow / Deny upload - Canapay</title>
        </Helmet>
        <header>
          <div className='main'>
            <h1>Upload to Allow / Deny List </h1>
          </div>
        </header>
        <div>
          Here, you may upload a file containing either customer email addresses, or Card PANs. Note
          that the file should only contain email addresses or PANs exclusively, not a mixture of
          both, and there should be only one on each line. This will add the entries in the file to
          your existing Allow / Deny List, and does not remove any entries that were already set.
        </div>

        <div className='content'>
          <div className='allowdeny'>
            <table className='allowdeny'>
              <tbody>
                <tr className='merchant_contract' hidden={'root' == byID?.[scope]?.type}>
                  <td className='key'>{byID?.[scope]?.type}</td>
                  <td className='val'>
                    {/*just a label indicating which merchant/contract this is for, could potentially in time import for another one but that's a bit strange*/}
                    {byID?.[scope]?.name}
                  </td>
                  <td>The individual merchant or contract to which the list will be applied.</td>
                </tr>
                <tr className='choice'>
                  <td className='key'>Choice</td>
                  <td className='val'>
                    <ChoiceSelector value={choice} onChange={setChoice} />
                  </td>
                  <td>
                    All the entries in the file will collectively be treated as Allow or Deny.
                  </td>
                </tr>
                <tr className='type'>
                  <td className='key'>Type</td>
                  <td className='val'>
                    <TypeSelector types={availableTypes} value={type} onChange={setType} />
                  </td>
                  <td>Please select one of the allowed types (e.g. customer's email address).</td>
                </tr>
                <tr className='header'>
                  <td className='key'>Ignore Header</td>
                  <td className='val'>
                    <input
                      type={'checkbox'}
                      checked={ignoreHeader}
                      onChange={(e) => setIgnoreHeader(e.target.checked)}
                    />
                  </td>
                  <td>
                    When checked, we consider the first record as a column header and not a data
                    record, therefore any data in the header will be ignored.
                  </td>
                </tr>
                <tr className='file'>
                  <td className='key'>File</td>
                  <td className='val'>
                    <input
                      key={fileInputKey}
                      type='file'
                      accept={'.csv,.xlsx'}
                      onChange={(e) => setFile(e.target.files[0])} // first
                    />
                  </td>
                  <td>We support .csv or .xlsx file formats, for example from Microsoft Excel.</td>
                </tr>
              </tbody>
            </table>
            {file &&
              file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                <p className={'note'}>
                  Excel files are expected to have a single sheet with the first column populated
                  with data.
                </p>
              )}
            {file && file.type === 'text/csv' && (
              <p className={'note'}>
                CSV files are expected to have the first column populated with data.
              </p>
            )}
            {saving && <p className='saving'>Importing, please wait...</p>}
            {loading && <p className='loading'>Loading data...</p>}
            {failed && (
              <p className='error'>
                {failed && failed.message ? (
                  <span>{failed.message}</span>
                ) : (
                  <span>
                    Something went wrong - please try again, or{' '}
                    <Link to='/support'>contact support</Link>
                  </span>
                )}
              </p>
            )}

            <footer className='actions'>
              <button
                onClick={bulkUploadEntries}
                disabled={saving || loading || !type || !choice || !file}
              >
                <i className='fas fa-upload' /> Upload and import
              </button>
            </footer>
          </div>
        </div>
      </section>
    </OrgScopeRequired>
  )
}

export default BulkUpload
