import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'

/** AmountFilter lets the user pick one or more countries. Very simple for now, text input. Needs
 * a better widget.
 */
const AmountFilter = ({ filter, setFilter, textEntryDebounceMs = 2000 }) => {
  // Track updates in local state for a smooth typing
  // experience.
  const [amt, setAmt] = useState(filter.req_amt)

  // updateParentFilter calls the given `setFilter`
  // based on local state.
  const updateParentFilter = () => {
    setFilter(assocPath(['req_amt'], amt, filter))
  }

  // But only call `setFilter` debounced, so that we don't
  // cause rerenders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setAmt(e.target.value)
    updateParentFilterDebounced.callback()
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  return (
    <div className='filter amount'>
      <input
        type='text'
        value={amt || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder='Amount ~ < > -'
        title='Enter a number, a range like 150 - 250, an approximate number like ~75.50, or < 200 or > 600 for less-than or greater-than'
      />
    </div>
  )
}

AmountFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
}

export default AmountFilter
