import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { getMerchants } from '../api'
import { useAuth } from '../auth'
import { join, path, length } from 'ramda'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { parseMerchantFilters, updateSearchParamsWithFilters } from './filters'
import ActiveStatus from '../ActiveStatus'
import MerchantID from './MerchantID'
import MerchantDateTime from './MerchantDateTime'
import DownloadCSVLink from './DownloadCSVLink'
import Header from '../Header'
import Footer from '../Footer'
import Helmet from 'react-helmet'

/* Filters */
import MerchantIDFilter from './filters/MerchantIDFilter'
import MerchantNameFilter from './filters/MerchantNameFilter'
import ContractDescription from './ContractDescription'
import MerchantName from './MerchantName'

/*
 * ListMerchants renders a high-level merchant listing which displays a filtered, paginated list of merchants
 */
const ListMerchants = () => {
  const { token } = useAuth()

  // Merchant data
  const [merchants, setMerchants] = useState({ loading: true, failed: false, data: {} })

  // Parse parameters into filter object (which drives filters UI state) Note: We are using the URL as the "state" here,
  // and updates perform a history replacement (so as not to mess up "back" button behaviour too much - though we may
  // want to apply a heuristic like small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseMerchantFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // How we fetch merchants from the back-end
  const fetchMerchants = async () => {
    setMerchants({ ...merchants, loading: true, failed: false })
    try {
      const data = await getMerchants(token, { filter })
      setMerchants({ ...merchants, loading: false, failed: false, data })
    } catch (failed) {
      setMerchants({ ...merchants, loading: false, failed })
    }
  }

  // Short-term solution until we have proper pagination: This
  // powers a "Shot xxx more" button at the bottom, and appends it
  // to the current set. Importantly, it also disables live loading,
  // otherwise things would get interesting. And of course, if you scroll
  // to the top and change filters, you have to scroll down again and "Load more"
  // if you want to, i.e. data is replaced.
  const fetchMoreMerchants = async (page) => {
    setMerchants({ ...merchants, loading: true, failed: false })
    try {
      const addMerchants = await getMerchants(token, { filter, page })
      // Append data. Note: This totally ignores any 'newer' merchants that
      // may have appeared, so we also don't update top-level counts.
      const data = {
        ...merchants.data,
        results: [...merchants.data.results, ...addMerchants.results],
      }
      setMerchants({ ...merchants, loading: false, failed: false, data })
    } catch (failed) {
      setMerchants({ ...merchants, loading: false, failed })
    }
  }

  // How many more merchants are available to load?
  const loadableCount = Math.min(
    100,
    merchants &&
      merchants.data &&
      merchants.data.results &&
      merchants.data.results.length &&
      merchants.data.page &&
      merchants.data.page.total_count
      ? merchants.data.page.total_count - merchants.data.results.length
      : 100
  )

  // Event handler for 'Show xxx more' button that was clicked
  const handleLoadMore = () => {
    const lastID =
      merchants && merchants.data && merchants.data.results && merchants.data.results.length
        ? merchants.data.results[merchants.data.results.length - 1].id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreMerchants(page)
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // Fetch merchants: Initially, and every time filters change
  useEffect(() => {
    fetchMerchants()
  }, [urlParams.toString()])

  return (
    <section className='merchants'>
      <Header />
      <Helmet>
        <title>Merchants - Canapay</title>
      </Helmet>
      <div className='content'>
        {merchants.loading && !merchants && <p className='loading'>Loading...</p>}
        {merchants.failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!merchants.failed && merchants.data && merchants.data.results && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>
                  {merchants.data.results.length.toLocaleString()}
                </strong>
                {merchants.data.page &&
                  merchants.data.results.length !== merchants.data.page.total_count && (
                    <span>
                      of
                      <strong className='count total_count'>
                        {merchants.data.page.total_count.toLocaleString()}
                      </strong>
                    </span>
                  )}
                merchants
                {merchants.loading && <span className='loading'>updating...</span>}
              </span>
              <DownloadCSVLink
                displayCount={path(['page', 'total_count'], merchants.data)}
                filter={filter}
              />
            </header>
            <table className='merchants'>
              <thead>
                <tr>
                  <th className='num'>#</th>
                  <th className='registered-name'>
                    <h6>Trading as</h6>
                    <MerchantNameFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='status'>
                    <h6>Status</h6>
                    {/*<StatusFilter filter={filter} setFilter={setFilter} />*/}
                  </th>
                  <th className='org-parents'>
                    <h6>Parent Org</h6>
                  </th>
                  <th className='id'>
                    <h6>ID</h6>
                    <MerchantIDFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='time'>
                    <h6>Created</h6>
                  </th>
                  {/*<th className='time'>*/}
                  {/*  <h6>Updated</h6>*/}
                  {/*</th>*/}
                  <th className='hosts'>
                    <h6>Host Names</h6>
                  </th>
                  {/*<th className='territories'>*/}
                  {/*  <h6>Allowed Territories</h6>*/}
                  {/*</th>*/}
                  <th className='setting cust-allow'>
                    <h6>Allow cardholders</h6>
                  </th>
                  <th className='setting crypto-consent'>
                    <h6>Crypto consent</h6>
                  </th>
                  <th className='setting is-ftd'>
                    <h6>FTD</h6>
                  </th>
                  <th className='actions'>
                    <button
                      title={'Add a new merchant'}
                      onClick={() => history.push('/create/merchant')}
                    >
                      <i className='fas fa-plus' />
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {merchants.data.results.map((merchant, i) => {
                  const detailURI = `/merchant/${merchant.id}`
                  return (
                    <tr className='merchant' key={i}>
                      <td className='num'>{i + 1}.</td>
                      <td className='name clickable' onClick={() => history.push(detailURI)}>
                        {merchant.trading_as || merchant.registered_name || '-'}
                      </td>
                      <td className='status'>
                        <ActiveStatus {...merchant} />
                      </td>
                      <td className='part-of'>
                        {/* TODO Replace with tree from the context */}
                        {merchant.part_of_id ? (
                          <MerchantName
                            id={merchant.part_of_id}
                            merchants={merchants?.data?.results}
                          />
                        ) : merchant.contract_id ? (
                          <ContractDescription id={merchant.contract_id} />
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td className='id'>
                        <MerchantID merchant_id={merchant.id} />
                      </td>
                      <td className='time'>
                        <MerchantDateTime at={merchant.created_at} />
                      </td>
                      {/*<td className='time'>*/}
                      {/*  <MerchantDateTime at={merchant.last_updated_at} />*/}
                      {/*</td>*/}
                      <td className='hosts'>
                        {merchant.host_names != null &&
                          length(merchant.host_names) !== 0 &&
                          join(', ', merchant.host_names)}
                      </td>
                      {/*<td className='territories'>*/}
                      {/*  {merchant.allowed_territories != null &&*/}
                      {/*    length(merchant.allowed_territories) !== 0 &&*/}
                      {/*    join(', ', merchant.allowed_territories)}*/}
                      {/*</td>*/}
                      <td
                        className={classnames({
                          setting: true,
                          'cust-allow': true,
                          any: !merchant.txns_must_match_allow_list,
                          'wl-only': merchant.txns_must_match_allow_list,
                        })}
                        title={
                          merchant.txns_must_match_allow_list
                            ? 'Rejects any customer or card not matching a whitelist'
                            : 'Accepts any customer before applying standard scoring and validation'
                        }
                      >
                        {merchant.txns_must_match_allow_list ? 'Whitelist-only' : 'Any'}
                      </td>
                      <td
                        className={classnames({
                          setting: true,
                          'crypto-consent': true,
                          visible: merchant.cust_must_confirm_crypto,
                          hidden: !merchant.cust_must_confirm_crypto,
                        })}
                        title={
                          merchant.cust_must_confirm_crypto
                            ? 'Customers see and must agree to a crypto buy + transfer to complete transaction'
                            : 'Customers agree to buy and send crypto implicitly / invisibly'
                        }
                      >
                        {merchant.cust_must_confirm_crypto ? 'Visible' : 'Hidden'}
                      </td>
                      <td
                        className={classnames({
                          setting: true,
                          'ftd-setting': true,
                          ftd: merchant.is_ftd,
                          'non-ftd': !merchant.is_ftd,
                        })}
                        title={
                          merchant.is_ftd
                            ? 'Customers are NOT regarded as first time depositors'
                            : 'Customers are regarded as first time depositors'
                        }
                      >
                        {merchant.is_ftd ? 'FTD Only' : '-'}
                      </td>
                      <td className='actions'>
                        <Link to={detailURI}>Detail</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <footer className='actions'>
              {merchants.data.page &&
                merchants.data.results.length !== merchants.data.page.total_count && (
                  <>
                    <button onClick={handleLoadMore} disabled={merchants.loading}>
                      {merchants.loading ? 'Loading...' : `Show ${loadableCount} more`}
                    </button>
                    {' or '}
                  </>
                )}
              <DownloadCSVLink
                displayCount={path(['page', 'total_count'], merchants.data)}
                filter={filter}
              />
            </footer>
          </div>
        )}
        {!merchants.failed &&
          merchants.data &&
          merchants.data.results &&
          merchants.data.results.length === 0 && (
            <div className='no-results'>There are no merchants to show</div>
          )}
        <Footer />
      </div>
    </section>
  )
}

export default ListMerchants
