import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/* Given a `type` for an allow/deny entry, renders it visually. */
const Type = ({ types = [], type }) => {
  const matchedType = types.find((t) => t.type === type)
  return (
    <span className={classnames({ type: true, unknown: !type, [type]: type })}>
      {matchedType?.description || '?'}
    </span>
  )
}

Type.propTypes = {
  types: PropTypes.array.isRequired,
  type: PropTypes.string,
}

export default Type
