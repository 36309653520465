import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import {
  getMerchant,
  activateMerchant,
  deactivateMerchant,
  setMerchantSecret,
  inviteUser,
  unlinkMerchantFromParentMerchant,
  unlinkMerchantFromContract,
  setMerchantTxnsMustMatchAllowList,
  setMerchantRequiresCryptoConsent,
  setMerchantIsFTD,
  renameMerchant,
  setMerchantIsGatewayCustomer,
  setMerchantKYCRequiredSetting,
} from '../api'
import { useAuth } from '../auth'
import { isOperator, hasContextContractID } from '../auth-roles'
import MerchantDateTime from './MerchantDateTime'
import { join, length, map } from 'ramda'

import { v4 as uuidv4 } from 'uuid'
import ActiveStatus from '../ActiveStatus'
import MerchantParentOrg from './MerchantParentOrg'
import LinkMerchantToParent from './LinkMerchantToParent'
import MerchantTokens from './MerchantTokens'
import SetMerchantAllowedTerritories from './SetMerchantAllowedTerritories'
import EditableTextField from '../EditableTextField'
import { useOrgScope } from '../org-scope'
import SetMerchantAllowedCurrencies from './SetMerchantAllowedCurrencies'

/** ViewMerchant renders a single merchant. */
const ViewMerchant = () => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const params = useParams()

  // Merchant data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [merchant, setMerchant] = useState()

  const fetchMerchant = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getMerchant(token, params.id)
      setMerchant(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchMerchant()
  }, [params.id])

  return (
    <section className='merchant'>
      <Header />
      <Helmet>
        <title>Merchant {merchant?.trading_as || ''} - Canapay</title>
      </Helmet>
      <div className='content'>
        {loading && !merchant && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && merchant && (
          <div className='merchant'>
            <MerchantCore
              merchant={merchant}
              isUserOperator={isUserOperator}
              token={token}
              fetchMerchant={fetchMerchant}
            />
            <MerchantLinks merchant={merchant} />
          </div>
        )}
      </div>
    </section>
  )
}

/** Core / basic merchant details */
const MerchantCore = ({ merchant, isUserOperator, token, fetchMerchant }) => {
  const { refresh } = useOrgScope()
  const [error, setError] = useState()
  const [linkParent, setLinkParent] = useState(false)
  const [invite, setInvite] = useState(false)
  const [email, setEmail] = useState('')
  const [inviteError, setInviteError] = useState()
  const [message, setMessage] = useState()
  const [settingTerritories, setTerritories] = useState(false)
  const [settingCurrencies, setCurrencies] = useState(false)

  const onActivate = async () => {
    try {
      await activateMerchant(token, merchant.id, '')
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem activating this merchant')
    }
  }

  const onDeactivate = async () => {
    try {
      await deactivateMerchant(token, merchant.id, '')
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem deactivating this merchant')
    }
  }

  const onGenerateSecret = async () => {
    const prompt = `Are you sure that you want to reset the merchant secret of ${merchant.trading_as}? WARNING this needs to have been communicated with the merchant or they will be unable to verify our callbacks.`
    if (window.confirm(prompt)) {
      try {
        await setMerchantSecret(token, merchant.id, uuidv4())
        await fetchMerchant()
      } catch (e) {
        setError('There was a problem activating this merchant')
      }
    }
  }

  const onInviteButton = async () => {
    setInvite(true)
  }

  const onInviteUser = async () => {
    setInviteError(undefined)
    try {
      await inviteUser(token, 'merchant', merchant.id, email)
      setInvite(false)
      setMessage(`${email} has been sent an email invite`)
      setTimeout(() => {
        setMessage(undefined)
        setEmail(undefined)
      }, 3000)
    } catch (e) {
      const failure = await e.json()
      if (failure.failure_code === 2) {
        setMessage(`${email} has been sent an email invite`)
        setTimeout(() => {
          setMessage(undefined)
          setEmail(undefined)
        }, 3000)
        return
      }
      setInviteError('There was a problem inviting the user')
    }
  }

  const onLinkParentButton = async () => {
    setLinkParent(true)
  }

  const onLinkParentComplete = async () => {
    try {
      await fetchMerchant()
      setLinkParent(false)
    } catch (e) {
      setError('There was a problem retrieving the updated merchant')
    }
  }

  const onSetTerritoriesButton = async () => {
    setTerritories(true)
  }

  const onSetTerritoriesComplete = async () => {
    try {
      await fetchMerchant()
      setTerritories(false)
    } catch (e) {
      setError('There was a problem retrieving the updated merchant')
    }
  }

  const onSetCurrenciesButton = async () => {
    setCurrencies(true)
  }

  const onSetCurrenciesComplete = async () => {
    try {
      await fetchMerchant()
      setCurrencies(false)
    } catch (e) {
      setError('There was a problem retrieving the updated merchant')
    }
  }

  const onUnlinkParent = async () => {
    try {
      if (merchant.part_of_id != undefined) {
        await unlinkMerchantFromParentMerchant(token, merchant.id)
      } else if (merchant.contract_id != undefined) {
        await unlinkMerchantFromContract(token, merchant.id, merchant.contract_id)
      }
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem unlinking this merchant')
    }
  }

  // Change setting: Incoming transactions required to be on allow list
  const onChangeCustAllow = (txns_must_match_allow_list) => async () => {
    const prompt = txns_must_match_allow_list
      ? `Are you sure that only transactions matching an allow list should be accepted for ${merchant.trading_as}?`
      : `Are you sure that all cardholders (including those not on an allow list) should be accepted for ${merchant.trading_as}?`
    if (window.confirm(prompt)) {
      try {
        await setMerchantTxnsMustMatchAllowList(token, {
          merchant_id: merchant?.id,
          txns_must_match_allow_list,
        })
        await fetchMerchant()
      } catch (e) {
        setError('There was a problem configuring the allow-list policy for this merchant')
      }
    }
  }

  const onChangeKYCRequired = (kycRequired) => async () => {
    const prompt = kycRequired
      ? `Are you sure that KYC should be required for ${merchant.trading_as}?`
      : `Are you sure that KYC should NOT be required for ${merchant.trading_as}?`
    if (window.confirm(prompt)) {
      try {
        await setMerchantKYCRequiredSetting(token, {
          merchant_id: merchant?.id,
          kyc_required: kycRequired,
        })
        await fetchMerchant()
      } catch (e) {
        setError('There was a problem configuring the kyc setting for this merchant')
      }
    }
  }

  // Change setting: Incoming transactions required to be on allow list
  const onChangeCryptoConsentRequired = (cust_must_confirm_crypto) => async () => {
    const prompt = cust_must_confirm_crypto
      ? `Are you sure that cardholders of ${merchant.trading_as} should see and visibly consent to a crypto purchase + send?`
      : `Are you sure that you wish to hide the visible crypto purchase + send step for cardholders of ${merchant.trading_as} ?`
    if (window.confirm(prompt)) {
      try {
        await setMerchantRequiresCryptoConsent(token, {
          merchant_id: merchant?.id,
          cust_must_confirm_crypto,
        })
        await fetchMerchant()
      } catch (e) {
        setError('There was a problem configuring the crypto consent policy for this merchant')
      }
    }
  }

  const onChangeIsFTDMerchant = (is_ftd) => async () => {
    const prompt = is_ftd
      ? `Are you sure that ${merchant.trading_as} should be marked as an FTD merchant?`
      : `Are you sure that ${merchant.trading_as} should be marked as a non-FTD merchant?`
    if (window.confirm(prompt)) {
      try {
        await setMerchantIsFTD(token, {
          merchant_id: merchant?.id,
          is_ftd: is_ftd,
        })
        await fetchMerchant()
      } catch (e) {
        setError('There was a problem configuring the ftd setting for this merchant')
      }
    }
  }

  const onChangeIsMerchantGatewayCustomer = (is_gateway_customer) => async () => {
    const prompt = is_gateway_customer
      ? `Are you sure that ${merchant.trading_as} should be marked as a gateway customer?`
      : `Are you sure that ${merchant.trading_as} should be marked as a psp customer?`
    if (window.confirm(prompt)) {
      try {
        await setMerchantIsGatewayCustomer(token, {
          merchant_id: merchant?.id,
          is_gateway_customer: is_gateway_customer,
        })
        await fetchMerchant()
      } catch (e) {
        setError('There was a problem configuring the gateway setting for this merchant')
      }
    }
  }

  const onTradingAsChange = (newTradingAs) =>
    renameMerchant(token, {
      merchantId: merchant?.id,
      registeredName: merchant?.registered_name,
      tradingAs: newTradingAs,
    })
      .then(fetchMerchant)
      .then(refresh)

  const onRegisteredNameChange = (newRegisteredName) =>
    renameMerchant(token, {
      merchantId: merchant?.id,
      registeredName: newRegisteredName,
      tradingAs: merchant?.trading_as,
    })
      .then(fetchMerchant)
      .then(refresh)

  return (
    <div className='merchant-core'>
      <h1>
        <i className='fas fa-store' />{' '}
        {merchant.trading_as || merchant.registered_name || '(un-named merchant)'}{' '}
        <ActiveStatus {...merchant} />
      </h1>
      {isUserOperator && merchant.active && inviteError && (
        <span className={'error'}>{inviteError}</span>
      )}
      {isUserOperator && merchant.active && message && <span className={'info'}>{message}</span>}
      <table>
        <tbody>
          <tr className='merchant_id id'>
            <td className='key'>ID</td>
            <td className='val'>
              <span className='id'>{merchant.id}</span>
            </td>
          </tr>
          <tr className='trading_as'>
            <td className='key'>Trading As</td>
            <td className={'val'}>
              {isUserOperator ? (
                <EditableTextField value={merchant.trading_as} onChange={onTradingAsChange} />
              ) : (
                `${merchant.trading_as || '-'}`
              )}
            </td>
          </tr>
          <tr className='merchant_name'>
            <td className='key'>Registered Name</td>
            <td className='val'>
              {isUserOperator ? (
                <EditableTextField
                  value={merchant.registered_name}
                  onChange={onRegisteredNameChange}
                />
              ) : (
                `${merchant.registered_name || '-'}`
              )}
            </td>
          </tr>
          {merchant.created_at && (
            <tr className='created_at'>
              <td className='key'>Created At</td>
              <td className='val'>
                <MerchantDateTime at={merchant.created_at} />
              </td>
            </tr>
          )}
          {merchant.last_updated_at && (
            <tr className='last_updated_at'>
              <td className='key'>Last Updated At</td>
              <td className='val'>
                <MerchantDateTime at={merchant.last_updated_at} />
              </td>
            </tr>
          )}
          {merchant.contact_uris && length(merchant.contact_uris) !== 0 && (
            <tr className='contact_uri'>
              <td className='key'>Contact</td>
              <td className='val'>
                {map((cu) => {
                  const email = cu.substring(cu.indexOf(':') + 1)
                  return (
                    <div key={cu}>
                      <a href={cu}>{email}</a>
                    </div>
                  )
                }, merchant.contact_uris)}
              </td>
            </tr>
          )}
          {merchant.host_names && length(merchant.host_names) !== 0 && (
            <tr className='contact_uri'>
              <td className='key'>Host Names</td>
              <td className='val'>
                {map((hostName) => {
                  return <div key={hostName}>{hostName}</div>
                }, merchant.host_names)}
              </td>
            </tr>
          )}
          <tr className='contact_uri'>
            <td className='key'>Allowed Territories</td>
            <td className='val'>
              {merchant?.allowed_territories ? join(', ', merchant?.allowed_territories) : '-'}
              {isUserOperator && (
                <button onClick={onSetTerritoriesButton}>
                  <i className='fas fa-globe-europe' /> Set territories
                </button>
              )}
              {/*  later we could show countries for territories */}
            </td>
          </tr>
          <tr className='contact_uri'>
            <td className='key'>Allowed Currencies</td>
            <td className='val'>
              {merchant?.allowed_currencies ? join(', ', merchant?.allowed_currencies) : '-'}
              {isUserOperator && (
                <button onClick={onSetCurrenciesButton}>
                  <i className='fas fa-money-bill' /> Set currencies
                </button>
              )}
            </td>
          </tr>
          <tr className='parent-org'>
            <td className='key'>Parent Organisation</td>
            <td className='val'>
              <MerchantParentOrg
                contract_id={merchant.contract_id}
                part_of_id={merchant.part_of_id}
              />
              {isUserOperator &&
                (merchant.contract_id != undefined || merchant.part_of_id != undefined) && (
                  <button onClick={onUnlinkParent}>
                    <i className='fas fa-link' /> Un-link
                  </button>
                )}
              {isUserOperator && !merchant.contract_id && !merchant.part_of_id && !linkParent && (
                <button onClick={onLinkParentButton}>
                  <i className='fas fa-link' /> Link to parent
                </button>
              )}
            </td>
          </tr>
          <tr className='status'>
            <td className='key'>Status</td>
            <td className='val'>
              <ActiveStatus {...merchant} />
              {merchant?.status_updated_at ? (
                <span>
                  <span> at </span>
                  <span className={'time'}>
                    <MerchantDateTime at={merchant?.status_updated_at} />
                  </span>
                </span>
              ) : (
                <span></span>
              )}

              {isUserOperator && merchant.active && (
                <button onClick={onDeactivate}>
                  <i className='fas fa-ban' /> Suspend
                </button>
              )}
              {isUserOperator && !merchant.active && (
                <button onClick={onActivate}>
                  <i className='fas fa-thumbs-up' /> Activate
                </button>
              )}
            </td>
          </tr>
          {merchant.secret && (
            <tr className='secret'>
              <td className='key'>Secret</td>
              <td className='val'>
                {merchant.secret}
                {isUserOperator && (
                  <button onClick={onGenerateSecret}>
                    <i className='fas fa-lock' /> {(merchant.secret && 'Reset') || 'Generate'}{' '}
                    secret
                  </button>
                )}
              </td>
            </tr>
          )}
          <tr>
            <td className='key'>Allow cardholders</td>
            <td
              className={classnames({
                val: true,
                setting: true,
                'cust-allow': true,
                any: !merchant.txns_must_match_allow_list,
                'wl-only': merchant.txns_must_match_allow_list,
              })}
            >
              {merchant.txns_must_match_allow_list ? 'Whitelist-only' : 'Any'}
              {merchant.txns_must_match_allow_list ? (
                <button onClick={onChangeCustAllow(false)}>
                  <i className='fas fa-door-open' /> Set to any
                </button>
              ) : (
                <button onClick={onChangeCustAllow(true)}>
                  <i className='fas fa-door-closed' />
                  Set to whitelist-only
                </button>
              )}
            </td>
          </tr>
          <tr>
            <td className='key'>Crypto consent</td>
            <td
              className={classnames({
                val: true,
                setting: true,
                'crypto-consent': true,
                visible: merchant.cust_must_confirm_crypto,
                hidden: !merchant.cust_must_confirm_crypto,
              })}
            >
              {merchant.cust_must_confirm_crypto ? 'Visible' : 'Hidden (implicit)'}
              {merchant.cust_must_confirm_crypto ? (
                <button onClick={onChangeCryptoConsentRequired(false)}>
                  <i className='fas fa-eye-slash' /> Set to hidden
                </button>
              ) : (
                <button onClick={onChangeCryptoConsentRequired(true)}>
                  <i className='fas fa-eye' />
                  Set to visible
                </button>
              )}
            </td>
          </tr>
          <tr>
            <td className='key'>FTD Customers</td>
            <td
              className={classnames({
                val: true,
                setting: true,
                'ftd-setting': true,
                'ftd-customers': merchant.is_ftd,
                'vip-customers': !merchant.is_ftd,
              })}
            >
              {merchant.is_ftd ? 'First time depositors only' : 'Not first time depositors'}
              {merchant.is_ftd ? (
                <button onClick={onChangeIsFTDMerchant(false)}>
                  <i className='fas fa-people-carry' /> Set to any customers
                </button>
              ) : (
                <button onClick={onChangeIsFTDMerchant(true)}>
                  <i className='fas fa-skull-crossbones' />
                  Set to FTDs only
                </button>
              )}
            </td>
          </tr>
          <tr>
            <td className='key'>Gateway Customers</td>
            <td
              className={classnames({
                val: true,
                setting: true,
                'gw-setting': true,
                'gw-customers': merchant.is_gateway_customer,
                'psp-customers': !merchant.is_gateway_customer,
              })}
            >
              {merchant.is_gateway_customer ? 'Gateway customer' : 'PSP customer'}
              {merchant.is_gateway_customer ? (
                <button onClick={onChangeIsMerchantGatewayCustomer(false)}>
                  <i className='fas fa-lock' /> Set to PSP
                </button>
              ) : (
                <button onClick={onChangeIsMerchantGatewayCustomer(true)}>
                  <i className='fas fa-lock-open' />
                  Set to Gateway
                </button>
              )}
            </td>
          </tr>
          <tr>
            <td className='key'>KYC Requirement</td>
            <td
              className={classnames({
                val: true,
                setting: true,
                'kyc-requirement': true,
                visible: merchant.kyc_required,
                hidden: !merchant.kyc_required,
              })}
            >
              {merchant.kyc_required ? 'Required' : 'Not required'}
              {merchant.kyc_required ? (
                <button onClick={onChangeKYCRequired(false)}>
                  <i className='fas fa-user-secret' /> Set to not required
                </button>
              ) : (
                <button onClick={onChangeKYCRequired(true)}>
                  <i className='fas fa-user-shield' />
                  Set to required
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <footer className='actions'>
        {isUserOperator && merchant.active && !invite && (
          <button onClick={onInviteButton}>
            <i className='fas fa-user-plus' /> Invite a user
          </button>
        )}
        {isUserOperator && merchant.active && invite && (
          <span>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={'Email address'}
              autoFocus={true}
            />
            <button onClick={onInviteUser}>Send invite</button>
          </span>
        )}
        {isUserOperator && linkParent && (
          <LinkMerchantToParent
            merchant={merchant}
            onSuccess={() => onLinkParentComplete()}
            onCancel={() => setLinkParent(false)}
          />
        )}
        {isUserOperator && settingTerritories && (
          <SetMerchantAllowedTerritories
            merchant={merchant}
            onSuccess={() => onSetTerritoriesComplete()}
            onCancel={() => setTerritories(false)}
          />
        )}
        {isUserOperator && settingCurrencies && (
          <SetMerchantAllowedCurrencies
            merchant={merchant}
            onSuccess={() => onSetCurrenciesComplete()}
            onCancel={() => setCurrencies(false)}
          />
        )}
        {error && <div className='error'>{error}</div>}
      </footer>
      {isUserOperator && (
        <MerchantTokens merchant_id={merchant?.id} tokens={merchant.tokens || []} />
      )}
    </div>
  )
}

MerchantCore.propTypes = {
  merchant: PropTypes.object.isRequired,
  isUserOperator: PropTypes.bool,
  token: PropTypes.string,
  fetchMerchant: PropTypes.func.isRequired,
}

const MerchantLinks = ({ merchant }) => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)
  return (
    <div className='useful-links'>
      <h1>
        <i className='fas fa-link' /> Quick links
      </h1>
      <nav>
        <Link to={`/transactions?from=m30d&to=now&merchant_any=${merchant.id}`}>
          <i className='fas fa-th-list' />
          Transactions
        </Link>
        <Link to={`/merchant/${merchant.id}/txn-stats?from=m7d&to=now`}>
          <i className='fas fa-chart-bar' />
          Transaction statistics
        </Link>
        {(isUserOperator || isContractUser) && (
          <Link to={`/settings/allowdeny/${merchant.id}`}>
            <i className='fas fa-ban' />
            Allow / Deny configuration
          </Link>
        )}
      </nav>
    </div>
  )
}
MerchantLinks.propTypes = {
  merchant: PropTypes.object.isRequired,
}

export default ViewMerchant
