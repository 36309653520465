import React, { useEffect, useState } from 'react'
import { getContracts } from '../api'
import { useAuth } from '../auth'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { parseContractFilters, updateSearchParamsWithFilters } from './filters'
import ContractID from './ContractID'
import ContractDateTime from './ContractDateTime'
import Header from '../Header'
import Footer from '../Footer'
import Helmet from 'react-helmet'
import ContractIDFilter from './filters/ContractIDFilter'
import ContractDescriptionFilter from './filters/ContractDescriptionFilter'

/*
 * ListContracts renders a high-level contract listing which displays a filtered, paginated list of contracts
 */
const ListContracts = () => {
  const { token } = useAuth()
  const [contracts, setContracts] = useState({ loading: true, failed: false, data: {} })

  // Parse parameters into filter object (which drives filters UI state) Note: We are using the URL as the "state" here,
  // and updates perform a history replacement (so as not to mess up "back" button behaviour too much - though we may
  // want to apply a heuristic like small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseContractFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  const fetchContracts = async () => {
    setContracts({ ...contracts, loading: true, failed: false })
    try {
      const data = await getContracts(token, { filter })
      setContracts({ ...contracts, loading: false, failed: false, data })
    } catch (failed) {
      setContracts({ ...contracts, loading: false, failed })
    }
  }

  // Short-term solution until we have proper pagination: This
  // powers a "Shot xxx more" button at the bottom, and appends it
  // to the current set. Importantly, it also disables live loading,
  // otherwise things would get interesting. And of course, if you scroll
  // to the top and change filters, you have to scroll down again and "Load more"
  // if you want to, i.e. data is replaced.
  const fetchMoreContracts = async (page) => {
    setContracts({ ...contracts, loading: true, failed: false })
    try {
      const addContracts = await getContracts(token, { filter, page })
      // Append data. Note: This totally ignores any 'newer' contracts that
      // may have appeared, so we also don't update top-level counts.
      const data = {
        ...contracts.data,
        results: [...contracts.data.results, ...addContracts.results],
      }
      setContracts({ ...contracts, loading: false, failed: false, data })
    } catch (failed) {
      setContracts({ ...contracts, loading: false, failed })
    }
  }

  // How many more contracts are available to load?
  const loadableCount = Math.min(
    100,
    contracts &&
      contracts.data &&
      contracts.data.results &&
      contracts.data.results.length &&
      contracts.data.page &&
      contracts.data.page.total_count
      ? contracts.data.page.total_count - contracts.data.results.length
      : 100
  )

  // Event handler for 'Show xxx more' button that was clicked
  const handleLoadMore = () => {
    const lastID =
      contracts && contracts.data && contracts.data.results && contracts.data.results.length
        ? contracts.data.results[contracts.data.results.length - 1].id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreContracts(page)
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // Fetch contracts: Initially, and every time filters change
  useEffect(() => {
    fetchContracts()
  }, [urlParams.toString()])

  useEffect(() => {
    fetchContracts()
  }, [])

  return (
    <section className='contracts'>
      <Header />
      <Helmet>
        <title>Contracts - Canapay</title>
      </Helmet>
      <div className='content'>
        {contracts.loading && !contracts && <p className='loading'>Loading...</p>}
        {contracts.failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!contracts.failed && contracts.data && contracts.data.results && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>
                  {contracts.data.results.length.toLocaleString()}
                </strong>
                {contracts.data.page &&
                  contracts.data.results.length !== contracts.data.page.total_count && (
                    <span>
                      of
                      <strong className='count total_count'>
                        {contracts.data.page.total_count.toLocaleString()}
                      </strong>
                    </span>
                  )}
                contracts
                {contracts.loading && <span className='loading'>updating...</span>}
              </span>
            </header>
            <table className='contracts'>
              <thead>
                <tr>
                  <th className='num'>#</th>
                  <th className='id'>
                    <h6>ID</h6>
                    <ContractIDFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='descriptiion'>
                    <h6>Description</h6>
                    <ContractDescriptionFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='time'>
                    <h6>Created</h6>
                  </th>
                  <th className='actions'>
                    <button
                      title={'Add a new contract'}
                      onClick={() => history.push('/create/contract')}
                    >
                      <i className='fas fa-plus' />
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {contracts.data.results.map((contract, i) => {
                  const detailURI = `/contract/${contract.id}`
                  return (
                    <tr className='contract' key={i}>
                      <td className='num'>{i + 1}.</td>
                      <td className='id'>
                        <ContractID contract_id={contract.id} />
                      </td>
                      <td className='name clickable' onClick={() => history.push(detailURI)}>
                        {contract.description || '-'}
                      </td>
                      <td className='time'>
                        <ContractDateTime at={contract.created_at} />
                      </td>
                      <td className='actions'>
                        <Link to={detailURI}>Detail</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <footer className='actions'>
              {contracts.data.page &&
                contracts.data.results.length !== contracts.data.page.total_count && (
                  <>
                    <button onClick={handleLoadMore} disabled={contracts.loading}>
                      {contracts.loading ? 'Loading...' : `Show ${loadableCount} more`}
                    </button>
                    {' or '}
                  </>
                )}
            </footer>
          </div>
        )}
        {!contracts.failed &&
          contracts.data &&
          contracts.data.results &&
          contracts.data.results.length === 0 && (
            <div className='no-results'>There are no contracts to show</div>
          )}
        <Footer />
      </div>
    </section>
  )
}

export default ListContracts
