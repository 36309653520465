import React from 'react'
import PropTypes from 'prop-types'

const CardRef = ({ id, bin }) => (
  <div className='id-container'>
    <div className='card_id' title='Internal Card ID'>
      <span className='id'>{id || '-'}</span>
    </div>
    {bin && (
      <div className='bin' title='BIN'>
        <label>BIN:</label> <span className='id'>{bin}</span>
      </div>
    )}
  </div>
)

CardRef.propTypes = {
  id: PropTypes.string,
  bin: PropTypes.string,
}

export default CardRef
