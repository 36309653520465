import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { getMerchantsCSVURL } from '../api'

/**
 * Renders a widget that, given a `filter` specification, allows the current user to download
 * transactions matching the filter, using the user's auth token.
 */
const DownloadCSVLink = ({ filter = {}, displayCount }) => {
  const { token } = useAuth()
  const csvURL = getMerchantsCSVURL(token, { filter })
  if (displayCount === 0) {
    return null
  }
  return (
    <span className='download csv'>
      <a href={csvURL} title='Download all merchants matching the current filter as CSV'>
        Download {displayCount != null && displayCount.toLocaleString()} as CSV
      </a>
    </span>
  )
}
DownloadCSVLink.propTypes = {
  filter: PropTypes.object,
  displayCount: PropTypes.number,
}
export default DownloadCSVLink
