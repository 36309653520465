import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'

const MatchFilter = ({ filter, setFilter, textEntryDebounceMs = 2000 }) => {
  // Track updates in local state for a smooth typing experience.
  const [match, setMatch] = useState(filter.match_cnts)

  // updateParentFilter calls the given `setFilter`
  // based on local state, i.e. PAN to search for.
  const updateParentFilter = () => {
    setFilter(assocPath(['match_cnts'], match, filter))
  }

  // But only call `setFilter` debounced, so that we don't cause re-renders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setMatch(e.target.value)
    updateParentFilterDebounced.callback()
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  return (
    <div className='filter match'>
      <input
        type='text'
        value={match || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder='Match'
      />
    </div>
  )
}

MatchFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
}

export default MatchFilter
