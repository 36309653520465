import React, { useEffect, useState } from 'react'
import PropSettings from 'prop-types'
import { prop } from 'ramda'

const SettingFilter = ({ filter, setFilter }) => {
  // Quick simple version to filter based on one PSP
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [settings, setSettings] = useState()

  const settingName = prop('choice', filter)
  const changed = (e) => {
    const newSetting = e.target.value
    setFilter({ ...filter, choice: newSetting ? [newSetting] : [] })
  }

  const fetchSettings = async () => {
    setLoading(true)
    setFailed(false)
    try {
      // someday this may come from an api but for now these are the values
      setSettings([
        { name: 'Allow', value: 'a' },
        { name: 'Deny', value: 'd' },
      ])
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    fetchSettings()
  }, [])
  return (
    <div className='filter settings'>
      <select value={settingName || ''} onChange={changed}>
        <option value=''>Any</option>
        {!loading &&
          !failed &&
          settings &&
          settings.map((t) => {
            return (
              <option key={t.value} value={t.value}>
                {t.name}
              </option>
            )
          })}
      </select>
    </div>
  )
}

SettingFilter.propSettings = {
  filter: PropSettings.object.isRequired,
  setFilter: PropSettings.func.isRequired,
}

export default SettingFilter
