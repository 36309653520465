/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { initPasswordReset } from './api'
import { useHistory } from 'react-router-dom'
import { useAuth } from './auth'

const PasswordResetInitiate = () => {
  const { setToken } = useAuth()
  const history = useHistory()
  const [resetError, setResetError] = useState('')
  const [email, setEmail] = useState('')
  const [expiry, setExpiry] = useState('')

  setToken(null)
  const tryInitReset = async () => {
    try {
      setResetError(null)
      const { expiry } = await initPasswordReset({ email })
      setExpiry(expiry)
    } catch (err) {
      console.log(err)
      setResetError(err)
    }
  }

  const changeEmail = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  // TODO: If user is already authenticated, do two things differently:
  // 1. Pre-fill email address
  // 2. On 'Cancel' go to /user instead of "home"

  return (
    <section className='reset-password'>
      <div className='content'>
        <h1 className='clickable'>Reset Password</h1>
        {!expiry ? (
          <div className='form'>
            <div className='field'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' value={email} onChange={changeEmail} autoFocus />
            </div>
            {resetError && <div className='error'>That did not work. Please try again.</div>}
            <footer className='actions'>
              <button onClick={() => history.push('/')}>Cancel</button>
              <button onClick={tryInitReset} disabled={!email}>
                Reset
              </button>
            </footer>
          </div>
        ) : (
          <div className='success'>
            <p>Instructions to complete your password reset have been sent to {email}.</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default PasswordResetInitiate
