import { has, any, toPairs } from 'ramda'

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an operator (which is our admin)
export const isOperator = (roles = {}) => has('operator', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an controller (e.g. Celoxo)
export const isController = (roles = {}) => has('controller', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an controller (e.g. Celoxo)
export const isControllerNoMerchant = (roles = {}) => has('controller-no-merchant', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is a psp-viewer (e.g. Decta acquirer)
export const isPSPViewer = (roles = {}) => has('psp-viewer', roles)

// Utility: Checks if the user has any 'contract' roles
export const hasContextContractID = (roles = {}) =>
  roles != null && any((pair) => any(({ t }) => t == 'ContractID', pair[1]), toPairs(roles))

// Utility: Checks if the user has any 'merchant' roles
export const hasContextMerchantID = (roles = {}) =>
  roles != null && any((pair) => any(({ t }) => t == 'MerchantID', pair[1]), toPairs(roles))
