import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './auth'
import { getAccessibleMerchants } from './api'
import { pick } from 'ramda'
import AccessibleMerchantsContext from './AccessibleMerchantsContext'

// DEPRECATED - use `org-scope` instead
const AccessibleMerchantsProvider = ({ children }) => {
  const { token } = useAuth()
  const [accessibleMerchants, setAccessibleMerchants] = useState()

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await getAccessibleMerchants(token)
      if (response && response.results) {
        setAccessibleMerchants(response.results.map(pick(['id', 'registered_name'])))
      }
    }
    fetchMyAPI()
  }, [token])

  const { Provider } = AccessibleMerchantsContext
  return <Provider value={accessibleMerchants}>{children}</Provider>
}
AccessibleMerchantsProvider.propTypes = {
  children: PropTypes.any,
}

export default AccessibleMerchantsProvider
