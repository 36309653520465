import PropTypes from 'prop-types'
import React from 'react'

const ChoiceSelector = ({ value, onChange }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value=''>Allow/Deny:</option>
    <option value='a'>Allow</option>
    <option value='d'>Deny</option>
  </select>
)

ChoiceSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default ChoiceSelector
