import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { majorErrorCodes, minorErrorCodes } from './error-codes'

export const statusNames = {
  ok: 'ok',
  failed: 'failed',
  pending: 'pending',
  cust_verification_required: '3ds pending',
  charged_back: 'charged back',
}

/** Renders a status name for human consumption */
export const renderStatusName = (s, source) =>
  s === 'cust_verification_required' && source === 2
    ? 'kyc pending'
    : statusNames[s] || (s && s.replaceAll('_', ' ')) || '-'

/**
 *  TxnStatus renders a small badge with a given status, and possibly including interactive help
 * etc to understand this status.
 */
const TxnStatus = ({
  status,
  failure_code,
  failure_code_minor,
  className = 'status',
  cust_verification_required = {},
  refunded = false,
  refund_pending = false,
}) => (
  <span className={classnames({ [className]: true, [status]: status })}>
    <span className='name'>{renderStatusName(status, cust_verification_required?.source)}</span>
    {status == 'failed' ? (
      <span className='codes'>
        {status == 'failed' && failure_code && (
          <>
            {' '}
            <FailureCode code={failure_code} />
          </>
        )}
        {status == 'failed' && failure_code && failure_code_minor && (
          <>
            {' '}
            <FailureCodeMinor code={failure_code} minorCode={failure_code_minor} />
          </>
        )}
        {refunded && status != 'refunded' && (
          <span
            className='composite refunded'
            title='Transaction was refunded as part of exception handling'
          >
            R
          </span>
        )}
        {refund_pending && status !== 'refunded' && (
          <span
            className='composite refund_pending'
            title='Refunded requested as part of exception handling'
          >
            RP
          </span>
        )}
      </span>
    ) : refund_pending && status !== 'refunded' ? (
      <span className='codes'>
        <span
          className='composite refund_pending'
          title='Awaiting confirmation of a refund that was requested upstream'
        >
          Rfnd pend
        </span>
      </span>
    ) : null}
  </span>
)
TxnStatus.propTypes = {
  status: PropTypes.string,
  failure_code: PropTypes.number,
  failure_code_minor: PropTypes.number,
  className: PropTypes.string,
  cust_verification_required: PropTypes.object,
  refunded: PropTypes.bool,
  refund_pending: PropTypes.bool,
}

const FailureCode = ({ code }) => (
  <span
    title={majorErrorCodes.get(code) ? majorErrorCodes.get(code) : 'Failure code'}
    className={classnames({ code: true, [code]: true })}
  >
    {code}
  </span>
)

FailureCode.propTypes = {
  code: PropTypes.number,
}

const FailureCodeMinor = ({ minorCode }) => {
  const title = minorErrorCodes.get(minorCode) || 'Minor Code'
  return (
    <span title={title} className={classnames({ 'minor-code': true, [minorCode]: true })}>
      {minorCode}
    </span>
  )
}
FailureCodeMinor.propTypes = {
  code: PropTypes.number,
  minorCode: PropTypes.number,
}

export default TxnStatus
