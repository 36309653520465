import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../Header'
import Helmet from 'react-helmet'
import { API_BASE_URL } from '../api'
import { useAuth } from '../auth'

const View = () => {
  const { token } = useAuth()
  const { id } = useParams()
  const [kyc, setKYC] = useState({})
  const [details, setDetails] = useState({})
  const [level, setLevel] = useState(1)

  const overrideKYC = async (e) => {
    e.preventDefault()
    console.log('overriding kyc')
    const resp = await fetch(`${API_BASE_URL}/kyc/api/txn/${id}/claims`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'error',
      body: JSON.stringify({
        level: 1,
      }),
    })
    if (resp.status === 200) {
      setLevel(2)
    }
  }

  useEffect(async () => {
    console.log({ id })

    try {
      const json = await fetch(`${API_BASE_URL}/kyc/api/txn/${id}/claims`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          // Authorization: `Bearer ${bearerToken}`,
        },
        redirect: 'error',
      }).then((res) => res.json())
      setLevel(json.current_level)
      setDetails(json.details)
      setKYC({
        face: json?.documents[10],
        idFront: json?.documents[20],
        idBack: json?.documents[21],
        drivers: json?.documents[30],
        passport: json?.documents[40],
      })
    } catch (err) {
      console.error(err)
    }
  }, [])

  console.log(details)

  return (
    <section className='kyc'>
      <Header />
      <Helmet>
        <title>Customer KYC for {id}</title>
      </Helmet>
      <div className='content'>
        <div>
          <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Details</span>
            {level < 1 && (
              <button onClick={overrideKYC} style={{ background: '#017210' }}>
                <i className='fas fa-check' style={{ color: '#fff' }} /> Approve KYC
              </button>
            )}
          </h2>
          <table>
            <tbody>
              <tr>
                <td>Name First</td>
                <td>{details.NameFirst}</td>
              </tr>
              <tr>
                <td>Name Last</td>
                <td>{details.NameLast}</td>
              </tr>
              {details.BirthDate && (
                <tr>
                  <td>Birth Date</td>
                  <td>
                    {details.BirthDate.Year} - {details.BirthDate.Month} - {details.BirthDate.Day}
                  </td>
                </tr>
              )}
              <tr>
                <td>Country of Residence</td>
                <td>{details.CountryResidence}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{details.Email}</td>
              </tr>
              <tr>
                <td>Mobile Phone</td>
                <td>{details.MobilePhone}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {kyc.face && (
          <div>
            <h2>Face</h2>
            <img src={`data:image/jpeg;base64,${kyc.face}`} />
          </div>
        )}
        {kyc.idFront && (
          <div>
            <h2>ID Front</h2>
            <img src={`data:image/jpeg;base64,${kyc.idFront}`} />
          </div>
        )}
        {kyc.idBack && (
          <div>
            <h2>ID Back</h2>
            <img src={`data:image/jpeg;base64,${kyc.idBack}`} />
          </div>
        )}
        {kyc.drivers && (
          <div>
            <h2>Drivers License</h2>
            <img src={`data:image/jpeg;base64,${kyc.drivers}`} />
          </div>
        )}
        {kyc.passport && (
          <div>
            <h2>Passport</h2>
            <img src={`data:image/jpeg;base64,${kyc.passport}`} />
          </div>
        )}
      </div>
    </section>
  )
}

export default View
