// map of failureCode to description
export const majorErrorCodes = new Map()
majorErrorCodes.set(1, 'Invalid card information')
majorErrorCodes.set(2, 'Invalid order')
majorErrorCodes.set(3, 'Scoring failed')
majorErrorCodes.set(4, 'Currency not supported')
majorErrorCodes.set(5, 'Fraudulent transaction')
majorErrorCodes.set(6, 'Declined by upstream')
majorErrorCodes.set(7, 'Merchant not active')
majorErrorCodes.set(8, 'Invalid customer destination URL')
majorErrorCodes.set(9, 'Invalid credentials')
majorErrorCodes.set(10, 'Invalid callback URL')
majorErrorCodes.set(11, 'Un-routable transaction / no upstream bank willing to accept')
majorErrorCodes.set(12, 'Invalid card holder details	')
majorErrorCodes.set(13, 'Invalid currency')
majorErrorCodes.set(14, 'Already processed')
majorErrorCodes.set(15, 'Insufficient information')
majorErrorCodes.set(16, 'Unsupported brand')
majorErrorCodes.set(17, 'Invalid upstream')
majorErrorCodes.set(18, 'Upstream returned invalid data')
majorErrorCodes.set(19, 'Bad credentials')
majorErrorCodes.set(20, 'System error')
majorErrorCodes.set(21, 'Customer blockchain transfer failed')
majorErrorCodes.set(22, 'Merchant blockchain transfer failed')
majorErrorCodes.set(23, 'Customer denied blockchain transfer')
majorErrorCodes.set(24, 'Customer blockchain transfer timed out')
majorErrorCodes.set(25, 'Customer cancelled KYC')
majorErrorCodes.set(26, 'Customer abandoned KYC')
majorErrorCodes.set(27, 'Customer abandoned 3DS')
majorErrorCodes.set(28, 'Customer abandoned fingerprinting')

export const minorErrorCodes = new Map()
minorErrorCodes.set(1, 'Decline 3DS Check failed')
minorErrorCodes.set(2, 'Invalid CVV')
minorErrorCodes.set(3, 'Communication error')
minorErrorCodes.set(4, 'Exceeds frequency limit')
minorErrorCodes.set(5, 'Exceeds acquirer limit')
minorErrorCodes.set(6, 'Unsupported brand')
minorErrorCodes.set(7, 'Limit exceeded')
minorErrorCodes.set(8, 'Suspected fraud')
minorErrorCodes.set(9, 'Do not honor')
minorErrorCodes.set(10, 'Lost or stolen card')
minorErrorCodes.set(11, 'Expired card')
minorErrorCodes.set(12, 'System malfunction')
minorErrorCodes.set(13, 'Insufficient Funds')
minorErrorCodes.set(14, 'Transaction not permitted')
minorErrorCodes.set(15, 'Decline (general)')
minorErrorCodes.set(16, 'Invalid amount')
minorErrorCodes.set(17, 'Restricted card')
minorErrorCodes.set(18, 'Violation of law')
minorErrorCodes.set(19, 'Invalid card number')
minorErrorCodes.set(20, 'Invalid merchant')
minorErrorCodes.set(21, 'Merchant mode error')
minorErrorCodes.set(22, 'Invalid Issuer')
minorErrorCodes.set(23, 'Invalid Country')
minorErrorCodes.set(24, 'Missing Email')
minorErrorCodes.set(25, 'Missing Phone')
minorErrorCodes.set(26, 'Missing First Name')
minorErrorCodes.set(27, 'Missing Last Name')
minorErrorCodes.set(28, 'Missing Date Of Birth')
minorErrorCodes.set(31, 'Card or Cardholder on Deny List')
minorErrorCodes.set(32, 'Card or Cardholder required to be on Allow List but was not')
minorErrorCodes.set(33, 'Transaction Risk Scoring')
minorErrorCodes.set(34, 'Missing Card Details')
minorErrorCodes.set(35, 'Missing Name on Card')
minorErrorCodes.set(36, 'Missing Card Expiry Date')
minorErrorCodes.set(37, 'Invalid Card Expiry Month')
minorErrorCodes.set(38, 'Invalid Card Expiry Year')
minorErrorCodes.set(39, 'Invalid Date Of Birth')
minorErrorCodes.set(40, 'Missing Order Reference')
minorErrorCodes.set(41, 'Unsupported Country')
minorErrorCodes.set(42, 'Invalid City')
minorErrorCodes.set(43, 'Invalid Postal Code')
minorErrorCodes.set(44, 'Invalid Line 1')
minorErrorCodes.set(45, 'Invalid Transaction')
minorErrorCodes.set(46, 'ACS Timeout')

// The following are old codes we used from Decta IIRC, we aren't using Decta atm and currently (2022-10-07) we don't
//  have minor codes for anything above 23 in production. This list is used to populate a dropdown filter in the
//  back-office, so I'm truncating it for now.

// minorErrorCodes.set(100, 'Decline (general, no comments)')
// minorErrorCodes.set(101, 'Decline, expired card')
// minorErrorCodes.set(102, 'Decline, suspected fraud')
// minorErrorCodes.set(103, 'Decline, card acceptor contact acquirer')
// minorErrorCodes.set(104, 'Decline, restricted card')
// minorErrorCodes.set(105, 'Decline, card acceptor call acquirers security department')
// minorErrorCodes.set(106, 'Decline, allowable PIN tries exceeded')
// minorErrorCodes.set(107, 'Decline, refer to card issuer')
// minorErrorCodes.set(108, 'Decline, refer to card issuers special conditions')
// minorErrorCodes.set(109, 'Decline, invalid merchant')
// minorErrorCodes.set(110, 'Decline, invalid amount')
// minorErrorCodes.set(111, 'Decline, invalid card number')
// minorErrorCodes.set(112, 'Decline, PIN data required')
// minorErrorCodes.set(113, 'Decline, unacceptable fee')
// minorErrorCodes.set(114, 'Decline, no account of type requested')
// minorErrorCodes.set(115, 'Decline, requested function not supported')
// minorErrorCodes.set(116, 'Decline, not sufficient funds')
// minorErrorCodes.set(117, 'Decline, incorrect PIN')
// minorErrorCodes.set(118, 'Decline, no card record')
// minorErrorCodes.set(119, 'Decline, transaction not permitted to cardholder')
// minorErrorCodes.set(120, 'Decline, transaction not permitted to terminal')
// minorErrorCodes.set(121, 'Decline, exceeds withdrawal amount limit')
// minorErrorCodes.set(122, 'Decline, security violation')
// minorErrorCodes.set(123, 'Decline, exceeds withdrawal frequency limit')
// minorErrorCodes.set(124, 'Decline, violation of law')
// minorErrorCodes.set(125, 'Decline, card not effective')
// minorErrorCodes.set(126, 'Decline, invalid PIN block')
// minorErrorCodes.set(127, 'Decline, PIN length error')
// minorErrorCodes.set(128, 'Decline, PIN kay synch error')
// minorErrorCodes.set(129, 'Decline, suspected counterfeit card')
// minorErrorCodes.set(134, 'Decline, invalid transaction')
// minorErrorCodes.set(135, 'Decline, re-enter transaction')
// minorErrorCodes.set(139, 'Decline, card issuer or switch inoperative')
// minorErrorCodes.set(141, 'Decline, system malfunction')
// minorErrorCodes.set(180, 'Decline, by cardholders wish')
// minorErrorCodes.set(181, 'Decline, Card is not active')
// minorErrorCodes.set(182, 'Decline, Card is not active')
// minorErrorCodes.set(183, 'Decline, Card is not active')
// minorErrorCodes.set(184, 'Decline, Card is not active')
// minorErrorCodes.set(185, 'Decline, Card is not active')
// minorErrorCodes.set(186, 'Decline, Card is not active')
// minorErrorCodes.set(187, 'Decline, Card is not active')
// minorErrorCodes.set(188, 'Decline, Card is not active')
// minorErrorCodes.set(189, 'Decline, Card is not active')
// minorErrorCodes.set(190, 'Decline, Card is not active')
// minorErrorCodes.set(192, 'Decline, Card is not active')
// minorErrorCodes.set(193, 'Decline, Card is not active')
// minorErrorCodes.set(194, 'Decline, Card is not active')
// minorErrorCodes.set(195, 'Decline, Card is not active')
// minorErrorCodes.set(196, 'Decline, Card is not active')
// minorErrorCodes.set(197, 'Decline, Card is not active')
// minorErrorCodes.set(198, 'Decline, Card is not active')
// minorErrorCodes.set(199, 'Decline, Card is not active')
// minorErrorCodes.set(200, 'Pick-up (general, no comments)')
// minorErrorCodes.set(201, 'Pick-up, expired card')
// minorErrorCodes.set(202, 'Pick-up, suspected fraud')
// minorErrorCodes.set(203, 'Pick-up, card acceptor contact card acquirer')
// minorErrorCodes.set(204, 'Pick-up, restricted card')
// minorErrorCodes.set(205, 'Pick-up, card acceptor call acquirers security department')
// minorErrorCodes.set(206, 'Pick-up, allowable PIN tries exceeded')
// minorErrorCodes.set(207, 'Pick-up, special conditions')
// minorErrorCodes.set(208, 'Pick-up, lost card')
// minorErrorCodes.set(209, 'Pick-up, stolen card')
// minorErrorCodes.set(210, 'Pick-up, suspected counterfeit card')
// minorErrorCodes.set(300, 'Status message: file action successful')
// minorErrorCodes.set(301, 'Status message: file action not supported by receiver')
// minorErrorCodes.set(302, 'Status message: unable to locate record on file')
// minorErrorCodes.set(303, 'Status message: duplicate record, old record replaced')
// minorErrorCodes.set(304, 'Status message: file record field edit error')
// minorErrorCodes.set(305, 'Status message: file locked out')
// minorErrorCodes.set(306, 'Status message: file action not successful')
// minorErrorCodes.set(307, 'Status message: file data format error')
// minorErrorCodes.set(308, 'Status message: duplicate record, new record rejected')
// minorErrorCodes.set(309, 'Status message: unknown file')
// minorErrorCodes.set(500, 'Status message: reconciled, in balance')
// minorErrorCodes.set(501, 'Status message: reconciled, out of balance')
// minorErrorCodes.set(502, 'Status message: amount not reconciled, totals provided')
// minorErrorCodes.set(503, 'Status message: totals for reconciliation not available')
// minorErrorCodes.set(504, 'Status message: not reconciled, totals provided')
// minorErrorCodes.set(600, 'Accepted (for administrative info)')
// minorErrorCodes.set(601, 'Status message: impossible to trace back original transaction')
// minorErrorCodes.set(602, 'Status message: invalid transaction reference number')
// minorErrorCodes.set(603, 'Status message: reference number/PAN incompatible')
// minorErrorCodes.set(604, 'Status message: POS photograph is not available')
// minorErrorCodes.set(605, 'Status message: requested item supplied')
// minorErrorCodes.set(
//   606,
//   'Status message: request cannot be fulfilled - required documentation is not available'
// )
// minorErrorCodes.set(680, 'List ready')
// minorErrorCodes.set(681, 'List not ready')
// minorErrorCodes.set(700, 'Accepted (for fee collection)')
// minorErrorCodes.set(800, 'Accepted (for network management)')
// minorErrorCodes.set(900, 'Advice acknowledged, no financial liability accepted')
// minorErrorCodes.set(901, 'Advice acknowledged, financial liability accepted')
// minorErrorCodes.set(902, 'Decline reason message: invalid transaction')
// minorErrorCodes.set(903, 'Status message: re-enter transaction')
// minorErrorCodes.set(904, 'Decline reason message: format error')
// minorErrorCodes.set(905, 'Decline reason message: acquirer not supported by switch')
// minorErrorCodes.set(906, 'Decline reason message: cutover in process')
// minorErrorCodes.set(907, 'Decline reason message: card issuer or switch inoperative')
// minorErrorCodes.set(
//   908,
//   'Decline reason message: transaction destination cannot be found for routing'
// )
// minorErrorCodes.set(909, 'Decline reason message: system malfunction')
// minorErrorCodes.set(910, 'Decline reason message: card issuer signed off')
// minorErrorCodes.set(911, 'Decline reason message: card issuer timed out')
// minorErrorCodes.set(912, 'Decline reason message: card issuer unavailable')
// minorErrorCodes.set(913, 'Decline reason message: duplicate transmission')
// minorErrorCodes.set(914, 'Decline reason message: not able to trace back to original transaction')
// minorErrorCodes.set(915, 'Decline reason message: reconciliation cutover or checkpoint error')
// minorErrorCodes.set(916, 'Decline reason message: MAC incorrect')
// minorErrorCodes.set(917, 'Decline reason message: MAC key sync error')
// minorErrorCodes.set(918, 'Decline reason message: no communication keys available for use')
// minorErrorCodes.set(919, 'Decline reason message: encryption key sync error')
// minorErrorCodes.set(920, 'Decline reason message: security software/hardware error - try again')
// minorErrorCodes.set(921, 'Decline reason message: security software/hardware error - no action')
// minorErrorCodes.set(922, 'Decline reason message: message number out of sequence')
// minorErrorCodes.set(923, 'Status message: request in progress')
// minorErrorCodes.set(950, 'Decline reason message: violation of business arrangement')
