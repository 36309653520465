import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'

/** CountryFilter lets the user pick one or more countries. Very simple for now, text input. Needs
 * a better widget.
 */
const CountryFilter = ({ filter, setFilter, textEntryDebounceMs = 2000 }) => {
  // Track updates in local state for a smooth typing
  // experience.
  const [country, setCountry] = useState(filter.country_any ? filter.country_any.join(',') : '')

  // updateParentFilter calls the given `setFilter`
  // based on local state, i.e. PAN to search for.
  const updateParentFilter = () => {
    setFilter(
      assocPath(
        ['country_any'],
        country ? country.split(',').map((s) => s.trim().toUpperCase()) : [],
        filter
      )
    )
  }

  // But only call `setFilter` debounced, so that we don't
  // cause rerenders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setCountry(e.target.value)
    updateParentFilterDebounced.callback()
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  return (
    <div className='filter country'>
      <input
        type='text'
        value={country || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder='Country'
      />
    </div>
  )
}

CountryFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
}

export default CountryFilter
