import React from 'react'
import PropTypes from 'prop-types'
import { useOrgScope, OrgScopeRequired, TYPE_MERCHANT } from '../org-scope'
import { compose, filter as rFilter, prop, propEq, propOr, sortBy, toLower, values } from 'ramda'

const MerchantSelector = ({ value, onChange }) => (
  <OrgScopeRequired>
    <Selector value={value} onChange={onChange} />
  </OrgScopeRequired>
)

MerchantSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const Selector = ({ value, onChange }) => {
  const { byID } = useOrgScope()
  const allMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', TYPE_MERCHANT)), values)(byID)
    ) || []
  const activeMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('active', true)))(allMerchants)
    ) || []
  const suspendedMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('active', false)))(allMerchants)
    ) || []

  const showGrouped =
    allMerchants.length !== activeMerchants.length &&
    allMerchants.length !== suspendedMerchants.length

  const change = onChange != null ? (e) => onChange(e.target.value) : undefined
  return (
    <div className='filter merchant'>
      {showGrouped ? (
        <select value={value} onChange={change}>
          activeMerchants.length !== 0 ? (
          <optgroup key={'merchants-active'} label={'Merchants (Active)'}>
            {activeMerchants.map((m) => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </optgroup>
          ) : null suspendedMerchants.length !== 0 ? (
          <optgroup key={'merchants-inactive'} label={'Merchants (Suspended)'}>
            {suspendedMerchants.map((m) => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </optgroup>
          ) : null
        </select>
      ) : (
        <select value={value} onChange={change}>
          {allMerchants.map((m) => (
            <option key={m.id} value={m.id}>
              {m.name}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

Selector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default MerchantSelector
