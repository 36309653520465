import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DateTime as DT } from 'luxon'

/* Renders an `at` property (as ISO date/time) as a human-readable date + time. */
const DateTime = ({ at }) => {
  if (!at) {
    return null
  }
  const now = DT.local()
  const dt = at ? DT.fromISO(at) : null
  const isToday = dt.hasSame(now, 'day')
  const isSameHour = isToday && dt.hasSame(now, 'hour')
  return (
    <span className={classnames({ 'date-time': true, today: isToday, 'this-hour': isSameHour })}>
      <span className='date'> {dt.toISODate()}</span>{' '}
      <strong className='time'>{dt.toLocaleString(DT.TIME_24_WITH_SHORT_OFFSET)}</strong>
    </span>
  )
}

DateTime.propTypes = {
  /** ISO DateTime string */
  at: PropTypes.string,
}

export default DateTime
