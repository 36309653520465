import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, NavLink } from 'react-router-dom'
import { useAuth } from './auth'
import {
  isOperator,
  hasContextContractID,
  isControllerNoMerchant,
  hasContextMerchantID,
  isPSPViewer,
} from './auth-roles'

/** Top-level header with navigation elements. */
const Header = ({ children }) => {
  const history = useHistory()
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)
  const isMerchantUser = hasContextMerchantID(roles)
  const hasPSPViewerRole = isPSPViewer(roles)
  const isControllerNoMer = isControllerNoMerchant(roles)

  const goHome = () => history.push('/')
  const isTesting =
    window.location.hostname === 'localhost' || window.location.hostname === 'test.canapay.io'
  return (
    <>
      {isTesting && <div id='testing-banner'>TESTING PURPOSES ONLY - NOT FOR PRODUCTION USE</div>}
      <header>
        {!children && (
          <h1 className='logo' onClick={goHome}>
            Canapay
          </h1>
        )}
        {children}
        <nav>
          <NavLink to='/transactions?from=m24h&to=now'>
            <i className='fas fa-th-list' />
            <span className='text'>Transactions</span>
          </NavLink>
          {isUserOperator && (
            <NavLink to='/merchants'>
              <i className='fas fa-store' />
              <span className='text'>Merchants</span>
            </NavLink>
          )}
          {isUserOperator && (
            <NavLink to='/contracts'>
              <i className='fas fa-file-contract' />
              <span className='text'>Contracts</span>
            </NavLink>
          )}
          {isUserOperator && (
            <NavLink to='/users'>
              <i className='fas fa-users' />
              <span className='text'>Users</span>
            </NavLink>
          )}
          {(isUserOperator || isContractUser || isMerchantUser) && !isControllerNoMer && (
            <NavLink to='/settings'>
              <i className='fas fa-cog'></i>
              <span className='text'>Settings</span>
            </NavLink>
          )}
          {!hasPSPViewerRole && (
            <a
              href='https://canapay.notion.site/Canapay-Merchant-FAQ-Board-b87160e235054e3a9e205dea902ca7da'
              rel='noopener noreferrer'
              target='_blank'
            >
              <i className='fas fa-question-circle' />
              <span className='text'>Support</span>
            </a>
          )}
          {token && (
            <NavLink to='/account'>
              <i className='fas fa-user' />
              <span className='text'>Account</span>
            </NavLink>
          )}
        </nav>
      </header>
    </>
  )
}
Header.propTypes = {
  children: PropTypes.any,
}

export default Header
