import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { getContractById, inviteUser, renameMerchant, setContractDescription } from '../api'
import { useAuth } from '../auth'
import { isOperator, hasContextContractID } from '../auth-roles'
import ContractDateTime from './ContractDateTime'
import { TYPE_MERCHANT, useOrgScope } from '../org-scope'
import MerchantID from '../merchants/MerchantID'
import EditableTextField from '../EditableTextField'

/** ViewContract renders a single contract. */
const ViewContract = () => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const params = useParams()

  // Contract data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [contract, setContract] = useState()

  const fetchContract = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getContractById(token, params.id)
      setContract(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchContract()
  }, [params.id])

  return (
    <section className='contract'>
      <Header />
      <Helmet>
        <title>Contract {contract?.description || ''} - Canapay</title>
      </Helmet>
      <div className='content'>
        {loading && !contract && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && contract && (
          <div className='contract'>
            <ContractCore
              contract={contract}
              isUserOperator={isUserOperator}
              token={token}
              fetchContract={fetchContract}
            />
            <ContractLinks contract={contract} />
          </div>
        )}
      </div>
    </section>
  )
}

/** Core / basic contract details */
const ContractCore = ({ contract, isUserOperator, token, fetchContract }) => {
  const [invite, setInvite] = useState(false)
  const [email, setEmail] = useState('')
  const [inviteError, setInviteError] = useState()
  const [message, setMessage] = useState()
  const { refresh } = useOrgScope()

  const onDescriptionChange = (newDescription) =>
    setContractDescription(token, {
      contractId: contract?.id,
      description: newDescription,
    })
      .then(fetchContract)
      .then(refresh)

  const onInviteButton = async () => {
    setInvite(true)
  }

  const onInviteUser = async () => {
    setInviteError(undefined)
    try {
      await inviteUser(token, 'contract', contract.id, email)
      setInvite(false)
      setMessage(`${email} has been sent an email invite`)
      setTimeout(() => {
        setMessage(undefined)
        setEmail(undefined)
      }, 3000)
    } catch (e) {
      const failure = await e.json()
      if (failure.failure_code === 2) {
        setMessage(`${email} has been sent an email invite`)
        setTimeout(() => {
          setMessage(undefined)
          setEmail(undefined)
        }, 3000)
        return
      }
      setInviteError('There was a problem inviting the user')
    }
  }

  return (
    <div className='contract-core'>
      <h1>
        <i className='fas fa-store' /> {contract.description || '(un-named contract)'}{' '}
      </h1>
      {isUserOperator && inviteError && <span className={'error'}>{inviteError}</span>}
      {isUserOperator && message && <span className={'info'}>{message}</span>}
      <table>
        <tbody>
          <tr className='contract_id id'>
            <td className='key'>ID</td>
            <td className='val'>
              <span className='id'>{contract.id}</span>
            </td>
          </tr>
          <tr className='description'>
            <td className='key'>Description</td>
            <td className='val'>
              {isUserOperator ? (
                <EditableTextField value={contract.description} onChange={onDescriptionChange} />
              ) : (
                `${contract.description || '-'}`
              )}
            </td>
          </tr>
          {contract.created_at && (
            <tr className='created_at'>
              <td className='key'>Created At</td>
              <td className='val'>
                <ContractDateTime at={contract.created_at} />
              </td>
            </tr>
          )}
          {contract.start_at && (
            <tr className='start_at'>
              <td className='key'>Start At</td>
              <td className='val'>
                <ContractDateTime at={contract.start_at} />
              </td>
            </tr>
          )}
          {contract.end_at && (
            <tr className='end_at'>
              <td className='key'>End At</td>
              <td className='val'>
                <ContractDateTime at={contract.end_at} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/*TODO add a list of users who have access to this contract*/}
      <footer className='actions'>
        {isUserOperator && !invite && (
          <button onClick={onInviteButton}>
            <i className='fas fa-user-plus' /> Invite a user
          </button>
        )}
        {isUserOperator && invite && (
          <span>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={'Email address'}
              autoFocus={true}
            />
            <button onClick={onInviteUser}>Send invite</button>
          </span>
        )}
      </footer>

      <ContractMerchants contract={contract} />
    </div>
  )
}

ContractCore.propTypes = {
  contract: PropTypes.object.isRequired,
  isUserOperator: PropTypes.bool,
  token: PropTypes.string,
  fetchContract: PropTypes.func.isRequired,
}

const ContractMerchants = ({ contract }) => {
  const { byID } = useOrgScope()
  const contractOrg = byID[contract.id]
  const merchants =
    contractOrg &&
    contractOrg.children &&
    contractOrg.children.filter((o) => o && o.type === TYPE_MERCHANT)
  return merchants ? (
    <div className='contract-merchants'>
      <h3>Merchants</h3>
      <table>
        <tbody>
          {merchants.map((merchant) => {
            const detailURI = `/merchant/${merchant.id}`
            return (
              <tr className='merchant' key={merchant.id}>
                <td className='name clickable' onClick={() => history.push(detailURI)}>
                  {merchant.name || '-'}
                </td>
                <td className='id'>
                  <MerchantID merchant_id={merchant.id} />
                </td>
                <td className='actions'>
                  <Link to={detailURI}>Detail</Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  ) : null
}

const ContractLinks = ({ contract }) => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)
  const { byID } = useOrgScope()
  const contractOrg = byID[contract.id]
  const merchantIDs =
    contractOrg &&
    contractOrg.children &&
    contractOrg.children.filter((o) => o && o.type === TYPE_MERCHANT).map((child) => child.id)

  return (
    <div className='useful-links'>
      <h1>
        <i className='fas fa-link' /> Quick links
      </h1>
      <nav>
        {merchantIDs && merchantIDs.length !== 0 && (
          <Link to={`/transactions?from=m30d&to=now&merchant_any=${merchantIDs.join(',')}`}>
            <i className='fas fa-th-list' />
            Transactions
          </Link>
        )}
        {(isUserOperator || isContractUser) && (
          <Link to={`/settings/allowdeny/${contract.id}`}>
            <i className='fas fa-ban' />
            Allow / Deny configuration
          </Link>
        )}
      </nav>
    </div>
  )
}
ContractLinks.propTypes = {
  contract: PropTypes.object.isRequired,
}

export default ViewContract
