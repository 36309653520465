import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './auth'
import AccessibleMerchantsProvider from './AccessibleMerchantsProvider'
import AccessibleContractsProvider from './AccessibleContractsProvider'

/** A drop-in replacement for react router `Route`,
 * which only renders its component if the user is authenticated.
 */
const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  // Note: If authentication expired, the first call me make with the bad
  // token will kick the user out to the sign-in page. Instead, check expiry
  // here already?

  // We track the destination URL, and make use of it in SignIn.jsx
  // to route user to requested destination after sign-in if needed.
  const { token } = useAuth()
  const fromURL = window.location.pathname + window.location.search

  // TODO call to get my user

  return (
    <Route
      {...rest}
      render={(props) =>
        // If a user's auth has a `token` we assume user is authenticated.
        token ? (
          <AccessibleContractsProvider>
            <AccessibleMerchantsProvider>
              <Component {...props} />
            </AccessibleMerchantsProvider>
          </AccessibleContractsProvider>
        ) : (
          <Redirect to={{ pathname: '/sign-in', state: { fromURL } }} />
        )
      }
    />
  )
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default AuthenticatedRoute
