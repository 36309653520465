import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react'
import { useInterval } from './timing/use-interval'
import { useAuth } from './auth'
import { getMyOrgScopeTree } from './api'
import { values } from 'ramda'

export const TYPE_ROOT = 'root'
export const TYPE_CONTRACT = 'contract'
export const TYPE_MERCHANT = 'merchant'
export const TYPE_PSP = 'psp' // Not used in org scope tree yet

// How often do we poll for changes to the user's org scope tree,
// until we replace polling with a web socket.
const LIVE_UPDATE_INTERVAL = 60000 // ms

/** OrgScopeProvider fetches and manages the scope of organizations
 * that the current user has access to. Child components will want
 * to make use of `useOrgScope` to obtain the data - both in tree,
 * and map, form. */
export const OrgScopeProvider = ({ children }) => {
  // If user is authenticated, pull org scope tree
  // for this user, and stick it in context
  const { token } = useAuth()
  const [scope, setScope] = useState({ data: null, loading: false, failed: null })
  const fetchMyScopeTree = async () => {
    // Only if authenticated
    if (!token) {
      return
    }
    setScope({ ...scope, loading: true, failed: null })
    try {
      const data = await getMyOrgScopeTree(token)
      setScope({
        ...scope,
        loading: false,
        failed: null,
        data: data,
      })
    } catch (failed) {
      setScope({ ...scope, loading: false, failed })
    }
  }

  // Whenever the user token changes, e.g. such as at the point of
  // logging in, fetch immediately
  useEffect(() => {
    fetchMyScopeTree()
  }, [token])

  // Thereafter, fetch periodically (no web socket to watch this yet)
  useInterval(fetchMyScopeTree, LIVE_UPDATE_INTERVAL, false)

  const value = {
    loading: scope.loading,
    failed: scope.failed,
    // TODO: At the moment, the server is sending buggy data (unnamed duplicates) that
    // we explicitly omit for now until bug is solved.
    tree: cleanOrg(scope.data), // Tree: {id, name, children: [ ... ]  }
    byID: foldOrgsByID({}, scope.data), // Map: { id -> org }
    refresh: fetchMyScopeTree,
  }
  return <OrgScope.Provider value={value}>{children}</OrgScope.Provider>
}
OrgScopeProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

/** A React Hook that returns the org scope visible to the current
 * user. Structure is:
 * {
 *  loading: boolean, // true while busy loading
 *  failed: string,   // non-null if fetching failed
 *  tree: { id, name, type, children: []  } // Root of the org tree visible to user (nested children)
 *  byID: {} // id -> org lookup map, which also contains nested `children` (all flattened)
 *  branch: [{id, name, type}] // Optional: A 'path' top-down of the org tree, from the highest root visible
 *                             // to the user, to the org with the ID passed into useOrgScope(orgID)
 * }
 */
export const useOrgScope = (orgID) => {
  const ctx = useContext(OrgScope)
  // If an orgID was passed in, also include a `branch` field that is a top-down
  // walk to the given org, if it is in scope.
  // This is a list of: [{id, type, name}] which only includes the
  // orgs that the current user has visibility of.
  const branch =
    orgID && ctx?.byID
      ? getBranchTo(ctx.byID, [ctx.byID[orgID]]).map((o) =>
          o
            ? {
                id: o.id,
                type: o.type,
                name: o.name,
              }
            : o
        )
      : []
  return { ...ctx, branch }
}
/** OrgScopeRequired is a handy utility to wrap any component that should only be rendered
 * if the user's org scope is known, i.e. has it least loaded once successfully. Saves
 * implementors from having to perform the same checks over and over to protect their
 * component logic from failures e.g. DURING the loading of org scope.
 */
export const OrgScopeRequired = ({ children }) => {
  const { loading, tree, failed } = useOrgScope()
  // Busy loading (first time, no tree available already)
  if (loading && !tree) {
    return <div className='loading'>Loading...</div>
  }
  // Failed to load, no org tree available
  if (failed && !tree) {
    return (
      <div className='error'>
        Something went wrong while determining your user&apos;s access scope, please try again.
      </div>
    )
  }
  return children
}
OrgScopeRequired.propTypes = {
  children: PropTypes.any.isRequired,
}

/* A React Context that makes available the org scope tree that the current
 * user has access to. This provides the following:
 * {
 *  loading: boolean, // true while busy loading
 *  failed: string,   // non-null if fetching failed
 *  tree: { id, name, type, children: []  } // Root of the org tree visible to user (nested children)
 *  byID: {} // id -> org lookup map, which also contains nested `children`
 * }
 **/
const OrgScope = createContext({ loading: false, failed: null, tree: {}, byID: {}, refresh: null })
OrgScope.displayName = 'OrgScope'

/** foldOrgByID produces a 'flat' ID -> Org lookup (as object),
 * as a fold over an org tree. */
const foldOrgsByID = (byID = {}, orgs = []) => {
  if (orgs) {
    orgs.forEach((org) => {
      if (org) {
        // TODO: There is currently a bug in the service endpoint that often returns
        // duplicates of the same merchant, but sometimes without name. As a temporary
        // solution, we merge instead of replace.
        byID[org.id] = { ...byID[org.id], ...org }

        if (org.children) {
          org.children
            // Hook up to parent by ID, so as to avoid cyclic JSON etc
            .map((child) => ({ ...child, parentID: org.id }))
            // Recurse (add all children to "by id" map)
            .reduce(foldOrgByID, byID)
        }
      }
    })
  }
  return byID
}

const foldOrgByID = (byID = {}, org = {}) => {
  if (org) {
    // TODO: There is currently a bug in the service endpoint that often returns
    // duplicates of the same merchant, but sometimes without name. As a temporary
    // solution, we merge instead of replace.
    byID[org.id] = { ...byID[org.id], ...org }

    if (org.children) {
      org.children
        // Hook up to parent by ID, so as to avoid cyclic JSON etc
        .map((child) => ({ ...child, parentID: org.id }))
        // Recurse (add all children to "by id" map)
        .reduce(foldOrgByID, byID)
    }
  }
  return byID
}

/** cleanOrg is a temporary fix to work around a back-end bug that's sending some
 * bogus, un-named duplicates. TODO: Remove once bug fixed. */
const cleanOrg = (org = {}) => {
  if (!org) {
    return null
  }
  const children = org.children
    ? org.children.filter(({ name }) => name != null).map(cleanOrg)
    : null
  return { ...org, children }
}

/** getBranchTo is a utility to produce a top-down walk down the org
 * tree to the given org. It is implemented as a recursive fold*/
export const getBranchTo = (byID = {}, walk = []) =>
  walk[0] && walk[0].parentID ? getBranchTo(byID, [byID[walk[0].parentID], ...walk]) : walk

/** Given an org, and a user's "root" org, returns the text of that
 * organization's name. Takes care of some subtleties / sensitivities,
 * such as not alerting top-level contract users to the possibility of
 * other contracts in the system, etc. */
export const orgName = (org, userRoot) =>
  org.type === TYPE_ROOT
    ? 'Global'
    : org.id === userRoot.id && userRoot.type === TYPE_CONTRACT
    ? 'All'
    : org?.name || org?.id || '?'

// operator roles have a root and show the appropriate parentID's linking back up to root. normal back-office users
// don't have a root and so the parentID is empty at the highest level, so attempt to extract the list of orgs at the
// same level.
export const getSameLevelOrgs = (byID = {}, selected = {}) => {
  const sel = byID[selected.id]
  if (sel) {
    if (sel.parentID) {
      const parent = byID[sel.parentID]
      if (parent && parent.children) {
        return parent.children
      }
    } else {
      return values(byID).filter((v) => {
        return !v.parentID
      })
    }
  }
  return [selected]
}
