import React from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'

const TypeFilter = ({ types = [], filter, setFilter }) => {
  const typeName = path(['type'], filter)
  const changed = (e) => {
    const newType = e.target.value
    setFilter({ ...filter, type: newType ? [newType] : [] })
  }

  return (
    <div className='filter types'>
      <select value={typeName || ''} onChange={changed}>
        <option value=''>Any</option>
        {types &&
          types.map((t) => {
            return (
              <option key={t.type} value={t.type}>
                {t.description}
              </option>
            )
          })}
      </select>
    </div>
  )
}

TypeFilter.propTypes = {
  types: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default TypeFilter
