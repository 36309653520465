import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { find, length, propEq, pathOr } from 'ramda'
import { useOrgScope } from '../org-scope'

/**
 * TODO replace with tree from context when its available
 */
const MerchantName = ({ id, merchants }) => {
  const { byID } = useOrgScope()
  // if we can find the merchant id, try and return merchant registered name otherwise fallback to showing only id
  const merchantDescription = (id) => {
    if (!id) {
      return '-'
    }

    // Legacy
    if (merchants && length(merchants)) {
      const merchant = merchants && length(merchants) !== 0 && find(propEq('id', id), merchants)
      if (merchant && length(merchant.registered_name) !== 0) {
        return merchant.registered_name
      } else {
        return id
      }
    } else if (byID) {
      // Look up in org scope tree
      return pathOr(id, [id, 'name'], byID)
    }
    return id
  }

  return (
    <span className={classnames({ merchant: true })}>
      <span className='name'>{merchantDescription(id)}</span>
    </span>
  )
}

MerchantName.propTypes = {
  id: PropTypes.string,
  merchants: PropTypes.array,
}

export default MerchantName
