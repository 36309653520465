import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../auth'
import { OrgScopeRequired } from '../../org-scope'
import Helmet from 'react-helmet'
import OrgScopeNavigator from '../../OrgScopeNavigator'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import {
  deleteAllowDenyEntry,
  getAllowDenyEntriesCSVURL,
  getTypes,
  postAllowDenyEntry,
  queryAllowDenyEntriesForScope,
} from '../../api'
import classnames from 'classnames'
import Choice from './Choice'
import Type from './Type'
import DateTime from '../../DateTime'
import { addEntryRequestIsWellFormed } from './validation'
import { parseAllowDenyFilters, updateSearchParamsWithFilters } from './filters'
import TypeFilter from './filters/TypeFilter'
import MatchFilter from './filters/MatchFilter'
import SettingFilter from './filters/SettingFilter'
import TimeFilter from '../../txns/filters/TimeFilter'
import TypeSelector from './TypeSelector'
import ChoiceSelector from './ChoiceSelector'

const MAX_SELECT_ALL = 1000

const AllowDeny = () => {
  const { scope } = useParams() // Scope that we're editing allow/deny for, e.g. merchant ID
  const routeMatch = useRouteMatch() // For relative path navigation
  const history = useHistory()
  const { token } = useAuth()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseAllowDenyFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)
  const [selectedEntries, setSelectedEntries] = useState(new Set())
  const [showSelectAllMessage, setShowSelectAllMessage] = useState(false)
  const [types, setTypes] = useState([])

  const fetchTypes = async () => {
    const result = await getTypes(token)
    setTypes(result)
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    fetchTypes()
  }, [])

  const searchParamsLength = Array.from(urlParams).length
  // Allow/Deny entries for this scope
  const [entries, setEntries] = useState({ loading: false, failed: null, data: null })
  const fetchEntries = async () => {
    if (!scope) {
      return
    }
    setSelectedEntries(new Set())
    setEntries({ ...entries, loading: true })
    try {
      const data = await queryAllowDenyEntriesForScope(token, scope, { filter })
      setEntries({ ...entries, loading: false, failed: null, data })
    } catch (failed) {
      setEntries({ loading: false, failed, data: null })
    }
  }

  useEffect(() => {
    fetchEntries()
  }, [scope, urlParams.toString()])

  /* Navigate to a different org via URL change */
  const onOrgChange = (id) => {
    history.push(routeMatch.path.replace(':scope', id))
  }

  const fetchMoreEntries = async (page) => {
    setEntries({ ...entries, loading: true, failed: null })
    try {
      const addEntries = await queryAllowDenyEntriesForScope(token, scope, { filter, page })
      const dataResults = [...entries.data.results, ...addEntries.results]
      setEntries({
        ...entries,
        loading: false,
        failed: null,
        data: { ...entries.data, results: dataResults },
      })
      return dataResults
    } catch (failed) {
      setEntries({ loading: false, failed, data: null })
    }
  }

  const loadableCount = Math.min(
    100,
    entries &&
      entries.data &&
      entries.data.results &&
      entries.data.results.length &&
      entries.data.page &&
      entries.data.page.total_count
      ? entries.data.page.total_count - entries.data.results.length
      : 100
  )

  const handleSelectAll = (maxLoadCount = MAX_SELECT_ALL) => {
    const lastId =
      entries && entries.data && entries.data.results && entries.data.results.length
        ? entries.data.results[entries.data.results.length - 1].reference
        : undefined

    const count =
      entries && entries.data && entries.data.results && entries.data.results.length
        ? maxLoadCount - entries.data.results.length
        : 100

    const page = { after: lastId, count: count }
    fetchMoreEntries(page).then((results) => {
      setSelectedEntries(new Set(results?.map((_, idx) => idx)))
    })
  }

  const handleLoadMore = () => {
    const lastId =
      entries && entries.data && entries.data.results && entries.data.results.length
        ? entries.data.results[entries.data.results.length - 1].reference
        : undefined
    const page = { after: lastId, count: loadableCount }
    fetchMoreEntries(page)
  }

  // Edit + post new entry
  const matchInputRef = useRef(null) // Ref to match text input field, so we can auto-focus it
  const [newEntry, setNewEntry] = useState({ saving: false, failed: null, req: {}, res: null })
  const onTypeChange = (type) => {
    setNewEntry({ ...newEntry, req: { ...newEntry?.req, type } })
    matchInputRef.current.focus() // Move cursor to text input
  }
  const onMatchChange = (e) =>
    setNewEntry({ ...newEntry, req: { ...newEntry?.req, match: e.target.value } })
  const onChoiceChange = (choice) => setNewEntry({ ...newEntry, req: { ...newEntry?.req, choice } })
  const postNewEntry = async () => {
    // TODO: If entry invalid, don't post
    setNewEntry({ ...newEntry, saving: true, failed: null, res: null })
    try {
      const res = await postAllowDenyEntry(token, scope, newEntry.req)
      setNewEntry({
        ...newEntry,
        res,
        saving: false,
        failed: null,
        // Reset match text, ready for entry of the next entry on the UI (same type, match)
        req: { ...newEntry?.req, match: '' },
      })
      // Refresh main list
      fetchEntries() // TODO: What about if we have filters?
    } catch (failed) {
      setNewEntry({ ...newEntry, failed, res: null, saving: false })
    }
  }
  const postNewEntryOnEnter = (e) => {
    if (e.key === 'Enter' && addEntryRequestIsWellFormed(newEntry.req)) {
      postNewEntry()
    }
  }

  // Remove entry
  const [delEntry, setDelEntry] = useState({ saving: false, failed: null })
  const deleteEntries = async () => {
    if (
      !window.confirm(
        `Are you sure you want to remove all ${selectedEntries.size} selected entries?`
      )
    ) {
      return
    }
    setDelEntry({ saving: true, failed: null })
    try {
      const entriesLength = entries?.data?.results?.length
      await Promise.all(
        [...selectedEntries].map((idx) => {
          if (idx < entriesLength) {
            const entry = entries?.data?.results?.[idx]
            return deleteAllowDenyEntry(token, entry)
          } else {
            // should never happen but just in case
            return Promise.resolve()
          }
        })
      )
      setDelEntry({ saving: false, failed: null })
      // Refresh main list
      fetchEntries() // TODO: What about if we have filters?
    } catch (failed) {
      setDelEntry({ saving: false, failed })
      window.alert(
        `Something went wrong while trying to remove all ${selectedEntries.size} selected entries - please try again.`
      )
    }
  }
  const deleteEntry = async (req) => {
    if (!window.confirm(`Are you sure you want to remove ${req?.match} ?`)) {
      return
    }
    setDelEntry({ req, saving: true, failed: null, res: null })
    try {
      const res = await deleteAllowDenyEntry(token, req)
      setDelEntry({ saving: false, failed: null, res })
      // Refresh main list
      fetchEntries() // TODO: What about if we have filters?
    } catch (failed) {
      setDelEntry({ saving: false, failed, res: null })
      window.alert(`Something went wrong while trying to remove ${req?.match} - please try again.`)
    }
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    // Note: It might be useful to the user to auto-disable live updates
    // when applying a filter, but other times it's annoying.
    //setUpdateLive(false)
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  const rowSelectionChanged = (e, idx) => {
    if (e.target.checked) {
      setSelectedEntries((prev) => {
        prev.add(idx)
        return new Set(prev)
      })
    } else {
      setSelectedEntries((prev) => {
        prev.delete(idx)
        return new Set(prev)
      })
    }
  }

  const checkboxRef = useRef()

  const selectAllChanged = (e) => {
    if (e.target.checked && selectedEntries?.size === 0) {
      setSelectedEntries(new Set(entries?.data?.results?.map((_, idx) => idx)))
    } else {
      setSelectedEntries(new Set())
    }
  }

  useEffect(() => {
    if (checkboxRef.current) {
      if (selectedEntries?.size === 0) {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = false
        setShowSelectAllMessage(false)
      } else if (selectedEntries?.size === entries?.data?.results?.length) {
        checkboxRef.current.checked = true
        checkboxRef.current.indeterminate = false
        setShowSelectAllMessage(true)
      } else {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = true
        setShowSelectAllMessage(false)
      }
    }
  }, [selectedEntries])

  const maxLoadSize = Math.min(MAX_SELECT_ALL, entries?.data?.page?.total_count)
  const downloadURL = getAllowDenyEntriesCSVURL(token, scope, { filter })

  return (
    <OrgScopeRequired>
      <section className='allowdeny'>
        <Helmet>
          <title>Allow / Deny - Canapay</title>
        </Helmet>
        <header>
          <div className='main'>
            <h1>Allow / Deny List </h1>
            <h4>
              <OrgScopeNavigator value={scope} onChange={onOrgChange} />
            </h4>
          </div>
          <div className='actions'>
            <Link to={`/settings/allowdeny/${scope}/test`}>
              <button className='fullwidth' title='Enter sample values against the allow/deny list'>
                <i className='fas fa-flask' /> Test value
              </button>
            </Link>
            <a href={downloadURL}>
              <button
                className='fullwidth'
                title='Export the data set currently matched, for import into a spreadsheet or other database'
              >
                <i className='fas fa-database' /> Export CSV
              </button>
            </a>
            <Link to={`/settings/allowdeny/${scope}/import`}>
              <button className='fullwidth' title='Bulk upload entries to the allow/deny list'>
                <i className='fas fa-upload' /> Upload file
              </button>
            </Link>
          </div>
        </header>
        <div className='body'>
          {!entries.loading &&
            !entries.failed &&
            searchParamsLength === 0 &&
            entries?.data?.page?.total_count === 0 && (
              <p className='none'>
                There are no <strong>allow/deny</strong> entries configured yet for this scope.
              </p>
            )}
          {entries.failed && (
            <p className='error'>
              Something went wrong while fetching entries for this scope. Please try again, or try
              navigating to a different scope.
            </p>
          )}
          {(searchParamsLength !== 0 || entries?.data?.results?.length > 0) && (
            <>
              <header className='controls'>
                {entries && entries.data && entries.data.results && (
                  <span className='summary'>
                    Showing{' '}
                    <strong className='count page_count'>{entries?.data?.results?.length}</strong>
                    {entries?.data?.page &&
                      entries?.data?.results.length !== entries?.data?.page?.total_count && (
                        <span>
                          of
                          <strong className='count total_count'>
                            {entries.data.page.total_count.toLocaleString()}
                          </strong>
                        </span>
                      )}
                    {entries?.loading && <span className='loading'>updating...</span>}
                  </span>
                )}
              </header>
              <table className='entries'>
                <thead>
                  <tr>
                    <th className='num'>
                      <h6 />#
                    </th>
                    <th className='select'>
                      <h6 />
                      <input
                        ref={checkboxRef}
                        type={'checkbox'}
                        id={'select-all'}
                        onChange={selectAllChanged}
                      />
                    </th>
                    <th className='type'>
                      <h6>Type</h6>
                      <TypeFilter types={types} filter={filter} setFilter={setFilter} />
                    </th>
                    <th className='match'>
                      <h6>Match</h6>
                      <MatchFilter filter={filter} setFilter={setFilter} />
                    </th>
                    <th className='choice'>
                      <h6>Setting</h6>
                      <SettingFilter filter={filter} setFilter={setFilter} />
                    </th>
                    <th className='at'>
                      <h6>Added at</h6>
                      <TimeFilter filter={filter} setFilter={setFilter} />
                    </th>

                    <th className='actions'>
                      <h6 />
                      <div className={'remove-selected'}>
                        <button
                          className='remove'
                          title='Remove selected entries'
                          onClick={() => deleteEntries()}
                          disabled={delEntry?.saving || selectedEntries.size === 0}
                        >
                          {' '}
                          <i className='fas fa-trash-alt' />{' '}
                        </button>
                      </div>
                    </th>
                    {/* TODO: `by` (person that added) */}
                  </tr>
                </thead>
                {showSelectAllMessage && (
                  <tbody className={'select-all-messages'}>
                    {selectedEntries?.size === maxLoadSize ? (
                      <tr className={'msg'}>
                        <td colSpan={7}>{`${selectedEntries?.size} entries selected`}</td>
                      </tr>
                    ) : (
                      <tr className={'msg'}>
                        <td colSpan={7}>
                          <span>{`${selectedEntries?.size} displayed entries are selected`}</span>
                          {maxLoadSize === entries?.data?.page?.total_count ? (
                            <button
                              onClick={() => handleSelectAll(entries?.data?.page?.total_count)}
                            >
                              Select all {entries?.data?.page?.total_count} matches
                            </button>
                          ) : (
                            <button onClick={() => handleSelectAll(maxLoadSize)}>
                              Select up to {maxLoadSize} matches
                            </button>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
                <tbody>
                  {entries?.data?.results?.map((entry, n) => (
                    <tr
                      key={entry.match}
                      className={classnames({ [entry?.choice]: entry?.choice })}
                    >
                      <td className='num'>{n + 1}.</td>
                      <td className={'select'}>
                        <input
                          type={'checkbox'}
                          id={'select'}
                          checked={selectedEntries?.has(n)}
                          onChange={(e) => rowSelectionChanged(e, n)}
                        />
                      </td>
                      <td className={classnames({ type: true, [entry?.type]: entry?.type })}>
                        <Type types={types} {...entry} />
                      </td>
                      <td className='match'>{entry.match}</td>
                      <td className='choice'>
                        <Choice {...entry} />
                      </td>
                      <td className='at'>
                        <DateTime at={entry.at} />
                      </td>
                      <td className='actions'>
                        <button
                          className='remove'
                          title='Remove this entry'
                          onClick={() => deleteEntry(entry)}
                          disabled={delEntry?.req === entry}
                        >
                          {' '}
                          <i className='fas fa-trash-alt' />{' '}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          <footer className='actions'>
            {entries?.data?.page &&
              entries?.data?.results &&
              entries?.data?.results?.length !== entries?.data?.page?.total_count && (
                <>
                  <button onClick={handleLoadMore} disabled={entries?.loading}>
                    {entries?.loading ? 'Loading...' : `Show ${loadableCount} more`}
                  </button>
                </>
              )}
          </footer>
          <table className={'entries'}>
            <tbody>
              <tr className='add-new-header'>
                <td className='num'></td>
                <td colSpan='5'>
                  <h3>Add new entry:</h3>
                </td>
              </tr>
              <tr className='add-new'>
                <td className='num'></td>
                <td className='type'>
                  <TypeSelector
                    types={types}
                    value={newEntry?.req?.type || ''}
                    onChange={onTypeChange}
                    onKeyPress={postNewEntryOnEnter}
                  />
                </td>
                <td className='match'>
                  <input
                    ref={matchInputRef}
                    type='text'
                    value={newEntry?.req?.match || ''}
                    onChange={onMatchChange}
                    onKeyPress={postNewEntryOnEnter}
                  />
                </td>
                <td className='choice'>
                  <ChoiceSelector
                    value={newEntry?.req?.choice || ''}
                    onChange={onChoiceChange}
                    onKeyPress={postNewEntryOnEnter}
                  />
                </td>
                <td className='at'></td>
                <td className='actions'>
                  <button
                    onClick={postNewEntry}
                    disabled={newEntry?.saving || !addEntryRequestIsWellFormed(newEntry?.req)}
                  >
                    <i className='fas fa-plus' /> Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          {newEntry.failed && (
            <p className='error'>
              {newEntry?.failed?.message
                ? newEntry?.failed?.message
                : 'Something went wrong when adding that entry - please try again.'}
            </p>
          )}
          {/*
          <pre>{JSON.stringify(entries, null, 2)}</pre>
          */}
        </div>
      </section>
    </OrgScopeRequired>
  )
}

export default AllowDeny
