import React, { useEffect, useState } from 'react'
import { pathOr, assocPath } from 'ramda'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { getUserRoles, removeUserRoles, addUserRoles } from '../api'
import { useOrgScope, OrgScopeRequired, TYPE_MERCHANT, TYPE_CONTRACT, TYPE_PSP } from '../org-scope'
import { CTX_TYPE_MERCHANT, CTX_TYPE_CONTRACT, CTX_TYPE_PSP } from './roles'
import { Link } from 'react-router-dom'
import { userRoles, isCtxRoleWellFormed } from './roles'
import OrgSelector from '../merchants/OrgSelector'

/** This component is an editor for a given users' roles. It is self-contained
 * in that it fetches and sends role assignments via the API, and is currently only
 * designed for use by `operator` users. */
const UserRolesAssignment = ({ userID }) => {
  const { token } = useAuth()
  // Current user roles as per back-end
  const [userRoles, setUserRoles] = useState({
    loading: false,
    failed: null,
    data: null, // List<ContextRole>
  })

  // Role being removed by the user (one at a time at the moment)
  const [removeRole, setRemoveRole] = useState({ saving: false, failed: null, data: {} })

  // Roles being added by the user
  const [addRoles, setAddRoles] = useState({ saving: false, data: [] })
  const hasAdded = addRoles.data.length > 0
  const addedValid = addRoles.data.every(isCtxRoleWellFormed)

  // Fetch user roles
  const fetchRoles = async (userID) => {
    if (!userID) {
      return
    }
    setUserRoles({ ...userRoles, failed: null, loading: true })
    try {
      const result = await getUserRoles(token, userID)
      setUserRoles({ ...userRoles, loading: false, data: result.roles })
    } catch (failed) {
      setUserRoles({ ...userRoles, loading: false, failed })
    }
  }
  useEffect(() => {
    fetchRoles(userID)
  }, [userID])

  const onRemoveExisting = (c) => async () => {
    const msg = `Role '${c.r}' ${!c.t ? 'globally' : `for ${c.t} ${c.id}`}`
    if (window.confirm(`Permanently remove for this user: ${msg} ?`)) {
      const req = {
        user: userID,
        roles: [c],
      }
      try {
        setRemoveRole({ ...removeRole, failed: null, saving: true, data: req })
        const resp = await removeUserRoles(token, req)
        setRemoveRole({ ...removeRole, saving: false, data: {} })
        // Response contains complete new set of roles for this user
        if (Array.isArray(resp.roles)) {
          setUserRoles({ ...userRoles, data: resp.roles })
        }
      } catch (failed) {
        setRemoveRole({ ...removeRole, saving: false, failed })
      }
    }
  }

  const onSaveAdded = async () => {
    try {
      const req = {
        user: userID,
        roles: addRoles.data,
      }
      setAddRoles({ ...addRoles, saving: true, failed: null })
      const resp = await addUserRoles(token, req)
      // Clear 'to add' collection
      setAddRoles({ ...addRoles, saving: false, data: [] })
      // Response contains complete new set of roles for this user
      if (Array.isArray(resp.roles)) {
        setUserRoles({ ...userRoles, data: resp.roles })
      }
    } catch (failed) {
      setAddRoles({ ...addRoles, saving: false, failed })
    }
  }

  const onAddNew = () => {
    const role = { r: null, t: null, id: null } // TODO: Smart pre-population based on existing
    setAddRoles({ ...addRoles, data: [...addRoles.data, role] })
  }

  const onReset = () => {
    setAddRoles({ data: [] })
  }

  const onRemoveNew = (n) => () => {
    const data = [...addRoles.data.slice(0, n), ...addRoles.data.slice(n + 1)]
    setAddRoles({ ...addRoles, data })
  }

  const setRole = (n) => (r) => {
    setAddRoles(assocPath(['data', n, 'r'], emptyToNull(r), addRoles))
  }
  const setCtxType = (n) => (t) => {
    setAddRoles(
      assocPath(['data', n], { ...addRoles.data[n], t: emptyToNull(t), id: null }, addRoles)
    )
  }
  const setID = (n) => (id) => {
    setAddRoles(assocPath(['data', n, 'id'], emptyToNull(id), addRoles))
  }

  return (
    <div className='user-roles editor'>
      <h3>
        <i className='fas fa-user-tag' /> Roles
      </h3>
      {userRoles.loading && <p className='loading'>Loading...</p>}
      {userRoles.failed && (
        <p className='error'>
          Something went wrong fetching user roles: {JSON.stringify(userRoles.failed)}
        </p>
      )}
      {/* Existing user roles - can only be removed */}
      {userRoles.data && (
        <div className='roles'>
          {userRoles.data.length == 0 && addRoles.data.length == 0 && (
            <p className='none'>This user does not have any roles assigned yet</p>
          )}
          {userRoles.data.map(({ r, t, id }) => (
            <div
              key={`${r}${t}${id}`}
              className={classnames({ role: true, existing: true, [r]: r })}
            >
              <span className='info'>
                <RoleName r={r} /> <ContextType t={t} />{' '}
                <OrgScopeRequired>
                  <For t={t} id={id} />
                </OrgScopeRequired>
              </span>
              <span className='actions'>
                <button
                  onClick={onRemoveExisting({ r, t, id })}
                  className='remove'
                  disabled={r === 'operator'}
                  title='Remove this role'
                >
                  -
                </button>
              </span>
            </div>
          ))}
        </div>
      )}
      {/* New roles being staged for addition */}
      {addRoles.data.map(({ r, t, id }, n) => (
        <div className='role staged' key={n}>
          <span className='info'>
            <span className='name'>
              <RoleSelector role={r} onChange={setRole(n)} />
            </span>
            <span className='context'>
              {r && <CtxTypeSelector ctxType={t} onChange={setCtxType(n)} />}
            </span>
            <span className='for'>
              {t == CTX_TYPE_MERCHANT && (
                <OrgSelector orgType={TYPE_MERCHANT} value={id} onChange={setID(n)} />
              )}
              {t == CTX_TYPE_CONTRACT && (
                <OrgSelector orgType={TYPE_CONTRACT} value={id} onChange={setID(n)} />
              )}
              {t == CTX_TYPE_PSP && (
                <OrgSelector orgType={TYPE_PSP} value={id} onChange={setID(n)} />
              )}
            </span>
          </span>
          <span className='actions'>
            <button className='remove' title='Remove' onClick={onRemoveNew(n)}>
              -
            </button>
          </span>
        </div>
      ))}
      <div className='role addnew'>
        <span className='info'></span>

        <span className='actions'>
          <button className='add' title='Add role' onClick={onAddNew}>
            +
          </button>
        </span>
      </div>
      <footer className='actions'>
        {addRoles.failed && (
          <span className='failed error'>Something went wrong adding new roles</span>
        )}
        {removeRole.failed && (
          <span className='failed error'>Something went wrong remove role</span>
        )}
        {hasAdded && (
          <button className='reset' onClick={onReset}>
            Reset
          </button>
        )}
        {hasAdded && (
          <button className='save' disabled={!addedValid || addRoles.saving} onClick={onSaveAdded}>
            {addRoles.saving && (
              <span>
                Saving {addRoles.data.length} new role{addRoles.data.length != 1 ? 's' : ''}...
              </span>
            )}
            {!addRoles.saving && (
              <span>
                Save {addRoles.data.length} new role{addRoles.data.length != 1 ? 's' : ''}
              </span>
            )}
          </button>
        )}
      </footer>
    </div>
  )
}

UserRolesAssignment.propTypes = {
  userID: PropTypes.string.isRequired,
}

/** Displays the role name */
const RoleName = ({ r }) => (
  <span className='name' title={userRoles[r]}>
    <span className={classnames({ 'role-name': true, [r]: r })}>{r}</span>
  </span>
)
RoleName.propTypes = {
  r: PropTypes.string.isRequired,
}

/** Displays what type of thing the role is for. */
const ContextType = ({ t }) => (
  <span className={classnames({ context: true, [t]: t, global: !t })}>
    {t === CTX_TYPE_MERCHANT && 'for merchant'}
    {t === CTX_TYPE_CONTRACT && 'for contract'}
    {t === CTX_TYPE_PSP && 'for acquirer'}
    {!t && 'globally'}
  </span>
)
ContextType.propTypes = {
  t: PropTypes.string,
}

/** Displays "who the role is for" */
const For = ({ t, id }) => {
  const { byID } = useOrgScope()
  const displayName = pathOr(id, [id, 'name'], byID)
  const link = t === CTX_TYPE_MERCHANT ? `/merchant/${id}` : null
  if (!id) {
    return <span className='for empty' />
  }
  return (
    <span className={classnames({ for: true })}>
      {link ? <Link to={link}>{displayName}</Link> : displayName}
    </span>
  )
}
For.propTypes = {
  t: PropTypes.string,
  id: PropTypes.string,
}

const RoleSelector = ({ role, onChange }) => {
  const roleNames = Object.keys(userRoles).sort()
  const roleChange = (e) => {
    if (onChange != null) {
      onChange(e.target.value)
    }
  }
  return (
    <select value={role || ''} onChange={roleChange} autoFocus>
      {!role && <option value=''>Select role</option>}
      {roleNames.map((r) => (
        <option key={r} value={r}>
          {r}
        </option>
      ))}
    </select>
  )
}
RoleSelector.propTypes = {
  role: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const CtxTypeSelector = ({ ctxType, onChange }) => {
  const ctxTypeChange = (e) => {
    if (onChange != null) {
      onChange(e.target.value)
    }
  }
  return (
    <select value={ctxType || ''} onChange={ctxTypeChange}>
      <option value=''>globally</option>
      <option value={CTX_TYPE_MERCHANT}>for merchant</option>
      <option value={CTX_TYPE_CONTRACT}>for contract</option>
      <option value={CTX_TYPE_PSP}>for acquirer</option>
    </select>
  )
}
CtxTypeSelector.propTypes = {
  ctxType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const emptyToNull = (v) => v || null

export default UserRolesAssignment
