import React, { useState } from 'react'
import PropTypes from 'prop-types'

// A component to render an editable text field. If the user edits the field and saves the changes, the 'onChange' callback
// will be called with the new text value, so the caller may call the appropriate endpoint to save the change
const EditableTextField = ({ value, onChange }) => {
  const [isEditing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [failed, setFailed] = useState(null)
  const [val, setVal] = useState(value)

  const onValueChange = (newValue) => {
    setVal(newValue)
  }

  const onSave = async () => {
    setFailed(null)
    setSaving(true)
    try {
      await onChange(val)
      setEditing(false)
    } catch (e) {
      setFailed(e || 'There was a problem saving this field')
    }
    setSaving(false)
  }

  const onCancel = () => {
    setFailed(null)
    setVal(value) // reset to original value
    setEditing(false)
  }

  const handleKeyDown = (e) => {
    // only handle 'Enter'
    if (e.keyCode === 13) {
      return onSave()
    }
  }

  return isEditing ? (
    <span className={'editing-field'}>
      <input
        type='text'
        value={val}
        onKeyDown={handleKeyDown}
        onChange={(e) => onValueChange(e.target.value)}
        autoFocus={true}
      />
      {saving ? (
        <span className={'saving'}>Saving....</span>
      ) : (
        <span>
          <button title={'Save'} onClick={() => onSave()} disabled={saving}>
            <i className='fas fa-check' />
          </button>
          <button title={'Cancel'} onClick={() => onCancel()} disabled={saving}>
            <i className='fas fa-ban' />
          </button>
        </span>
      )}
      {failed && <span className={'error'}>{failed}</span>}
    </span>
  ) : (
    <span className={'edit-field'}>
      {value || '-'}
      <button title={'Edit'} onClick={() => setEditing(!isEditing)}>
        <i className='fas fa-pencil-alt' />
      </button>
    </span>
  )
}

EditableTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EditableTextField
