import PropTypes from 'prop-types'
import React from 'react'

const TypeSelector = ({ types, value, onChange }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value=''>Type:</option>
    {types?.map((t) => (
      <option key={t.type} value={t.type}>
        {t.description}
      </option>
    ))}
  </select>
)

TypeSelector.propTypes = {
  types: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default TypeSelector
