import React from 'react'
import PropTypes from 'prop-types'

export const kycStatus = (status = 0) => {
  switch (status) {
    case 1:
      return <span>Confirmed Details</span>
    case 2:
      return <span>Busy Verifying</span>
    case 3:
      return <span>Failed</span>
    case 4:
      return <span>Verified</span>
  }
  return <></>
}

const Badge = ({ status = 0 }) => {
  switch (status) {
    case 1:
      return (
        <span title='Confirmed'>
          <i className='fas fa-check' />
        </span>
      )
    case 2:
      return (
        <span title='Busy Verifying'>
          <i className='fas fa-hourglass-half' />
        </span>
      )
    case 3:
      return (
        <span title='Failed'>
          <i className='fas fa-times-circle' />
        </span>
      )
    case 4:
      return (
        <span title='Verified'>
          <i className='fas fa-check-double' />
        </span>
      )
  }
  return <></>
}

Badge.propTypes = {
  status: PropTypes.number,
}

export default Badge
