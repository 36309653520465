import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './auth'
import { getContracts } from './api'
import { pick } from 'ramda'
import AccessibleContractsContext from './AccessibleContractsContext'

// DEPRECATED - use `org-scope` instead
const AccessibleContractsProvider = ({ children }) => {
  const { token } = useAuth()
  const [accessibleContracts, setAccessibleContracts] = useState()

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await getContracts(token, {})
      if (response && response.results) {
        setAccessibleContracts(response.results.map(pick(['id', 'description'])))
      }
    }
    fetchMyAPI()
  }, [token])

  const { Provider } = AccessibleContractsContext
  return <Provider value={accessibleContracts}>{children}</Provider>
}

AccessibleContractsProvider.propTypes = {
  children: PropTypes.any,
}

export default AccessibleContractsProvider
