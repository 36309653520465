import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pathOr } from 'ramda'
import { useOrgScope } from '../org-scope'

/**
 * ContractDescription renders the contract description
 */
const ContractDescription = ({ id }) => {
  const { byID } = useOrgScope()

  // if we can find the contract id, try and return contract name otherwise fallback to showing only id
  const contractDescription = (id) => {
    if (!id) {
      return '-'
    }

    // Look up in org scope tree
    return pathOr(id, [id, 'name'], byID)
  }

  return (
    <span className={classnames({ contract: true })}>
      <span className='name'>{contractDescription(id)}</span>
    </span>
  )
}

ContractDescription.propTypes = {
  id: PropTypes.string,
  contracts: PropTypes.array,
}

export default ContractDescription
