import React from 'react'
import PropTypes from 'prop-types'
import { SORT_BY_TIME_CREATED_AT, SORT_BY_TIME_UPDATED_AT } from '../filters'

/** TimeSortBy is a control, data-bound to the "filter" object,
 * which controls which key we are sorting by. This is a quick, intermediate solution
 * which only works for time-based sorting, as our back-end does not support arbitrary sorting
 * yet - which, when it does, would be better served by classical table sorting controls (arrows / carets).
 */
const TimeSortBy = ({ filter, setFilter }) => {
  // Quick simple version to filter based on one status
  const sortBy = (filter || {}).sort_by
  const changed = (e) => {
    setFilter({
      ...filter,
      sort_by: e.target.value,
    })
  }
  const sortKeyUnknown =
    sortBy != '' && sortBy != SORT_BY_TIME_CREATED_AT && sortBy != SORT_BY_TIME_UPDATED_AT
  return (
    <span className='controls sort time'>
      <select value={sortBy || SORT_BY_TIME_CREATED_AT} onChange={changed}>
        <option value={SORT_BY_TIME_CREATED_AT}>received</option>
        <option value={SORT_BY_TIME_UPDATED_AT}>updated</option>
        {sortKeyUnknown && <option value={sortBy}>{sortBy}</option>}
      </select>
    </span>
  )
}

TimeSortBy.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default TimeSortBy
