import React, { useState } from 'react'
import { getForexRates, getForexMargins } from '../../api'
import { useAuth } from '../../auth'
import { toPairs, sortBy, compose, path, map } from 'ramda'
import { DateTime } from 'luxon'
import { useInterval } from '../../timing/use-interval'
import Helmet from 'react-helmet'
import classnames from 'classnames'

const LIVE_UPDATE_INTERVAL = 20000 // ms

/** Converts the object of { pair -> {rate, at}} to an alphabetically
 * sorted list of: {pair, rate, at}. Powers rendering as table. */
const toSortedRatesList = compose(
  map(([pair, data]) => ({ ...data, pair })),
  sortBy(path([0])),
  toPairs
)

const Forex = () => {
  const { token } = useAuth()
  // Forex rates
  const [rates, setRates] = useState({ loading: false, failed: null, data: null })
  const fetchRates = async () => {
    setRates({ ...rates, loading: true })
    try {
      const data = await getForexRates(token)
      setRates({ ...rates, loading: false, failed: null, data })
    } catch (failed) {
      setRates({ ...rates, loading: false, failed })
    }
  }
  // Forex margins
  const [margins, setMargins] = useState({ loading: false, failed: null, data: null })
  const fetchMargins = async () => {
    setMargins({ ...margins, loading: true })
    try {
      const data = await getForexMargins(token)
      setMargins({ ...margins, loading: false, failed: null, data })
    } catch (failed) {
      setMargins({ ...margins, loading: false, failed })
    }
  }

  /* Fetch both once at startup, and at intervals thereafter */
  useInterval(
    () => {
      fetchRates()
      fetchMargins()
    },
    LIVE_UPDATE_INTERVAL,
    true
  )

  const ratesList = toSortedRatesList(rates?.data?.rates || {})
  const marginByPair = margins?.data?.margins || {}
  // Convenience display: Which currencies do we support
  const eurCcyList = marginByPair
    ? Object.keys(marginByPair)
        .sort()
        .map((pair) => pair.replace('/EUR', ''))
    : []

  return (
    <section className='forex'>
      <Helmet>
        <title>Forex rates - Canapay</title>
      </Helmet>
      <header>
        <div className='main'>
          <h1>Forex rates</h1>
          <h3>Real-time rates + margins to support non-native currencies</h3>
        </div>
        <div className='actions'>
          {(rates.loading || margins.loading) && <span className='loading'>Loading...</span>}
        </div>
      </header>
      <div className='body'>
        {(rates.failed || margins.failed) && (
          <p className='error'>Something went wrong while fetching data, please try again.</p>
        )}
        {eurCcyList.length > 0 && (
          <p className='supported-ccy'>
            The following currencies are supported for incoming card transactions:
            <br />
            {['EUR', ...eurCcyList].join(', ')}.
          </p>
        )}
        <table className='forex'>
          <thead>
            <tr>
              <th>Pair</th>
              <th>Rate</th>
              <th>Last updated</th>
              <th>Margin</th>
            </tr>
          </thead>
          <tbody>
            {ratesList.map(({ pair, rate, at }) => {
              const dt = at ? DateTime.fromISO(at) : undefined
              const ago = dt ? dt.toRelative() : undefined
              const hourAgo = -60 * 60 * 1000
              const upToDate = dt && dt.diffNow().valueOf() > hourAgo
              return (
                <tr key={pair}>
                  <td className='pair'>{pair || '-'}</td>
                  <td className='rate'>{rate || '-'}</td>
                  <td className={classnames({ at: true, error: !upToDate })}>{ago || '-'}</td>
                  <td className='margin'>{marginByPair[pair]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default Forex
