import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import { authenticateUser } from './api'
import { useAuth } from './auth'
import Helmet from 'react-helmet'

/** SignIn requests user authentication, and after authentication, routes
  the user to a previously-recorded destination - or to the "home" view if
  none were set. */
const SignIn = ({ location }) => {
  const history = useHistory()
  const [email, setEmail] = useState(undefined)
  const [password, setPassword] = useState(undefined)
  const [loginError, setLoginError] = useState('')
  const { setToken } = useAuth()
  const { fromURL } = location.state || { fromURL: '/' }

  const handleSubmit = () => {
    if (email && password) {
      setLoginError(null)
      authenticateUser(email, password)
        .then((res) => {
          if (!res) {
            setLoginError('That did not work, please try again')
          } else {
            setToken(res.token)
            history.push(fromURL)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setLoginError('Please provide both email and password')
    }
  }

  const handleKeyPressed = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    // TODO: See if `AuthenticatedRoute` set a user destination, and if so,
    // route user there upon login.
    <section className='sign-in'>
      <Helmet>
        <title>Sign In - Canapay</title>
      </Helmet>
      <div className='content'>
        <h1>Sign In</h1>
        <div className='field'>
          <label htmlFor='email'>email</label>
          <input
            type='email'
            id='email'
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPressed}
            autoFocus
          />
        </div>
        <div className='field'>
          <label htmlFor='password'>password</label>
          <input
            type='password'
            id='password'
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPressed}
          />
        </div>
        <footer className='actions'>
          <button onClick={handleSubmit}>Submit</button>
          {loginError !== '' && <div className='error'>{loginError}</div>}
        </footer>
        <footer className='actions'>
          <Link to='/request-account'>Request user account</Link>
          <Link to='/password-reset'>Reset password</Link>
        </footer>
      </div>
    </section>
  )
}
SignIn.propTypes = {
  location: PropTypes.object.isRequired,
}

export default SignIn
