import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { getTypes, testAllowDenyValue } from '../../api'
import { useAuth } from '../../auth'
import TypeSelector from './TypeSelector'
import { OrgScopeRequired, useOrgScope } from '../../org-scope'

const TestAllowDeny = ({ inputTypes = [] }) => {
  const { token } = useAuth()
  const { byID } = useOrgScope()
  const { scope } = useParams() // Scope that we're editing allow/deny for, e.g. merchant ID
  const [testing, setTesting] = useState(false)
  const [failed, setFailed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState()
  const [value, setValue] = useState('')
  const [types, setTypes] = useState(inputTypes)
  const [testResult, setTestResult] = useState(null)

  const fetchTypes = async () => {
    const result = await getTypes(token)
    setTypes(result)
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    if (types.length === 0) {
      setLoading(true)
      fetchTypes()
      setLoading(false)
    }
  }, [inputTypes])
  const testValue = async () => {
    setTestResult(null)
    setFailed(false)
    setTesting(true)
    try {
      const response = await testAllowDenyValue(token, { scope, type, value })
      setTestResult({ result: response?.result, why: response?.why })
    } catch (e) {
      setFailed(e)
    }
    setTesting(false)
  }

  // we only support these types for now, so filtering out the rest
  return (
    <OrgScopeRequired>
      <section className='allowdeny'>
        <Helmet>
          <title>Allow / Deny test value - Canapay</title>
        </Helmet>
        <header>
          <div className='main'>
            <h1>Test a value against the Allow / Deny List </h1>
          </div>
        </header>
        <div className='content'>
          <div className='allowdeny'>
            <table className='allowdeny'>
              <tbody>
                <tr className='merchant_contract'>
                  <td className='key'>{byID?.[scope]?.type}</td>
                  <td className='val'>{byID?.[scope]?.name}</td>
                  <td>
                    The individual merchant or contract against which the test will be applied.
                  </td>
                </tr>
                <tr className='type'>
                  <td className='key'>Type</td>
                  <td className='val'>
                    <TypeSelector types={types} value={type} onChange={setType} />
                  </td>
                  <td>Please select one of the allowed types (e.g. customer's email address).</td>
                </tr>
                <tr className='value'>
                  <td className='key'>Value</td>
                  <td className='val'>
                    <input type={'text'} value={value} onChange={(e) => setValue(e.target.value)} />
                  </td>
                  <td>The value you would like to test against the allow / deny list.</td>
                </tr>
              </tbody>
            </table>
            {testing && <p className='testing'>Testing, please wait...</p>}
            {loading && <p className='loading'>Loading data...</p>}
            {failed && (
              <p className='error'>
                {failed && failed.message ? (
                  <span>Failed with message: {failed.message}</span>
                ) : (
                  <span>
                    Something went wrong - please try again, or{' '}
                    <Link to='/support'>contact support</Link>
                  </span>
                )}
              </p>
            )}

            <footer className='actions'>
              <button onClick={testValue} disabled={testing || loading || !type || !value}>
                <i className='fas fa-flask' /> Test
              </button>
            </footer>
          </div>
          {testResult !== null && (
            <div>
              <h3>Test Result</h3>
              {!testResult?.result ? (
                <p>No matches found</p>
              ) : (
                <div>
                  <p>{testResult?.result === 'd' ? 'Deny' : 'Allow'}</p>
                  {testResult?.why?.match !== '' ? (
                    scope === testResult?.why?.scope ? (
                      <p>
                        The entered value has matched against an{' '}
                        <Link
                          to={`/settings/allowdeny/${scope}?type=${testResult?.why?.type}&match_cnts=${testResult?.why?.match}&choice=${testResult?.why?.choice}`}
                        >
                          entry
                        </Link>{' '}
                        within this scope
                      </p>
                    ) : (
                      <p>
                        The entered value has matched against an{' '}
                        <Link
                          to={`/settings/allowdeny/${testResult?.why?.scope}?type=${testResult?.why?.type}&match_cnts=${testResult?.why?.match}&choice=${testResult?.why?.choice}`}
                        >
                          entry
                        </Link>{' '}
                        in the{' '}
                        <Link to={`/settings/allowdeny/${testResult?.why?.scope}`}>
                          {byID?.[testResult?.why?.scope]?.name}
                        </Link>{' '}
                        scope
                      </p>
                    )
                  ) : (
                    <p>The entered value has matched against an entry outside of your control.</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </OrgScopeRequired>
  )
}

export default TestAllowDeny
