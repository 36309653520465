import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { getPSPs } from '../../api'
import { useAuth } from '../../auth'

const PSPFilter = ({ filter, setFilter }) => {
  // Quick simple version to filter based on one PSP
  const { token } = useAuth()
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [psps, setPsps] = useState()

  const pspName = path(['psp_any', 0], filter)
  const changed = (e) => {
    const newPSP = e.target.value
    setFilter({ ...filter, psp_any: newPSP ? [newPSP] : [] })
  }

  const fetchPsps = async () => {
    setLoading(true)
    setFailed(false)
    try {
      const result = await getPSPs(token)
      setPsps(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    fetchPsps()
  }, [])

  // Fetch merchants visible to user (TODO: Move to app-wide context)
  useEffect(() => {
    async function fetchPSPs() {
      setLoading(true)
      const response = await getPSPs(token)
      setPsps(response)
    }
    fetchPSPs()
  }, [])

  return (
    <div className='filter status'>
      <select value={pspName || ''} onChange={changed}>
        <option value=''>Any</option>
        {!loading &&
          !failed &&
          psps &&
          psps.map((s) => (
            <option key={s.id} value={s.id}>
              {s.id}
            </option>
          ))}
      </select>
    </div>
  )
}

PSPFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default PSPFilter
