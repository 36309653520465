import React, { useState, useRef } from 'react'
import { completePasswordReset, checkPassword } from './api'
import { useHistory, useLocation } from 'react-router-dom'

const PasswordResetComplete = () => {
  const history = useHistory()
  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  const passwordTimer = useRef(null)
  const [password, setPassword] = useState('')
  const [passwordScore, setPasswordScore] = useState(0)
  const [passwordCheck, setPasswordCheck] = useState('')
  const [resetError, setResetError] = useState('')
  const [noMatchError, setNoMatchError] = useState('')
  const [checkingPassword, setCheckingPassword] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const changeAndCheckPassword = (e) => {
    e.preventDefault()
    setPassword(e.target.value)
    if (passwordTimer.current) {
      clearTimeout(passwordTimer.current)
      setCheckingPassword(false)
    }
    if (e.target.value && e.target.value !== '') {
      setCheckingPassword(true)
      passwordTimer.current = setTimeout(async () => {
        try {
          const passwordScore = await checkPassword(e.target.value)
          setCheckingPassword(false)
          if (passwordScore.password === e.target.value) {
            setPasswordScore(passwordScore.score)
          }
        } catch (err) {
          console.error(err)
          setResetError(err)
          setCheckingPassword(false)
        }
      }, 100)
    }
  }

  const humanReadableScore = (score) => {
    let text = 'Unknown'
    switch (score) {
      case 0:
        text = 'Very weak'
        break
      case 1:
        text = 'Very weak'
        break
      case 2:
        text = 'Weak'
        break
      case 3:
        text = 'Moderate'
        break
      case 4:
        text = 'Strong'
        break
    }

    return text
  }

  const passwordCheckChange = (e) => {
    e.preventDefault()
    if (e.target.value != password) {
      setNoMatchError("Passwords don't match")
    } else {
      setNoMatchError(null)
    }
    setPasswordCheck(e.target.value)
  }

  const completeReset = async () => {
    setSubmitting(true)
    try {
      await completePasswordReset({ password, token })
      setSubmitting(false)
      history.push('/sign-in')
    } catch (err) {
      setSubmitting(false)
      setResetError(err)
    }
  }

  return (
    <section className='reset-complete'>
      <div className='content'>
        <h1>You&apos;re almost there!</h1>
        <div className='form'>
          <div className='field'>
            <label htmlFor='pdwinput'>New password:</label>
            <input type='password' value={password} onChange={changeAndCheckPassword} autoFocus />
          </div>
          {checkingPassword ? (
            <span>
              <i className='fas fa-spin fa-sync' />
            </span>
          ) : null}
          {password && password.length > 0 && (
            <div className='password-score'>
              <div
                className={
                  'indicator ' +
                  humanReadableScore(passwordScore).toLowerCase().replaceAll(' ', '-')
                }
              />
              <div>
                {passwordScore < 3
                  ? `${humanReadableScore(
                      passwordScore
                    )}, add more characters like upper and lower case letters, numbers, and special symbols.`
                  : passwordScore < 4
                  ? `${humanReadableScore(
                      passwordScore
                    )}, you're almost there! Add a few more characters like upper and lower case letters, numbers, and special symbols to get to a 'Strong' password.`
                  : `${humanReadableScore(passwordScore)}, nice work!`}
              </div>
            </div>
          )}

          <div className='field'>
            <label htmlFor='pdwinput'>Confirm password:</label>
            <input type='password' value={passwordCheck} onChange={passwordCheckChange} />
          </div>

          {resetError && (
            <div className='error'>
              There was a problem setting your password. Please try again or contact support.
            </div>
          )}
          {noMatchError && <div className='error'>{noMatchError}</div>}
          <footer className='actions'>
            <button
              onClick={completeReset}
              disabled={
                !passwordCheck || noMatchError || !passwordScore || passwordScore < 4 || submitting
              }
            >
              Finish
            </button>
            {submitting && (
              <span>
                <i className='fas fa-spin fa-sync' />
              </span>
            )}
          </footer>
        </div>
      </div>
    </section>
  )
}

export default PasswordResetComplete
