import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { countries } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const isoDisplay = (iso) =>
  iso == 'OTHER' // Stats return 'OTHER' as key from back-end
    ? 'OTHER'
    : iso && iso.length == 2
    ? iso
    : iso && iso.length > 2
    ? iso.substring(0, 2) + '*'
    : '-'

/** Country renders a small reference (short name, flag) to a given country, using 2-char ISO code. */
const Country = ({ iso }) => (
  <span className={classnames({ country: true, [iso]: iso })}>
    <span className='iso'>{isoDisplay(iso)}</span>{' '}
    {countries.includes(iso) && <span className='icon'>{getUnicodeFlagIcon(iso)}</span>}
  </span>
)

Country.propTypes = {
  iso: PropTypes.string,
}

export default Country
