import React, { useState, useEffect, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import decodeJWT from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import { getUserByToken } from './api'

/** High-level component that provides an authentication context - i.e. all nested
 * components have access to information about whether the current user is authenticated
 * or not. Useful at a top level in the app hierarchy to create an authentication system.
 * Lower-level components then use the `useAuth` hook to access this authentication information. */
export const AuthProvider = ({ children, tokenKey }) => {
  // Manage 'auth' data in state - initialised from storage if any
  const [auth, setAuth] = useState(getAuthFromStorage(tokenKey))
  const history = useHistory() // To push user to login page etc

  // fetch user's username (email in this case)
  useEffect(() => {
    if (auth.token === '') return
    getUserByToken(auth.token).then(({ user }) => {
      setAuth({ ...auth, email: user.username })
    })
  }, [auth?.token || ''])

  // A new token was obtained, such as by logging in or out
  const setToken = (token) => {
    if (token) {
      // Non-empty token received
      try {
        const claims = decodeJWT(token)
        const roles = tokenToRolesMap(claims)
        setAuth({ ...auth, token, claims, roles })
        localStorage.setItem(tokenKey, token)
      } catch (e) {
        // Bad token - what can we do?
        console.error(e)
        window.alert('Your authentication token was damaged. Please sign in again to restore it.')
        setAuth(emptyAuth)
        history.push('/sign-in')
      }
    } else {
      // Token removed
      setAuth(emptyAuth)
      localStorage.removeItem(tokenKey)
    }
  }

  return <Auth.Provider value={{ auth, setToken, tokenKey }}>{children}</Auth.Provider>
}
AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
  /** Key used to store or access the user's auth token in durable storage,
   * such as with window.localStorage or cookie. */
  tokenKey: PropTypes.string.isRequired,
}

/** React hook to access information about the currently-authenticated
 * user. Returns an object of:
 * {
 *  token: string,  // Raw JWT
 *  claims: {}, // Unpacked JWT containing claims
 *  roles: { roleName: []context }, // Which roles the user has, and optionally for 'who'
 *  setToken: jwt -> (), // Function to change / overwrite the token, such as in a login page
 * }
 */
export const useAuth = () => {
  const { auth, setToken } = useContext(Auth)

  return { ...auth, setToken }
}

/** The structure of the `auth` value, returned
 * and managed by the `Auth` context. */
const emptyAuth = {
  auth: {
    token: null,
    claims: null,
    roles: {},
  },
  tokenKey: '',
  emailAddress: '',
  setToken: () => {},
}

/** Auth is a react context that makes the current user authentication available to
 * child components. */
const Auth = createContext(emptyAuth) // FIXME: Make private
Auth.displayName = 'Auth'

/** Produces auth data by reading stored state in the browser. Useful
 * to initialise auth the first time. */
const getAuthFromStorage = (tokenKey) => {
  const token = localStorage.getItem(tokenKey)
  const claims = token ? decodeJWT(token) : {}
  const roles = claims ? tokenToRolesMap(claims) : {}
  return { token, claims, roles }
}

/** tokenToRolesMap accepts a decoded token, containing a nested `roles` list
 * of context roles, and produces a simple { roleName: [context]} map, ignoring
 * context type etc. It's assumed that context type will be simple enough and
 * never be mixed, for the purposes of this UI (e.g. if you are a `back-office`
 * the values will always be merchant IDs)*/
export const tokenToRolesMap = ({ roles = [] }) =>
  roles
    ? roles.reduce((m, ctxRole) => {
        const name = ctxRole.r
        if (name) {
          if (!m[name]) {
            m[name] = []
          }
          m[name].push(ctxRole)
        }
        return m
      }, {})
    : {}
