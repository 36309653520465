import { DateTime } from 'luxon'

/** parseUserFilters produce a filter object (see example) populated from the given URLSearchParams object. */
export const parseUserFilters = (params) => ({
  from: params.get('from'), // ISO date/time string, or key for `dateTimeProducer` fn
  to: params.get('to'), // ISO date/time string, or key for `dateTimeProducer` fn
  user_id_cnts: params.get('user_id_cnts'),
  user_name_cnts: params.get('user_name_cnts'),
})

/** If any of these keys are used as the value of `from` or `to` in filters, produce an absolute date using these functions. */
export const dateTimeProducer = {
  // Current moment in time
  now: () => DateTime.local().toISO(),
  // 1 hour ago
  m1h: () => DateTime.local().minus({ hours: 1 }).toISO(),
  // 24 hours ago
  m24h: () => DateTime.local().minus({ hours: 24 }).toISO(),
  // 7 days ago
  m7d: () => DateTime.local().minus({ days: 7 }).toISO(),
  // 30 days ago
  m30d: () => DateTime.local().minus({ days: 30 }).toISO(),
  // Begin / end of day
  sd: () => DateTime.local().startOf('day').toISO(),
  ed: () => DateTime.local().endOf('day').toISO(),
  // Begin / end of week
  sw: () => DateTime.local().startOf('week').toISO(),
  ew: () => DateTime.local().endOf('week').toISO(),
  // Begin / end of month
  sm: () => DateTime.local().startOf('month').toISO(),
  em: () => DateTime.local().endOf('month').toISO(),
  // Begin / end of year
  sy: () => DateTime.local().startOf('year').toISO(),
  ey: () => DateTime.local().endOf('year').toISO(),
  // Yesterday
  syd: () => DateTime.local().minus({ days: 1 }).startOf('day').toISO(),
  eyd: () => DateTime.local().minus({ days: 1 }).endOf('day').toISO(),
  // Start of "3 days ago". This, together with `eyd` allows a "Previous 3 days" range
  sm3d: () => DateTime.local().minus({ days: 3 }).startOf('day').toISO(),
  // Last month
  slm: () => DateTime.local().minus({ months: 1 }).startOf('month').toISO(),
}

/**
 * For a given date string, which could be an ISO string, or a key in `dateTimeProducer`,
 * the date will be expanded to concrete form, or returned unchanged if it does not
 * denote a producer time like `ew` (end of week).
 */
export const concretiseDate = (d) => (dateTimeProducer[d] != null ? dateTimeProducer[d]() : d)

/**
 * updateSearchParamsWithFilters takes the output of `parseUserFilters` and
 * updates the given URLSearchParams with all the values contained therein.
 * Note: For empty values in the filters object, it will remove any matching
 * values that are present in the URLSearchParams. This keeps the URL string "clean" for sharing / bookmarking.
 * On the other hand, this is an 'update' function becuase there might be other search params
 * present which has nothing to do with filtering - such as pagination, etc.
 */
export const updateSearchParamsWithFilters = (params, txnFilters = {}) => {
  // Note: We sort to ensure a stable resulting string, such that it
  // is also useful e.g. in `useEffect` to know when filters have changed.
  Object.keys(txnFilters)
    .sort()
    .forEach((p) => {
      const val = txnFilters[p]
      if (Array.isArray(val) && val.length > 0) {
        params.set(p, val.join(','))
      } else if (!Array.isArray(val) && val != null && val != '') {
        params.set(p, val)
      } else {
        params.delete(p)
      }
    })
  // Idea: Functions in object keyed by relative offset, otherwise pass-through
  return params.toString()
}

/** concretiseParams is used to make any relative parameters, like relative
 * date / time ranges, concrete, before sending to a server.
 */
export const concretiseParams = (params) => {
  // Expand known relative terms, e.g. relative times, to absolutes
  // Expand 'from' date with function if needed
  const from = params.get('from')
  if (dateTimeProducer[from] != null) {
    params.set('from', dateTimeProducer[from]())
  }
  // Expand 'to' date with function if needed
  const to = params.get('to')
  if (dateTimeProducer[to] != null) {
    params.set('to', dateTimeProducer[to]())
  }
}
