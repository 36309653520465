export const userRoles = {
  operator: 'Global administrator',
  'back-office': 'Payment controller / General back-office user',
  gdpr: 'User with whom we have a GDPR data sharing agreement, views extended customer details',
  'psp-viewer': 'Upstream acquirer / PSP transaction viewer',
  bin11: 'Allowed to view extended (11-digit) card BINs',
  'settled-vol-viewer': 'Allowed to view settlement volume stats',
}

// TODO: Certain roles like `operator` should never be in the context of an org.
// TODO: Certain other roles, like `back-office` should *always* be in the context
// of an org. Somehow encode this...

export const CTX_TYPE_MERCHANT = 'MerchantID'
export const CTX_TYPE_CONTRACT = 'ContractID'
export const CTX_TYPE_PSP = 'PSPID'

/** isCtxRoleWellFormed tests whether a context role is well-formed. While not a proper
 * validity check, it is useful to eliminate badly-formatted data. */
export const isCtxRoleWellFormed = (c) => c != null && c.r && (!c.t || c.id)
