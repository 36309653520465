import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer>
    <span className='copyright'>&copy; 2024 Canapay</span>
    <span className='links'>
      <Link to='/support'>Support</Link>
    </span>
    {/* TODO: Terms of use, etc */}
  </footer>
)

export default Footer
