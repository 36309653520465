import React, { useEffect, useState } from 'react'
import {
  getContracts,
  getMerchants,
  linkMerchantAsPartOfAnotherMerchant,
  linkMerchantAsPartOfParentContract,
} from '../api'
import { useAuth } from '../auth'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useOrgScope } from '../org-scope'

/**
 * LinkMerchantToParent a component that will link a merchant to a parent (either a contract or another merchant)
 */
const LinkMerchantToParent = ({ merchant, onSuccess, onCancel }) => {
  const { token } = useAuth()
  const { refresh } = useOrgScope()

  // All accessible merchants
  const [merchants, setMerchants] = useState([])
  const [loadingMerchants, setLoadingMerchants] = useState(false)
  const [failedMerchants, setFailedMerchants] = useState()
  // All accessible contracts
  const [contracts, setContracts] = useState([])
  const [loadingContracts, setLoadingContracts] = useState(false)
  const [failedContracts, setFailedContracts] = useState()
  // The selected parent
  const [parent, setParent] = useState()
  const [linkParentLoading, setLinkParentLoading] = useState()
  const [failedLinkParent, setFailedLinkParent] = useState()

  // Are we setting a merchant or a contract as a parent
  const [linkType, setLinkType] = useState('')

  // Fetch accessible merchants from the back-end
  const fetchMerchants = async () => {
    setLoadingMerchants(true)
    setFailedMerchants(false)
    try {
      const resp = await getMerchants(token, {})
      if (resp) {
        setMerchants(resp.results)
        // FIXME: Sort alphabetically
      }
      setLoadingMerchants(false)
    } catch (failed) {
      setFailedMerchants(failed)
      setLoadingMerchants(false)
    }
  }
  useEffect(() => {
    fetchMerchants()
  }, [])

  // Fetch contracts from the back-end
  const fetchContracts = async () => {
    setLoadingContracts(true)
    setFailedContracts(false)
    try {
      const resp = await getContracts(token, {})
      if (resp) {
        setContracts(resp.results)
      }
      setLoadingContracts(false)
    } catch (failed) {
      setLoadingContracts(false)
      setFailedContracts(failed)
    }
  }
  useEffect(() => {
    fetchContracts()
  }, [])

  const onLinkAsPartOf = async () => {
    setFailedLinkParent(null)
    setLinkParentLoading(true)
    try {
      if (linkType === 'merchant') {
        await linkMerchantAsPartOfAnotherMerchant(token, merchant.id, parent)
      } else if (linkType === 'contract') {
        await linkMerchantAsPartOfParentContract(token, merchant.id, parent)
      }
      if (refresh) {
        await refresh() // refresh org scope tree to get the latest updates
      }
      setLinkParentLoading(false)
      onSuccess()
    } catch (e) {
      setLinkParentLoading(false)
      setFailedLinkParent(`There was a problem linking this merchant ${e}`)
    }
  }

  return (
    <div className='link-parent'>
      <h3>Link {merchant.registered_name || '(un-named merchant)'} to a parent organisation</h3>
      <div className='content'>
        {(loadingMerchants || loadingContracts) && <p className='loading'>Loading data...</p>}
        {linkParentLoading && <p className='loading'>Updating...</p>}
        {(failedLinkParent || failedMerchants || failedContracts) && (
          <p className='error'>
            {failedLinkParent && failedLinkParent.code ? (
              <span>Failed with code: {failedLinkParent && failedLinkParent.code}</span>
            ) : (
              <span>
                Something went wrong - please try again, or{' '}
                <Link to='/support'>contact support</Link>
              </span>
            )}
          </p>
        )}

        <p>
          <select value={linkType} onChange={(e) => setLinkType(e.target.value)}>
            <option value=''>Parent org type:</option>
            <option value='contract'>Contract</option>
            <option value='merchant'>Merchant</option>
          </select>
        </p>

        <p>
          {linkType && linkType === 'contract' && (
            <select value={parent} onChange={(e) => setParent(e.target.value)}>
              <option value=''>Select parent contract:</option>
              {contracts.map((contract, idx) => (
                <option key={idx} value={contract.id}>
                  {contract.description}
                </option>
              ))}
            </select>
          )}
          {linkType && linkType === 'merchant' && (
            <select value={parent} onChange={(e) => setParent(e.target.value)}>
              <option value=''>Select parent merchant:</option>
              {merchants.map((merchant, idx) => (
                <option key={idx} value={merchant.id}>
                  {merchant.registered_name}
                </option>
              ))}
            </select>
          )}
        </p>
      </div>
      <p className='warning'>
        <em>
          <strong>WARNING:</strong> Linking a merchant to a parent means the parent will be able to
          see all of this merchant&apos;s transactions
        </em>
      </p>
      <footer className='actions'>
        <button onClick={onLinkAsPartOf} disabled={!parent}>
          Link up
        </button>
        <button onClick={onCancel}>Cancel</button>
      </footer>
    </div>
  )
}

LinkMerchantToParent.propTypes = {
  // The merchant object
  merchant: PropTypes.object.isRequired,
  // Success callback handler
  onSuccess: PropTypes.func,
  // Cancel callback handler
  onCancel: PropTypes.func,
}

export default LinkMerchantToParent
