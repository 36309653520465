import React from 'react'
import PropTypes from 'prop-types'

// TODO: Get supported currencies from back-end
const supported = [
  'AUD',
  'BGN',
  'CAD',
  'CHF',
  'DKK',
  'EUR',
  'GBP',
  'HRK',
  'HUF',
  'ISK',
  'NOK',
  'NZD',
  'PLN',
  'RON',
  'SEK',
  'USD',
]

/** CurrencyFilter is a simple currency drop-down filter. */
const CurrencyFilter = ({ filter, setFilter }) => {
  // For now, only suppport single-currency selection even though the API
  // in principle supports multiple.
  const ccyAny = (filter || {}).ccy_any
  const changed = (e) => {
    setFilter({
      ...filter,
      ccy_any: e.target.value,
    })
  }
  const ccyUnknown = ccyAny && ccyAny.length && !supported.includes(ccyAny[0])
  return (
    <span className='controls sort time'>
      <select
        value={ccyAny.length ? ccyAny[0] : ''}
        onChange={changed}
        title='Currency that transaction amount was submitted with'
      >
        <option value=''>any</option>
        {supported.sort().map((ccy) => (
          <option key={ccy} value={ccy}>
            {ccy}
          </option>
        ))}
        {ccyUnknown && <option value={ccyAny}>{ccyAny.join(',')}</option>}
      </select>
    </span>
  )
}

CurrencyFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default CurrencyFilter
