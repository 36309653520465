import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { testAllowDenyValue } from '../api'
import { useAuth } from '../auth'
import AddAllowDenyEntry from './AddAllowDenyEntry'
import ViewTestDetail from './ViewTestDetail'

/** AllowDenyTest is a wrapper for some content representing a value that is to
 * be tested against the allow/deny list, which then renders some auxiliary information
 * and use-cases around the provided children content:
 * 1. Indicates whether the value is allowed, denied, or no-match.
 * 2. If no match, offers the ability to insert the value, or some derived pattern therof,
 *    into the allow/deny list at a user-chosen scope within the allowed org scope tree.
 * 3. TODO: If a match, offers the ability to remove the matching pattern from the allow/deny list.
 */
const AllowDenyTest = ({ children, scope = '', type = '', types = [], value = '' }) => {
  const { token } = useAuth()

  // If any required values are missing, abort and simply render children
  if (!scope || !type || !value) {
    return children
  }

  // State: Track status of test
  const [test, setTest] = useState({ loading: false, failed: null, data: {} })
  const fetchTest = async () => {
    if (!scope) {
      return
    }
    setTest({ ...test, loading: true })
    try {
      const data = await testAllowDenyValue(token, { scope, type, value })
      setTest({ ...test, loading: false, failed: null, data })
    } catch (failed) {
      setTest({ loading: false, failed, data: null })
    }
  }

  useEffect(() => {
    fetchTest()
  }, [scope, type, value])

  const match = !test?.loading && !test?.failed && test?.data?.result != null
  const result = test?.data?.result
  const noMatch = !test?.loading && !test?.failed && test?.data?.result == null

  // Effect: Fetch whenever scopes, type, value changes, and all 3 are present
  return (
    <div
      className={classnames({
        'allow-deny-test': true,
        match: match,
        [result]: match && result,
        'no-match': noMatch,
      })}
    >
      <div className='content'>{children}</div>
      {match && result == 'd' && <DeniedControls data={test.data} onRefresh={fetchTest} />}
      {match && result == 'a' && <AllowedControls data={test.data} onRefresh={fetchTest} />}
      {noMatch && (
        <NotMatchedControls
          scope={scope}
          type={type}
          types={types}
          value={value}
          onRefresh={fetchTest}
        />
      )}
    </div>
  )
}
AllowDenyTest.propTypes = {
  /** Visually rendered inside the allow/deny wrapper. If none provided, the value is rendered as a string. */
  children: PropTypes.any,
  /** The ID of the leaf-most scope, e.g. the merchant for a transaction, that the value should be
   * tested against. The server will automatically test it against all inherited scopes as well, e.g.
   * if scope "MerchantA" is passed in, test might be against: "Root,ContractFoo,MerchantA". */
  scope: PropTypes.string,
  /** Type of the value, as per the allow/deny API, e.g. 'email', 'pan' */
  type: PropTypes.string.isRequired,
  /** The value to be tested, e.g. a particular email address */
  value: PropTypes.string.isRequired,
}

const AllowedControls = ({ data, onRefresh }) => {
  const [showDetail, setShowDetail] = useState(false) // Show in-line view / remove dialog
  const toggleShowDetail = () => setShowDetail(!showDetail)
  const onDone = () => {
    setShowDetail(false)
    if (onRefresh != null) {
      onRefresh()
    }
  }
  return (
    <>
      <div className='controls clickable' onClick={toggleShowDetail}>
        <span className='result'>
          <i className='fas fa-check' /> Allowed
        </span>
      </div>
      {showDetail && <ViewTestDetail data={data} onCancel={toggleShowDetail} onDone={onDone} />}
    </>
  )
}
AllowedControls.propTypes = {
  data: PropTypes.object,
  onRefresh: PropTypes.func,
}

const DeniedControls = ({ data, onRefresh }) => {
  const [showDetail, setShowDetail] = useState(false) // Show in-line view / remove dialog
  const toggleShowDetail = () => setShowDetail(!showDetail)
  const onDone = () => {
    setShowDetail(false)
    if (onRefresh != null) {
      onRefresh()
    }
  }
  return (
    <>
      <div className='controls clickable' onClick={toggleShowDetail}>
        <span className='result'>
          <i className='fas fa-ban' /> Denied
        </span>
      </div>
      {showDetail && <ViewTestDetail data={data} onCancel={toggleShowDetail} onDone={onDone} />}
    </>
  )
}
DeniedControls.propTypes = {
  data: PropTypes.object,
  onRefresh: PropTypes.func,
}

const NotMatchedControls = ({ scope = '', type = '', types = [], value = '', onRefresh }) => {
  const [showAdd, setShowAdd] = useState(false) // Show in-line controls to add entry
  const toggleShowAdd = () => setShowAdd(!showAdd)
  const onAddDone = () => {
    setShowAdd(false)
    if (onRefresh != null) {
      onRefresh()
    }
  }
  return (
    <>
      <div
        className='controls clickable'
        title='Add this value to the allow or deny list'
        onClick={toggleShowAdd}
      >
        <span className='result'>
          <i className='fas fa-check' /> / <i className='fas fa-ban' />
        </span>
      </div>
      {showAdd && (
        <AddAllowDenyEntry
          scope={scope}
          type={type}
          types={types}
          match={value}
          onCancel={toggleShowAdd}
          onDone={onAddDone}
        />
      )}
    </>
  )
}

NotMatchedControls.propTypes = {
  /** Scope that was used to check against - usually e.g. a Merchant or Contract ID. */
  scope: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onRefresh: PropTypes.func,
}

export default AllowDenyTest
