import React from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../auth'

/** Top-level user profile / account page */
const User = () => {
  const { roles } = useAuth()
  const roleNames = Object.keys(roles || {})
  return (
    <section className='user-profile'>
      <Header />
      <Helmet>
        <title>User control panel - Canapay</title>
      </Helmet>
      <div className='content'>
        <h1>User account</h1>
        {/* TODO: Show User ID */}
        {roleNames && (
          <h3 className='roles'>
            Account type: <em>{roleNames.join(', ')}</em>
          </h3>
        )}
        <p>
          <NavLink to='/sign-out'>
            <i className='fas fa-sign-out-alt'></i> <span className='text'>Sign out</span>
          </NavLink>
        </p>
        <p>
          <NavLink to='/password-reset'>
            <i className='fas fa-lock'></i> <span className='text'>Reset my password</span>
          </NavLink>
        </p>
      </div>
    </section>
  )
}

export default User
