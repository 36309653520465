import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import Helmet from 'react-helmet'
import { useAuth } from '../auth'
import { API_BASE_URL, handleSignIn } from '../api'

const ViewBlock = () => {
  const { token } = useAuth()
  const urlParams = new URLSearchParams(location.search)

  // blocks from request
  const [blocks, setBlocks] = useState([])

  // search params
  console.log(urlParams)
  const [id, setID] = useState(urlParams.get('id') || '')
  const [account, setAccount] = useState(urlParams.get('account') || '')
  const [hash, setHash] = useState(urlParams.get('hash') || '')

  const onSearch = async () => {
    try {
      const url = new URL(`${API_BASE_URL}/api/canachain/explorer`)
      url.searchParams.append('id', id)
      url.searchParams.append('account', account)
      url.searchParams.append('hash', hash)

      const resp = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        redirect: 'error',
      }).then(handleSignIn)

      const blocks = await resp.json()

      setBlocks(blocks)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    onSearch()
  }, [])

  return (
    <section className='explorer'>
      <Header />
      <Helmet>
        <title>Blockchain explorer - Canapay</title>
      </Helmet>
      <div className='content'>
        <Search
          id={id}
          onChangeID={setID}
          account={account}
          onChangeAccount={setAccount}
          hash={hash}
          onChangeHash={setHash}
          onSearch={onSearch}
        />
        {(!blocks || blocks.length <= 0) && <div>There are no blocks</div>}
        {blocks.map((block) => (
          <BlockDetails key={block.id} {...block} />
        ))}
      </div>
    </section>
  )
}

const Search = ({ id, account, hash, onChangeID, onChangeAccount, onChangeHash, onSearch }) => (
  <div className='search'>
    <label htmlFor='blockchain'>Blockchain Transfer/Transaction</label>
    <input
      id='blockchain'
      placeholder='e.g. 01FX7CW4M3ABSXBA445BRFSFQ'
      value={id}
      onChange={(e) => onChangeID(e.target.value)}
    />

    <label htmlFor='account'>Account on the blockchain</label>
    <input
      id='account'
      placeholder='e.g. 79054025255fb1a26e4bc422aef54eb4'
      value={account}
      onChange={(e) => onChangeAccount(e.target.value)}
    />

    <label htmlFor='hash'>Block hash</label>
    <input
      id='hash'
      placeholder='e.g. 66b1132a0173910b01ee3a15ef4e69583bbf2f7f1e4462c99efbe1b9ab5bf808'
      value={hash}
      onChange={(e) => onChangeHash(e.target.value)}
    />

    <button onClick={onSearch}>Search</button>
  </div>
)

Search.propTypes = {
  id: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  onChangeID: PropTypes.func.isRequired,
  onChangeAccount: PropTypes.func.isRequired,
  onChangeHash: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

const BlockDetails = (block) => {
  return (
    <div>
      <h3>Block {block.id}</h3>
      <table className='block' cellSpacing='0' cellPadding='0'>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{block.id}</td>
          </tr>
          <tr>
            <td>From</td>
            <td>{block.from}</td>
          </tr>
          <tr>
            <td>To</td>
            <td>{block.to}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>{block.amount}</td>
          </tr>
          <tr>
            <td>Memo</td>
            <td>{block.memo}</td>
          </tr>
          <tr>
            <td>At</td>
            <td>{block.at}</td>
          </tr>
          <tr>
            <td>Hash</td>
            <td>{block.hash}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ViewBlock
