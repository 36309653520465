import React, { useState } from 'react'
import { setMerchantAllowedTerritories } from '../api'
import { useAuth } from '../auth'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

/**
 * SetMerchantAllowedTerritories a component that will set a merchants allowed territories
 */
const SetMerchantAllowedTerritories = ({ merchant, onSuccess, onCancel }) => {
  const { token } = useAuth()
  const [territories, setTerritories] = useState(merchant?.allowed_territories || [])
  const [failed, setFailed] = useState()
  const [saving, setSaving] = useState(false)

  const onSetTerritories = async () => {
    setFailed(null)
    setSaving(true)
    try {
      await setMerchantAllowedTerritories(token, {
        merchant_id: merchant.id,
        territories: territories,
      })
      setSaving(false)
      onSuccess()
    } catch (e) {
      setSaving(false)
      setFailed(e)
    }
  }

  return (
    <div className='link-parent'>
      <h3>Set the allowed territories for {merchant.registered_name || '(un-named merchant)'}</h3>
      <div className='content'>
        <p>
          <input type='text' value={territories} onChange={(e) => setTerritories(e.target.value)} />
        </p>

        {saving && <p className='loading'>Updating...</p>}
        {failed && (
          <p className='error'>
            {failed ? (
              <span>{failed}</span>
            ) : (
              <span>
                Something went wrong - please try again, or{' '}
                <Link to='/support'>contact support</Link>
              </span>
            )}
          </p>
        )}
      </div>
      <p className='warning'>
        <em>
          <strong>WARNING:</strong> This will set all the territories the merchant may operate in
        </em>
      </p>
      <footer className='actions'>
        <button
          onClick={onSetTerritories}
          disabled={
            false /* removing for now so we can unset !territories || territories.length === 0*/
          }
        >
          Set allowed territories
        </button>
        <button onClick={onCancel}>Cancel</button>
      </footer>
    </div>
  )
}

SetMerchantAllowedTerritories.propTypes = {
  // The merchant object
  merchant: PropTypes.object.isRequired,
  // Success callback handler
  onSuccess: PropTypes.func,
  // Cancel callback handler
  onCancel: PropTypes.func,
}

export default SetMerchantAllowedTerritories
