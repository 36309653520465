import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import classnames from 'classnames'

// Supported inputs, useful to display 'help' to the user
const ulidPattern = /[0-7][0-9A-HJKMNP-TV-Z]{25}/gm
const last4Pattern = /^\*?[0-9]{4}$/g
const first6Pattern = /^[0-9]{6}\*?$/g

// CardFilter is a 'smart' filter that send a value to the back-end that has adaptive
// behaviour based on the value that's passed in:
// - If it's a ULID, match on Card ID
// - If it's a 4-digit number, optionally prefixed by a star (*1234 or 1234) patch PAN last 4 digits
// - Future: If it's a 6- or 11-digit number, optionally postfixed by a star, match
//   BIN (short or extended), e.g. 424242* or 42424242424*
const CardFilter = ({ filter, setFilter, textEntryDebounceMs = 5000 }) => {
  // Track updates in local state for a smooth typing
  // experience. Note: Backwards-compatible with old card_id_cnts filter.
  const [card, setCard] = useState(filter.card || filter.card_id_cnts)

  // updateParentFilter calls the given `setFilter`
  // based on local state, i.e. PAN to search for.
  // Note: Backwards compatible with old links using card_id_cnts
  const updateParentFilter = () => {
    setFilter({ ...filter, card_id_cnts: '', card })
  }

  // But only call `setFilter` debounced, so that we don't
  // cause rerenders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setCard(e.target.value)
    updateParentFilterDebounced.callback()
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  const isID = (card || '').match(ulidPattern)
  const isLast4 = (card || '').match(last4Pattern)
  const isFirst6 = (card || '').match(first6Pattern)
  const noMatch = filter.card && card && !isID && !isLast4 && !isFirst6

  return (
    <div
      className={classnames({
        filter: true,
        pan: true,
        error: noMatch,
        cardid: isID,
        last4: isLast4,
      })}
    >
      <input
        type='text'
        value={card || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder='ID / First 6 / Last 4'
      />
      {isID && <div className='tinysuffix'>Card ID</div>}
      {isLast4 && <div className='tinysuffix'>PAN Last 4 digits</div>}
      {isFirst6 && <div className='tinysuffix'>PAN 6-digit BIN</div>}
    </div>
  )
}

CardFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
}

export default CardFilter
