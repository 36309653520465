import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TxnStatus from './TxnStatus'

const CardHistory = ({ card_history }) => {
  if (card_history == null || card_history.length < 1) {
    return null
  }
  return (
    <div>
      <h2>Customer cards history ({card_history.length})</h2>
      {card_history.map((card) => {
        return (
          <div key={card.id} className='id'>
            <span className='val'>{card.id}</span> - BIN {card.bin}
            <table className='history'>
              <tbody>
                <tr className='success_count'>
                  <td className='key'>
                    Total <TxnStatus status='ok' />
                  </td>
                  <td className='val'>
                    {card.total_success}
                    {card.id && (
                      <Link
                        className='search-txns'
                        title='View successful transactions for this card'
                        to={`/transactions?status_any=ok&card=${encodeURIComponent(card.id)}`}
                      >
                        <i className='fas fa-search' />
                      </Link>
                    )}
                  </td>
                </tr>
                <tr className='failure_count'>
                  <td className='key'>
                    Total <TxnStatus status='failed' />
                  </td>
                  <td className='val'>
                    {card.total_failed}
                    {card.id && (
                      <Link
                        className='search-txns'
                        title='View failed transactions for this card'
                        to={`/transactions?status_any=failed&card=${encodeURIComponent(card.id)}`}
                      >
                        <i className='fas fa-search' />
                      </Link>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}
    </div>
  )
}

CardHistory.propTypes = {
  card_history: PropTypes.array.isRequired,
}
export default CardHistory
