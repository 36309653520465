import React from 'react'
import PropTypes from 'prop-types'
import { DateTime as DT } from 'luxon'

/* Renders a date range (two given ISO datetime strings) as a compact date range */
const DateRange = ({ from, to }) => {
  if (!from && !to) {
    return null
  }
  const fromStr = dateComp(from)
  const toStr = dateComp(to, true)
  return (
    <div className='date-range'>
      <span className='comp from'>{fromStr}</span>
      <span className='comp to'> - {toStr}</span>
    </div>
  )
}

DateRange.propTypes = {
  /** Luxon DateTime or string */
  from: PropTypes.any,
  /** Luxon DateTime or string*/
  to: PropTypes.any,
}

// ISODateString -> String
const dateComp = (d, tz = false) => {
  if (!d) {
    return ''
  }
  const dt = d ? DT.fromISO(d) : null
  const fmt = tz ? 'd MMM HH:mm Z' : 'd MMM HH:mm'
  return dt.toFormat(fmt)
}

export default DateRange
