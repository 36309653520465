import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { getTransactionsCSVURL } from '../api'

const downloadFormatKey = 'download-format'

function getFormatFromStorage() {
  return localStorage.getItem(downloadFormatKey)
}

/** Renders a widget that, given a `filter` specification, allows the current user to download
 * transactions matching the filter, using the user's auth token. */
const DownloadCSVLink = ({ filter = {}, displayCount }) => {
  const { token } = useAuth()
  const [format, setFormat] = useState(getFormatFromStorage() || 'csv')

  const setNewFormat = (newFormat) => {
    if (newFormat !== format) {
      setFormat(newFormat)
      localStorage.setItem(downloadFormatKey, newFormat)
    }
  }

  const csvURL = getTransactionsCSVURL(token, { filter, format })
  if (displayCount === 0) {
    return null
  }
  return (
    <span className='download csv'>
      <a href={csvURL} title='Download all transactions matching the current filter'>
        Download {displayCount != null && displayCount.toLocaleString()} as
      </a>{' '}
      <select value={format} onChange={(e) => setNewFormat(e.target.value)}>
        <option key={'csv'} value={'csv'} title={'comma separated values'}>
          {'csv'}
        </option>
        <option key={'scsv'} value={'scsv'} title={'semicolon separated values'}>
          {'scsv'}
        </option>
        <option key={'excel'} value={'excel'} title={'excel (xlsx)'}>
          {'excel'}
        </option>
      </select>
    </span>
  )
}
DownloadCSVLink.propTypes = {
  filter: PropTypes.object,
  displayCount: PropTypes.number,
}
export default DownloadCSVLink
