import React, { useContext, useEffect, useState } from 'react'
import { getUsers } from '../api'
import { useAuth } from '../auth'
import { compose, find, join, map, prop, propEq, propOr, length, uniq, reduce, append } from 'ramda'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { parseUserFilters, updateSearchParamsWithFilters } from './filters'
import UserDateTime from './UserDateTime'
import Header from '../Header'
import Footer from '../Footer'
import Helmet from 'react-helmet'
import UserIDFilter from './filters/UserIDFilter'
import AccessibleMerchantsContext from '../AccessibleMerchantsContext'
import AccessibleContractsContext from '../AccessibleContractsContext'
import UsernameFilter from './filters/UsernameFilter'
import ActiveStatus from '../ActiveStatus'

const getRoles = compose(join(','), uniq, map(prop('r')))

// Legend for the obfuscated context role object:
// Role        `json:"r"`
// ContextType `json:"t"`
// ContextID   `json:"id"`
const getMerchantIDs = compose(
  map(prop('id')),
  reduce((r, contextRole) => {
    if (propEq('t', 'MerchantID', contextRole)) {
      return append(contextRole, r)
    }
    return r
  }, [])
)
const getContractIDs = compose(
  map(prop('id')),
  reduce((r, contextRole) => {
    if (propEq('t', 'ContractID', contextRole)) {
      return append(contextRole, r)
    }
    return r
  }, [])
)

/** ListUsers renders a high-level user listing which displays a filtered, paginated list of users. */
const ListUsers = () => {
  const { token } = useAuth()
  const accessibleMerchants = useContext(AccessibleMerchantsContext)
  const accessibleContracts = useContext(AccessibleContractsContext)

  // User data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [users, setUsers] = useState()
  //const [accessibleMerchants, setAccessibleMerchants] = useState([])

  // Parse parameters into filter object (which drives filters UI state) Note: We are using the URL as the "state" here,
  // and updates perform a history replacement (so as not to mess up "back" button behaviour too much - though we may
  // want to apply a heuristic like small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseUserFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // How we fetch users from the back-end
  const fetchUsers = async () => {
    setLoading(true)
    setFailed(false)
    try {
      const result = await getUsers(token, { filter })
      setUsers(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // Short-term solution until we have proper pagination: This
  // powers a "Shot xxx more" button at the bottom, and appends it
  // to the current set. Importantly, it also disables live loading,
  // otherwise things would get interesting. And of course, if you scroll
  // to the top and change filters, you have to scroll down again and "Load more"
  // if you want to, i.e. data is replaced.
  const fetchMoreUsers = async (page) => {
    setLoading(true)
    setFailed(false)
    try {
      const addUsers = await getUsers(token, { filter, page })
      // Append data. Note: This totally ignores any 'newer' users that
      // may have appeared, so we also don't update top-level counts.
      const newUsers = { ...users, results: [...users.results, ...addUsers.results] }
      setUsers(newUsers)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // How many more users are available to load?
  const loadableCount = Math.min(
    100,
    users && users.results && users.results.length && users.page && users.page.total_count
      ? users.page.total_count - users.results.length
      : 100
  )

  // Event handler for 'Show xxx more' button that was clicked
  const handleLoadMore = () => {
    const lastID =
      users && users.results && users.results.length
        ? users.results[users.results.length - 1].user.id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreUsers(page)
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // Fetch users: Initially, and every time filters change
  useEffect(() => {
    fetchUsers()
  }, [urlParams.toString()])

  const mapToMerchantNames = compose(
    join(','),
    map((id) => propOr('-', 'registered_name', find(propEq('id', id), accessibleMerchants)))
  )

  const mapToContractNames = compose(
    join(','),
    map((id) => propOr('-', 'description', find(propEq('id', id), accessibleContracts)))
  )

  return (
    <section className='users'>
      <Header />
      <Helmet>
        <title>Users - Canapay</title>
      </Helmet>
      <div className='content'>
        {loading && !users && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && users && users.results && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>
                  {users.results.length.toLocaleString()}
                </strong>
                {users.page && users.results.length !== users.page.total_count && (
                  <span>
                    of
                    <strong className='count total_count'>
                      {users.page.total_count.toLocaleString()}
                    </strong>
                  </span>
                )}
                user accounts
                {loading && <span className='loading'>updating...</span>}
              </span>
            </header>
            <table className='users'>
              <thead>
                <tr>
                  <th className='num'>#</th>
                  <th className='username'>
                    <h6>User name</h6>
                    <UsernameFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='status'>
                    <h6>Status</h6>
                  </th>
                  <th className='id'>
                    <h6>ID</h6>
                    <UserIDFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='time'>
                    <h6>Created At</h6>
                  </th>
                  <th className='roles'>
                    <h6>Roles</h6>
                  </th>
                  <th className='merchants'>
                    <h6>Merchants</h6>
                  </th>
                  <th className='contracts'>
                    <h6>Contracts</h6>
                  </th>
                  <th className='actions' />
                </tr>
              </thead>
              <tbody>
                {users.results.map((userInfo, i) => {
                  const detailURI = `/user/${userInfo.user.id}`
                  const merchantIDs = userInfo.roles ? getMerchantIDs(userInfo.roles) : []
                  const merchantNames =
                    merchantIDs && length(merchantIDs) !== 0 && accessibleMerchants
                      ? mapToMerchantNames(merchantIDs)
                      : '-'
                  const contractIDs = userInfo.roles ? getContractIDs(userInfo.roles) : []
                  const contractNames =
                    contractIDs && length(contractIDs) !== 0 && accessibleContracts
                      ? mapToContractNames(contractIDs)
                      : '-'
                  return (
                    <tr className='user' key={userInfo.user.id}>
                      <td className='num'>{i + 1}.</td>
                      <td className='username clickable' onClick={() => history.push(detailURI)}>
                        {userInfo.user.username || '-'}
                      </td>
                      <td className='status'>
                        <ActiveStatus {...userInfo.user} />
                      </td>
                      <td className='id'>
                        <span className='id'>{userInfo.user.id || '-'}</span>
                      </td>
                      <td className='time'>
                        <UserDateTime at={userInfo.user.created_at} />
                      </td>
                      <td className='roles'>{userInfo.roles ? getRoles(userInfo.roles) : '-'}</td>
                      <td className='merchants'>{merchantNames}</td>
                      <td className='contracts'>{contractNames}</td>
                      <td className='actions'>
                        <Link to={detailURI}>Detail</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <footer className='actions'>
              {users.page && users.results.length !== users.page.total_count && (
                <>
                  <button onClick={handleLoadMore} disabled={loading}>
                    {loading ? 'Loading...' : `Show ${loadableCount} more`}
                  </button>
                </>
              )}
            </footer>
          </div>
        )}
        {!failed && users && users.results && users.results.length === 0 && (
          <div className='no-results'>There are no users to show</div>
        )}
        <Footer />
      </div>
    </section>
  )
}

export default ListUsers
