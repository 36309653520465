import { concretiseParams, updateSearchParamsWithFilters } from './txns/filters'
import { updateSearchParamsWithPagination } from './page'
import { compose, length, map, split, toUpper, trim } from 'ramda'

// Client-side (i.e. in-browser) API proxy
// to call the merchant and operator
// back-end. Authentication happens per-call using
// a provided token.
export const API_BASE_URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8090'
    : `https://${window.location.hostname}`

/** Given a `fetch` response, handles redirecting the
 * user to the sign-in page if server sends a 401 unauthorized,
 * otherwise simply returns the given response. */
export const handleSignIn = (response) => {
  if (!response.ok) {
    // Trap 401 and route user to login page
    if (response.status === 401) {
      if (console && console.warn) {
        console.warn('Redirecting user to login page because 401 received: ', response)
      }
      // TODO: Should we do this (which could also be useful in triggering
      // a page load, updating e.g. stale app code) or should we use the
      // history API (react-router) ?
      if (window.location.href.indexOf('/sign-in') == -1) {
        // Prevent redirect loop
        window.location.href = '/sign-in'
      }
    }
    // Pass-through any other error
    if (console && console.warn) {
      console.warn('Error response received: ', response)
    }
    throw response
  }
  return response
}

/** Ping the server to check if it's up. Requires valid token. */
export const ping = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/ping`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.text()) // <- Server returns text, not JSON, in this case

/**
 * Retrieve a list of transactions, optionally filtered and paginated
 * using the given query object.
 */
export const getTransactions = (bearerToken, { /* scope,*/ filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)

  return fetch(`${API_BASE_URL}/api/transactions?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Retrieve a list of merchants, optionally filtered and paginated
 * using the given query object.
 */
export const getMerchants = (bearerToken, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)
  return fetch(`${API_BASE_URL}/api/merchants?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Activate a merchant
 */
export const activateMerchant = (bearerToken, merchant_id, reason) => {
  return fetch(`${API_BASE_URL}/api/merchant/activate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchant_id,
      reason: reason,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Deactivate a merchant
 */
export const deactivateMerchant = (bearerToken, merchant_id, reason) => {
  return fetch(`${API_BASE_URL}/api/merchant/deactivate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchant_id,
      reason: reason,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Activate a merchants test card access
 */
export const activateMerchantTestCardAccess = (bearerToken, merchant_id) => {
  return fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/test-card/enable`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Deactivate a merchants test card access
 */
export const deactivateMerchantTestCardAccess = (bearerToken, merchant_id) => {
  return fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/test-card/disable`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * set a merchants secret
 */
export const setMerchantSecret = (bearerToken, merchant_id, secret) => {
  return fetch(`${API_BASE_URL}/api/merchant/secret`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchant_id,
      secret: secret,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Retrieve a list of contracts
 */
export const getContracts = (bearerToken, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)
  return fetch(`${API_BASE_URL}/api/contracts?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => {
      if (r.status === 200) {
        return r.json()
      }
      throw r.text()
    })
}

export const getContractById = (bearerToken, id) => {
  return fetch(`${API_BASE_URL}/api/contract/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/** Produces a URL that, when visited by the client, will result in a CSV download
 * of the transactions that the client is allowed to see, filtered by the given
 * filter. */
export const getTransactionsCSVURL = (bearerToken, { filter, format = 'csv' }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', format)
  return `${API_BASE_URL}/api/transactions?${params.toString()}`
}

/** Retrieve a single transaction. */
export const getTransaction = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/transaction/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Retrieve a single transaction. */
export const getBuyByReference = (bearerToken, reference) =>
  fetch(`${API_BASE_URL}/api/orders/buy/reference/${reference}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Retrieve a single transaction. */
export const getTransferByReference = (bearerToken, reference) =>
  fetch(`${API_BASE_URL}/api/orders/transfer/reference/${reference}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Retrieve a single merchant. */
export const getMerchant = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/merchant/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/**
 * Produces a URL that, when visited by the client, will result in a CSV download
 * of the merchants that the client is allowed to see, filtered by the given filter.
 */
export const getMerchantsCSVURL = (bearerToken, { filter }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', 'csv')
  return `${API_BASE_URL}/api/merchants?${params.toString()}`
}

/** Try and authenticate user and if successful
 * store the token in session storage.
 */
export const authenticateUser = (email, password) =>
  fetch(`${API_BASE_URL}/api/auth`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    redirect: 'error',
    body: JSON.stringify({
      strategy: 1,
      password,
      id_in_strategy: email,
    }),
  }).then((r) => {
    if (r.status == 200) {
      return r.json()
    }
    return null
  })

export const syncTransaction = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/payment/transaction/${id}/sync`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  }).then((r) => r.json())

export const initPasswordReset = (c) =>
  fetch(`${API_BASE_URL}/api/auth/reset/init`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(c),
  }).then((r) => r.json())

export const completePasswordReset = async (c) => {
  const resp = await fetch(`${API_BASE_URL}/api/auth/reset`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(c),
  })
  if (resp.status === 200) {
    return resp.json()
  } else {
    throw await resp.text()
  }
}

export const checkPassword = async (password) => {
  const resp = await fetch(`${API_BASE_URL}/api/auth/password/score`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    },
    redirect: 'error',
    body: JSON.stringify({ Password: password }),
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const getAccessibleMerchants = async (bearerToken) => {
  const resp = await fetch(`${API_BASE_URL}/api/merchants?count=1000`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const getUsers = async (bearerToken, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)

  const resp = await fetch(`${API_BASE_URL}/api/auth/users?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

/** Retrieve a single user */
export const getUser = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Retrieve a single user's roles.
 * Returns Promise<{user: UserID, roles: List<ContextRole>}>
 */
export const getUserRoles = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}/roles`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** enable a user */
export const enableUser = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}/enable`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** disable a user */
export const disableUser = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}/disable`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * invite a user
 */
export const inviteUser = (bearerToken, orgType, orgId, email) => {
  return fetch(`${API_BASE_URL}/api/${orgType}/${orgId}/users/invites`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      email: email,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const getPSPs = async (bearerToken) => {
  const resp = await fetch(`${API_BASE_URL}/api/admin/psps`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

/*
 * Link a merchant as part of a parent contract
 */
export const linkMerchantAsPartOfParentContract = async (
  bearerToken,
  child_merchant_id,
  parent_contract_id
) => {
  const response = await fetch(
    `${API_BASE_URL}/api/contract/${parent_contract_id}/link/merchant/${child_merchant_id}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      redirect: 'error',
    }
  )

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown contract')
        case 2:
          throw Error('Unknown merchant')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * Link a merchant as part of a parent merchant
 */
export const linkMerchantAsPartOfAnotherMerchant = async (
  bearerToken,
  child_merchant_id,
  parent_merchant_id
) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${child_merchant_id}/part-of`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(parent_merchant_id),
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown parent merchant')
        case 2:
          throw Error('Unknown child merchant')
        case 3:
          throw Error('Already linked')
        case 4:
          throw Error('Invalid tree.  Merchant is already a parent')
        case 5:
          throw Error('Invalid tree.  Parent is already a child')
        case 6:
          throw Error('Invalid tree.  Parent must be different from child')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * UnLink a merchant from a parent merchant
 */
export const unlinkMerchantFromParentMerchant = async (bearerToken, merchant_id) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/part-of`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown merchant')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * UnLink a merchant from a parent merchant
 */
export const unlinkMerchantFromContract = async (bearerToken, merchant_id, contract_id) => {
  const response = await fetch(
    `${API_BASE_URL}/api/contract/${contract_id}/unlink/merchant/${merchant_id}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      redirect: 'error',
    }
  )

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown contract')
        case 2:
          throw Error('Unknown merchant')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * Register a merchant
 */
export const registerMerchant = async (bearerToken, merchant) => {
  const response = await fetch(`${API_BASE_URL}/api/merchants`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(merchant),
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Required field is missing')
        case 2:
          throw Error('Input field is malformed')
        case 3:
          throw Error('Parent not found')
        case 4:
          throw Error('Duplicate merchant')
        case 5:
          throw Error('Parent cannot have a child added to it')
      }
    }
    throw await error
  }
  throw await response.text()
}

/** Retrieves the org tree that the caller has access to.
 * This returns a tree of Root -> Contract -> Merchant -> Merchant (brand)
 * of any depth.
 */
export const getMyOrgScopeTree = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/auth/scope/me`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * Forex rates currently in use
 */
export const getForexRates = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/admin/forex/rates`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * Forex margins currently in use
 */
export const getForexMargins = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/admin/forex/margins`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Obtain allow/deny entries for a given scope */
export const queryAllowDenyEntriesForScope = (token, scope, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)

  return fetch(`${API_BASE_URL}/api/allowdeny/${scope}?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const getAllowDenyEntriesCSVURL = (bearerToken, scope, { filter }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', 'csv')
  return `${API_BASE_URL}/api/allowdeny/${scope}?${params.toString()}`
}

/** Post a new allow/deny entry */
export const postAllowDenyEntry = async (bearerToken, scope, req) => {
  const resp = await fetch(`${API_BASE_URL}/api/allowdeny/${scope}/entries`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(req),
  })

  if (resp.status === 401) {
    return handleSignIn(resp)
  }
  // Expected response
  if (resp.status === 200) {
    return resp.json()
  }

  if (resp.status === 400) {
    const r = await resp.json()
    const code = r?.code
    let msg = 'There was a problem adding the entry.'
    if (code && code !== 0) {
      switch (code) {
        case 1:
          msg += ' Invalid type.'
          break
        case 2:
          msg += ' Invalid value.'
          break
        case 3:
          msg += ' Invalid choice.'
          break
        case 4:
          msg += ' Duplicate entry.'
          break
        case 5:
          msg +=
            ' Conflicting entry - another entry exists in this organisation tree for the same value with a different setting.'
          break
      }
    }
    throw new Error(msg)
  }
  throw await resp.text()
}

/** Obtain allow/deny entries for a given scope. */
export const deleteAllowDenyEntry = (token, { scope, type, match }) =>
  fetch(
    `${API_BASE_URL}/api/allowdeny/${scope}/entries-by-type/${encodeURIComponent(
      type
    )}/${encodeURIComponent(match)}`,
    {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'error',
    }
  )
    .then(handleSignIn)
    .then(() => ({})) // Note: Service returns no data, for convenience, we return an object

/** Test a given value of a given type, against the allow/deny list(s) for the given scopes.
 * The scopes are IDs, in-order, e.g. [root, contract, merchant, brand] */
export const testAllowDenyValue = (token, { scope = '', type = '', value = '' }) =>
  fetch(
    `${API_BASE_URL}/api/allowdeny/${encodeURIComponent(scope)}/test-by-type/${encodeURIComponent(
      type
    )}/${encodeURIComponent(value)}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'error',
    }
  )
    .then(handleSignIn)
    .then((r) => r.json())

// TODO: Test value against scopes - note: tricky to figure out scopes! (path, e.g. global -> celoxo -> merchA)

/** Retrieves the user using the auth token
 * This returns the user's details
 */
export const getUserByToken = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/auth/user/token/${bearerToken}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    redirect: 'error',
  }).then((r) => {
    if (!r.ok) {
      throw r.text()
    }
    return r.json()
  })

/** PUT a request to change the status of a transactoin */
export const updateTransactionStatus = (token, { transaction_id, status }) =>
  fetch(`${API_BASE_URL}/api/transaction/${transaction_id}/status/${status}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: status,
      // TODO: Optional notes
    }),
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** POST a request to generate a new API token for a merchant */
export const generateMerchantToken = (token, merchant_id) =>
  fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/tokens`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** POST a request to refund a transaction by a given amount */
export const refundTransaction = (token, { transaction_id, amount }) =>
  fetch(`${API_BASE_URL}/api/transaction/${transaction_id}/refund`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      amount,
    }),
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/**
 * Retrieves a merchant statistics data rollup for a given merchant and filters.
 */
export const getMerchantCTxnsRollup = (bearerToken, { merchantID, filter }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  return fetch(`${API_BASE_URL}/api/merchant/${merchantID}/ctxns-rollup?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/** Produces a URL that, when visited by the client, will result in a CSV download
 * of the CTxns (compensating transactions) for a particular merchant and time window. */
export const getMerchantCTxnsCSVURL = (bearerToken, { merchantID, filter, format = 'csv' }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', format)
  return `${API_BASE_URL}/api/merchant/${merchantID}/ctxns?${params.toString()}`
}

/** PUT a setting for the given merchant: Must incoming transactions match a whitelist */
export const setMerchantTxnsMustMatchAllowList = async (
  token,
  { merchant_id, txns_must_match_allow_list }
) => {
  const response = await fetch(
    `${API_BASE_URL}/api/merchant/${merchant_id}/requires-match-allow-list`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(txns_must_match_allow_list), // Naked boolean value
      redirect: 'error',
    }
  )

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }
}

/** PUT a setting for the given merchant: Must cardholders visibly consent to a crypto buy + send
 * during the payment flow. */
export const setMerchantRequiresCryptoConsent = async (
  token,
  { merchant_id, cust_must_confirm_crypto }
) => {
  const response = await fetch(
    `${API_BASE_URL}/api/merchant/${merchant_id}/requires-crypto-consent`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(cust_must_confirm_crypto), // Naked boolean value
      redirect: 'error',
    }
  )

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }
}

export const setMerchantKYCRequiredSetting = async (token, { merchant_id, kyc_required }) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/kyc-setting`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(kyc_required),
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }
}

export const setMerchantIsFTD = async (token, { merchant_id, is_ftd }) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/ftd-setting`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(is_ftd), // Naked boolean value
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }
}

export const setMerchantAllowedTerritories = async (token, { merchant_id, territories }) => {
  let allowedTerritories = compose(map(compose(trim, toUpper)), split(','))(territories)
  if (length(allowedTerritories) === 1 && allowedTerritories[0] === '') {
    allowedTerritories = []
  }
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/territories`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(allowedTerritories), // Naked [] value
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }

  throw await response.text()
}

export const setMerchantAllowedCurrencies = async (token, { merchant_id, currencies }) => {
  let allowedCurrencies = compose(map(compose(trim, toUpper)), split(','))(currencies)
  if (length(allowedCurrencies) === 1 && allowedCurrencies[0] === '') {
    allowedCurrencies = []
  }
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/currencies`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(allowedCurrencies), // Naked [] value
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }

  throw await response.text()
}

export const setMerchantIsGatewayCustomer = async (token, { merchant_id, is_gateway_customer }) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/gw-setting`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(is_gateway_customer), // Naked boolean value
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  // Expected response
  if (response.status === 204) {
    return
  }

  throw await response.text()
}

export const getTypes = async (bearerToken) => {
  const resp = await fetch(`${API_BASE_URL}/api/allowdeny/types`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const addUserRoles = async (token, req = {}) => {
  const resp = await fetch(`${API_BASE_URL}/api/merchant/users/roles/add`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(req), // Naked boolean value
    redirect: 'error',
  })

  if (resp.status === 401) {
    return handleSignIn(resp)
  }
  // Expected response
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const removeUserRoles = async (token, req = {}) => {
  const resp = await fetch(`${API_BASE_URL}/api/merchant/users/roles/remove`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(req), // Naked boolean value
    redirect: 'error',
  })

  if (resp.status === 401) {
    return handleSignIn(resp)
  }
  // Expected response
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const bulkUpload = async (token, req = {}) => {
  const resp = await fetch(
    `${API_BASE_URL}/api/allowdeny/${req.scope}/${req.type}/import?choice=${req.choice}&ignore_header=${req.ignoreHeader}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': req.file.type,
        'Content-Length': `${req.file.size}`,
      },
      body: req.file,
      redirect: 'error',
    }
  )

  if (resp.status === 401) {
    return handleSignIn(resp)
  }
  // Expected response
  if (resp.status === 200) {
    return resp.json()
  }

  if (resp.status === 400) {
    const r = await resp.json()
    const code = r?.code
    const line = r?.line_number
    let msg = 'There was a problem importing the file.'
    if (line && line !== 0) {
      msg += ` First error encountered on line number ${line}.`
    }
    if (code && code !== 0) {
      switch (code) {
        case 1:
          msg += ' Invalid type.'
          break
        case 2:
          msg += ' Invalid choice.'
          break
        case 3:
          msg += ' Invalid email.'
          break
        case 4:
          msg += ' Invalid masked PAN.'
          break
        case 5:
          msg += ' Unsupported type.'
          break
      }
    }
    throw new Error(msg)
  }

  // todo refusal mapping
  throw await resp.text()
}

export const createContract = async (bearerToken, contract) => {
  const response = await fetch(`${API_BASE_URL}/api/contract`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(contract),
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  throw await response.text()
}

export const renameMerchant = async (bearerToken, { merchantId, registeredName, tradingAs }) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchantId}/name`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchantId,
      registered_name: registeredName,
      trading_as: tradingAs,
    }),
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  throw await response.text()
}

export const getReceipt = async (bearerToken, { txnId, type }) => {
  const response = await fetch(`${API_BASE_URL}/api/receipts/${txnId}/${type}-receipt`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  throw await response.text()
}

export const getCASContent = async (bearerToken, { txnId, casId }) => {
  const response = await fetch(`${API_BASE_URL}/api/transaction/${txnId}/response/${casId}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  throw await response.text()
}

/** Reports: Merchant settlement: Generates a URL given a transaction filter (mostly just for
 * the dates) for a given merchant, authenticated with the given bearer token.
 * */
export const getReportMerchantSettlementURL = (bearerToken, { filter, merchantID }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  return `${API_BASE_URL}/api/reports/merchant/${merchantID}/settlement/computable?${params.toString()}`
}

export const setContractDescription = async (bearerToken, { contractId, description }) => {
  const response = await fetch(`${API_BASE_URL}/api/contract/${contractId}/description`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: description,
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  throw await response.text()
}
