import React from 'react'
import { render } from 'react-dom'
import App from './App'

// Enable module hot-reloading for Parcel bundler
if (window.module && window.module.hot) {
  window.module.hot.accept()
}

// Mount the app
// TODO: Quick hack until we figure out server-side URL blacklisting
if (window.location.hostname && window.location.hostname.indexOf('epayme.tech') == -1) {
  render(<App />, document.getElementById('root'))
}
