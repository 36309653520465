import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Helmet from 'react-helmet'
import Header from '../Header'
import { useOrgScope } from '../org-scope'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import TimeFilter from '../txns/filters/TimeFilter'
import { parseTransactionFilters, updateSearchParamsWithFilters } from '../txns/filters'
import {
  getMerchantCTxnsCSVURL,
  getMerchantCTxnsRollup,
  getReportMerchantSettlementURL,
} from '../api'
import { useAuth } from '../auth'
import { isOperator } from '../auth-roles'
import DateTime from '../DateTime'
import AmountWithCurrency from '../AmountWithCurrency'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import MerchantSelector from './MerchantSelector'
import { majorErrorCodes } from '../txns/error-codes'

// MerchantTxnStats renders a tree-shaped data rollup of the transaction statistics for
// a selected merchant over a selected timeframe.
const MerchantTxnStats = () => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)

  // Which merchant is selected?
  const { byID } = useOrgScope()
  const params = useParams()
  const merchant = byID ? byID[params.id] || {} : {}

  // Parse parameters into filter object (which drives filters UI state)
  // Note: We are using the URL as the "state" here, and updates
  // perform a history replacement (so as not to mess up "back" button
  // behaviour too much - though we may want to apply a heuristic like
  // small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseTransactionFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // Fetch statistics / data rollup
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [stats, setStats] = useState()
  const fetchMerchantStats = async () => {
    setLoading(true)
    setFailed(false)
    try {
      const result = await getMerchantCTxnsRollup(token, { merchantID: params.id, filter })
      setStats(result)
      if (filter.by_psp == 'true') {
        const ccyVals = result ? Object.values(result.by_ccy || {}) : []
        const psps = new Set()
        ccyVals?.forEach((v) => {
          const x = v?.c?.['Accepted']?.c
          Object.keys(x || {}).forEach((p) => psps.add(p))
        })
        setPSPSelections(psps)
      } else {
        setPSPSelections(null)
        setPSP('All')
      }
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    fetchMerchantStats()
  }, [params.id, urlParams.toString()])

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }
  const onIncludePSPDetailsChange = (e) => {
    if (e.target.checked) {
      setFilter({ ...filter, by_psp: 'true' })
    } else {
      setFilter({ ...filter, by_psp: null })
    }
  }

  const [psp, setPSP] = useState('All')
  const [pspSelections, setPSPSelections] = useState()

  // CSV Download
  const downloadFormatKey = 'download-format'
  const [format, setFormat] = useState(localStorage.getItem(downloadFormatKey) || 'csv')
  const csvURL = getMerchantCTxnsCSVURL(token, { merchantID: merchant.id, filter, format })
  const onDownloadFormatChange = (e) => {
    if (e.target.value !== format) {
      setFormat(e.target.value)
      localStorage.setItem(downloadFormatKey, e.target.value)
    }
  }

  // Data rendering
  const ccys = (stats ? Object.keys(stats.by_ccy || {}) : []).sort(cTxnSortOrder)
  const [showJSON, setShowJSON] = useState(false)

  // Merchant selector
  const onMerchantChange = (merchantID) => {
    history.push(`/merchant/${merchantID}/txn-stats${location.search}`)
  }

  // TEMPORARY: Download corresponding settlement report, until it has its
  // own reporting section.
  const reportMerchantSettlementURL = getReportMerchantSettlementURL(token, {
    merchantID: merchant.id,
    filter,
  })
  var settlementReportURL
  if (!psp || psp == '' || psp == 'All') {
    settlementReportURL = reportMerchantSettlementURL
  } else {
    settlementReportURL = `${reportMerchantSettlementURL}&psp=${psp}`
  }

  return (
    <section className='merchant stats'>
      <Header />
      <Helmet>
        <title>{(stats && stats.merchant_name) || 'Merchant'} stats - Canapay</title>
      </Helmet>
      <div className='content'>
        <header className='controls'>
          <div className='headings'>
            <h1>
              Merchant stats -{' '}
              {isUserOperator ? (
                <Link to={`/merchant/${merchant.id}`}>{merchant.name}</Link>
              ) : (
                merchant.name
              )}
            </h1>
            <div>
              {!loading && !failed && stats && (
                <>
                  <DateTime at={stats.from} /> - <DateTime at={stats.to} />
                </>
              )}
              {loading && 'Loading...'}
            </div>
          </div>
          <MerchantSelector value={params.id} onChange={onMerchantChange} />
          <div>
            <TimeFilter filter={filter} setFilter={setFilter} />
          </div>
          {isUserOperator && (
            <div>
              <label>
                <input
                  type='checkbox'
                  checked={filter.by_psp == 'true'}
                  onChange={onIncludePSPDetailsChange}
                />{' '}
                By Acquirer
              </label>
            </div>
          )}
          <div>
            <span className='download csv'>
              <a href={csvURL} title='Download raw transaction events'>
                Download matching transaction list as
              </a>
              <select value={format} onChange={onDownloadFormatChange}>
                <option key={'csv'} value={'csv'} title={'comma separated values'}>
                  {'csv'}
                </option>
                <option key={'scsv'} value={'scsv'} title={'semicolon separated values'}>
                  {'scsv'}
                </option>
                <option key={'excel'} value={'excel'} title={'excel (xlsx)'}>
                  {'excel'}
                </option>
              </select>
            </span>
          </div>
        </header>
        <div>
          {failed && (
            <p className='note error'>
              Something went wrong loading your data - please re-try or{' '}
              <Link to='/support'>contact support</Link>.
            </p>
          )}
          {!loading && !failed && ccys.length == 0 && (
            <p className='note'>There is no data for the selected merchant and timeframe.</p>
          )}
          {!loading && !failed && (
            <p className='note'>
              The data on this page is based on <strong>separate transactions</strong> for each
              Attempt, Acceptance, Approval, Refund and/or Chargeback which occurred during the
              selected time period. We perform merchant settlement based on this data. To monitor
              and control transactions using a simple view, see the{' '}
              <Link
                to={`/transactions?from=${filter.from || ''}&to=${filter.to || ''}&merchant_any=${
                  params.id
                }`}
              >
                transactions dashboard
              </Link>
              .
            </p>
          )}
        </div>
        {!loading &&
          !failed &&
          ccys &&
          ccys.map((ccy) => (
            <CurrencyRollup key={ccy} ccy={ccy} volCountTree={stats.by_ccy[ccy]} />
          ))}
        {showJSON && (
          <JSONInput
            viewOnly={true}
            placeholder={stats || {}}
            locale={locale}
            theme='light_mitsuketa_tribute'
            colors={{ background: '#f3f3f3' }}
            width='100%'
          />
        )}
        {!loading && !failed && isUserOperator && (
          <footer className='actions'>
            {!showJSON && <button onClick={() => setShowJSON(true)}>Show as JSON</button>}
            {showJSON && <button onClick={() => setShowJSON(false)}>Hide JSON</button>}
            {isUserOperator && (
              <a href={settlementReportURL}>
                {' '}
                <i className='fas fa-file-invoice-dollar' /> Download Billing Statement
              </a>
            )}
            {isUserOperator &&
              filter.by_psp == 'true' &&
              pspSelections &&
              pspSelections?.size != 0 && (
                <span className='psps'>
                  <select value={psp} onChange={(e) => setPSP(e.target.value)}>
                    <option key={'All'} value={'All'}>
                      All
                    </option>
                    {Array.from(pspSelections)
                      .sort()
                      .map((p) => (
                        <option key={p} value={p}>
                          {p}
                        </option>
                      ))}
                  </select>
                </span>
              )}
          </footer>
        )}
      </div>
    </section>
  )
}

const CurrencyRollup = ({ ccy, volCountTree }) => {
  return (
    <section className='ccy rollup'>
      <VolCountTree name={ccy} displayName={`${ccy} attempts`} {...volCountTree} />
    </section>
  )
}
CurrencyRollup.propTypes = {
  ccy: PropTypes.string.isRequired,
  volCountTree: PropTypes.object.isRequired,
}

const VolCountTree = ({ displayName, name, unit, count, volume, count_fraction, c }) => {
  const compKeys = c ? Object.keys(c).sort(cTxnSortOrder) : []
  return (
    <div className={classnames({ node: true, [name]: true })}>
      <div className='item' title={descriptions[name]}>
        <div className='label'>
          <Label name={name} displayName={displayName} />
        </div>
        <div className='datum count'>
          {count_fraction != null && (
            <span className='fraction' title='Percentage by count'>
              {count_fraction.toLocaleString(undefined, {
                minimumFractionDigits: 3,
                style: 'percent',
              })}
            </span>
          )}
          <span className='val'>{count != null && count.toLocaleString()}</span>
        </div>
        <div className='datum volume'>
          <span className='val'>
            {' '}
            <AmountWithCurrency amount={volume} currency={unit} />
          </span>
        </div>
      </div>
      <div className='components'>
        {compKeys.map((name) => (
          <VolCountTree key={name} name={name} {...c[name]} />
        ))}
      </div>
    </div>
  )
}
VolCountTree.propTypes = {
  // Optionally overrides the displayed label. Uses `name` if not supplied.
  displayName: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.string,
  count: PropTypes.number,
  volume: PropTypes.number,
  count_fraction: PropTypes.number,
  // Nested components.
  c: PropTypes.object,
}

const statuses = ['ok', 'failed', 'refunded', 'charged_back']

const Label = ({ name, displayName }) => {
  // Classes - by default, add name as class
  var cls = { [('' || name).toLowerCase()]: true }
  // Heading of sorts
  if (displayName && displayName.indexOf('attempts') != -1) {
    cls.head = true
  }
  // Known transaction status
  if (statuses.indexOf(name) > -1) {
    cls.status = true
  }
  // TODO: Make click-able to show transactions in this status (if possible - tricky!)
  return <span className={classnames(cls)}>{displayName || name}</span>
}
Label.propTypes = {
  // Optionally overrides the displayed label. Uses `name` if not supplied.
  displayName: PropTypes.string,
  name: PropTypes.string,
}

export default MerchantTxnStats

// Custom stats key sorting: Certain values should appear before others, i.e.
// with a "known" ordering (such as transaction status breakdown), whilst
// supporting a fallback to alphabetical ordering.
const sortWeight = {
  Approved: 1,
  Declined: 2,
  Refunded: 3,
  'Auto-refunded': 4,
  Chargeback: 5,
  Accepted: 10,
  Rejected: 11,
  Incomplete: 12,
}
const cTxnSortOrder = (a, b) => {
  const sa = sortWeight[a]
  const sb = sortWeight[b]
  if (sa && sb) {
    return sa < sb ? -1 : sa > sb ? 1 : 0
  }
  return a < b ? -1 : a > b ? 1 : 0
}

// Human-friendly help text for the different key
var descriptions = {
  Accepted:
    'Transaction details accepted and passed risk scoring, ready for sending to upstream acquirer',
  Approved: 'Upstream acquirer successfully processed transaction',
  Declined: 'Upstream acquirer declined transaction, payment not processed',
  Refunded: 'Previously-successful transaction was refunded by merchant or cardholder request',
  'Auto-refunded':
    'Transaction was approved by upstream acquirer, but customer did not complete crypto purchase + send to merchant',
  Rejected: 'Transaction details not accepted, or failed risk scoring',
  Incomplete: 'Customer abandoned transaction verification, or connection error / redirect failure',
}
// Including error codes
majorErrorCodes.forEach((desc, code) => {
  descriptions[`Code ${('' + code).padStart(2, '0')}`] = desc
})
