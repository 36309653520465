import React from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Route, Redirect, Switch, NavLink, useRouteMatch } from 'react-router-dom'
import AuthenticatedRoute from '../AuthenticatedRoute'
import {
  useOrgScope,
  TYPE_ROOT,
  OrgScopeRequired,
  TYPE_CONTRACT,
  TYPE_MERCHANT,
} from '../org-scope'
import { useAuth } from '../auth'
import { values, length, filter } from 'ramda'
import { isOperator, hasContextContractID } from '../auth-roles'

// Settings sections
import Forex from './forex/Forex'
import AllowDeny from './allowdeny/AllowDeny'
import BulkUpload from './allowdeny/BulkUpload'
import TestAllowDeny from './allowdeny/TestAllowDeny'

/** High-level container for the 'settings' section, which presents a separate side
 * menu, with nested sections, available to different roles.
 */
const Settings = () => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)

  const { path, url } = useRouteMatch()
  const allowDenyMatch = useRouteMatch(`${url}/allowdeny/:scope`)
  // Figure out what is the top-most org that current user can access, in order
  // to build e.g. direct links to sections. Otherwise, we have to rely on each section
  // doing some sort of redirect, which is messy.
  const { tree } = useOrgScope()
  const rootOrgID = getRootOrgID(tree)
  return (
    <section className='settings'>
      <Helmet>
        <title>Settings - Canapay</title>
      </Helmet>
      <Header />
      <OrgScopeRequired>
        <div className='layout'>
          <nav>
            <NavLink to={`${url}/allowdeny/${rootOrgID}`} isActive={() => allowDenyMatch != null}>
              <i className='fas fa-ban' />
              Allow / Deny
            </NavLink>
            {isUserOperator && (
              <NavLink to={`${url}/forex`}>
                <i className='fas fa-shekel-sign' />
                Forex rates
              </NavLink>
            )}
          </nav>
          <div className='content'>
            <Switch>
              <Route exact path={`${path}`}>
                {/* TODO: What if you aren't allowed to see allow/deny, where to direct to? */}
                <Redirect to={`${path}/allowdeny/${rootOrgID}`} />
              </Route>
              <AuthenticatedRoute path={`${path}/allowdeny/:scope/import`} component={BulkUpload} />
              <AuthenticatedRoute
                path={`${path}/allowdeny/:scope/test`}
                component={TestAllowDeny}
              />
              <AuthenticatedRoute path={`${path}/allowdeny/:scope`} component={AllowDeny} />
              <AuthenticatedRoute path={`${path}/forex`} component={Forex} />
            </Switch>
          </div>
        </div>
      </OrgScopeRequired>
    </section>
  )
}

const getRootOrgID = (tree = {}) => {
  if (tree == null) {
    return TYPE_ROOT
  }

  const vals = filter((e) => e != null)(values(tree))
  if (length(vals) === 1) {
    return vals[0]?.id || TYPE_ROOT
  } else if (length(vals) > 1) {
    // todo just using the first contract/ or merchant here if we ever need to support more this won't work
    const firstContract = vals.find((t) => t != null && t.type === TYPE_CONTRACT)
    const firstMerchant = vals.find((t) => t != null && t.type === TYPE_MERCHANT)
    return firstContract?.id || firstMerchant?.id || TYPE_ROOT
  }
  return TYPE_ROOT
}

export default Settings
