import React, { useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { createContract } from '../api'
import { useAuth } from '../auth'
import DateTimePicker from 'react-datetime-picker'

const CreateContract = () => {
  const { token } = useAuth()
  const history = useHistory()

  const [saving, setSaving] = useState(false)
  const [failed, setFailed] = useState()
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(new Date())

  const createNewContract = async () => {
    setFailed(false)
    setSaving(true)
    try {
      const contract = {
        description: description,
        start_at: startDate.toISOString(),
      }
      const response = await createContract(token, contract)
      history.push(`/contract/${response.id}`)
    } catch (e) {
      setFailed(e)
    }
    setSaving(false)
  }

  return (
    <section className='create-contract'>
      <Header />
      <Helmet>
        <title>Create a contract - Canapay</title>
      </Helmet>
      <div className='content'>
        <div className='contract'>
          <h3>Create a new contract</h3>
          <table>
            <tbody>
              <tr className='description'>
                <td className='key'>Description</td>
                <td className='val'>
                  <input
                    type='text'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoFocus={true}
                  />
                </td>
              </tr>
              <tr className='start-date'>
                <td className='key'>Start Date</td>
                <td className='val'>
                  <DateTimePicker
                    format='y-MM-dd HH:mm:ss'
                    clearIcon={null}
                    calendarIcon={null}
                    value={startDate}
                    maxDetail='second'
                    onChange={setStartDate}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {saving && <p className='saving'>Saving...</p>}
          {failed && (
            <p className='error'>
              {failed && failed.message ? (
                <span>Failed with message: {failed.message}</span>
              ) : (
                <span>
                  Something went wrong - please try again, or{' '}
                  <Link to='/support'>contact support</Link>
                </span>
              )}
            </p>
          )}

          <footer className='actions'>
            <button onClick={createNewContract} disabled={!description || !startDate}>
              <i className='fas fa-plus-circle' /> Create contract
            </button>
          </footer>
        </div>
      </div>
    </section>
  )
}

export default CreateContract
