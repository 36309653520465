import React, { useContext, useEffect, useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { useParams, Link } from 'react-router-dom'
import { getUser, enableUser, disableUser } from '../api'
import { useAuth } from '../auth'
import { isOperator } from '../auth-roles'
import PropTypes from 'prop-types'
import UserDateTime from './UserDateTime'
import AccessibleMerchantsContext from '../AccessibleMerchantsContext'
import AccessibleContractsContext from '../AccessibleContractsContext'
import { find, propEq, propOr } from 'ramda'
import ActiveStatus from '../ActiveStatus'
import UserRolesAssignment from './UserRolesAssignment'

/** ViewUser renders a single user. */
const ViewUser = () => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)

  const params = useParams()
  // User data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [userInfo, setUserInfo] = useState()
  const fetchUser = async (id) => {
    if (!id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getUser(token, id)
      setUserInfo(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchUser(params.id)
  }, [params.id])

  return (
    <section className='user'>
      <Header />
      <Helmet>
        <title>User {userInfo && userInfo.user ? userInfo.user.username : ''} - Canapay</title>
      </Helmet>
      <div className='content'>
        {loading && !userInfo && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && userInfo && (
          <div className='user'>
            <UserCore
              user={userInfo.user}
              stats={userInfo.stats}
              token={token}
              isUserOperator={isUserOperator}
              fetchUser={fetchUser}
            />
            {isUserOperator && <UserRolesAssignment userID={params.id} />}
          </div>
        )}
      </div>
    </section>
  )
}

/** Core / basic user details */
const UserCore = ({ user, stats, token, isUserOperator, fetchUser }) => {
  const onEnable = async () => {
    await enableUser(token, user.id)
    fetchUser()
  }

  const onDisable = async () => {
    await disableUser(token, user.id)
    fetchUser()
  }

  return (
    <div className='user-core'>
      <h1>
        <i className='fas fa-user-alt' /> {user.username || '-'}
      </h1>
      <table className='user-info'>
        <tbody>
          <tr className='user_id'>
            <td className='key'>User ID</td>
            <td className='val'>
              <span className='id'>{user.id}</span>
            </td>
          </tr>
          <tr className='user_id'>
            <td className='key'>Email</td>
            <td className='val'>{user.username}</td>
          </tr>
          <tr className='status'>
            <td className='key'>Status</td>
            <td className='val'>
              <ActiveStatus {...user} />
              {isUserOperator && user.enabled && (
                <button onClick={onDisable}>
                  <i className='fas fa-ban' /> Suspend user account
                </button>
              )}
              {isUserOperator && !user.enabled && (
                <button onClick={onEnable}>
                  <i className='fas fa-thumbs-up' />
                  Activate user account
                </button>
              )}
            </td>
          </tr>
          {user.created_at && (
            <tr className='created_at'>
              <td className='key'>Created at</td>
              <td className='val'>
                <UserDateTime at={user.created_at} />
              </td>
            </tr>
          )}
          {stats && stats.last_login_at && (
            <tr className='last_login'>
              <td className='key'>Last login at</td>
              <td className='val'>
                <UserDateTime at={stats.last_login_at} />
              </td>
            </tr>
          )}
          {stats && stats.successful_logins && (
            <tr className='successful_logins'>
              <td className='key'>No. success logins</td>
              <td className='val'>{stats.successful_logins}</td>
            </tr>
          )}
          {stats && stats.failed_logins && (
            <tr className='failed_logins'>
              <td className='key'>No. failed logins</td>
              <td className='val'>{stats.failed_logins}</td>
            </tr>
          )}
        </tbody>
      </table>
      <footer className='actions'></footer>
    </div>
  )
}

UserCore.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    entity_id: PropTypes.string,
    created_at: PropTypes.string,
    enabled: PropTypes.bool,
    username: PropTypes.string,
  }),
  stats: PropTypes.shape({
    last_login_at: PropTypes.string,
    successful_logins: PropTypes.number,
    failed_logins: PropTypes.number,
  }),
  token: PropTypes.string,
  isUserOperator: PropTypes.bool,
  fetchUser: PropTypes.func,
}

// Legend for the obfuscated context role object:
// Role        `json:"r"`
// ContextType `json:"t"`
// ContextID   `json:"id"`
const UserRoles = ({ roles }) => (
  <div className='user-roles'>
    <h3>User roles</h3>
    {roles && roles.length !== 0 ? (
      <table>
        <thead>
          <tr>
            <th className='role'>Role</th>
            <th className='role'>Context type</th>
            <th className='role'>Context id</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role, idx) => {
            return (
              <tr key={idx} className='role'>
                <td className='role'>{role.r}</td>
                <td className='context-type'>{role.t}</td>
                <td className='context-id'>
                  <ContextRole role={role} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    ) : (
      <p>This user has no roles.</p>
    )}
    <footer className='actions' />
  </div>
)

UserRoles.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      r: PropTypes.string,
      t: PropTypes.string,
      id: PropTypes.string,
    })
  ),
}

// render a friendly representation with id and name of context role.
const ContextRole = ({ role }) => {
  const roleName = mapToContextRoleName(role.t, role.id)
  return <span>{`${role.id} (${roleName})`}</span>
}

ContextRole.propTypes = {
  role: PropTypes.shape({
    r: PropTypes.string,
    t: PropTypes.string,
    id: PropTypes.string,
  }),
}

const mapToContextRoleName = (contextType, contextID) => {
  const accessibleMerchants = useContext(AccessibleMerchantsContext)
  const accessibleContracts = useContext(AccessibleContractsContext)
  return contextType === 'MerchantID'
    ? propOr('-', 'registered_name', find(propEq('id', contextID), accessibleMerchants || []))
    : contextType === 'ContractID'
    ? propOr('-', 'description', find(propEq('id', contextID), accessibleContracts || []))
    : '-'
}

export default ViewUser
