import React from 'react'
import AuthenticatedRoute from './AuthenticatedRoute'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { OrgScopeProvider } from './org-scope'
import { AuthProvider } from './auth'

// Our components
// Top-level user account stuff
import SignIn from './SignIn'
import SignOut from './SignOut'
import RequestAccount from './RequestAccount'
import PasswordResetInitiate from './PasswordResetInitiate'
import PasswordResetComplete from './PasswordResetComplete'
import NotFound from './NotFound'
import Support from './Support'

// Transactions
import ListTxns from './txns/ListTxns'
import ViewTxn from './txns/ViewTxn'
import EditTxnStatus from './txns/EditTxnStatus'
import RefundTxn from './txns/RefundTxn'

// Merchants
import ListMerchants from './merchants/ListMerchants'
import ViewMerchant from './merchants/ViewMerchant'
import MerchantTxnStats from './merchants/MerchantTxnStats'

// Settings and related
import Settings from './settings/Settings'
import User from './user/User'

// Users
import ListUsers from './users/ListUsers'
import ViewUser from './users/ViewUser'
import CreateMerchant from './merchants/CreateMerchant'

// Blockchain explorer
import ViewBlock from './explorer/Canachain'

// KYC
import ViewKYC from './kyc/View'
import ListContracts from './contracts/ListContracts'
import ViewContract from './contracts/ViewContract'
import CreateContract from './contracts/CreateContract'

/** App is the top-most component of the application. */
const App = () => (
  <AuthProvider tokenKey='canapay:token'>
    <OrgScopeProvider>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/transactions?from=m24h&to=now' />
          </Route>
          <Route exact path='/sign-in' component={SignIn} />
          <Route exact path='/sign-out' component={SignOut} />
          <Route exact path='/request-account' component={RequestAccount} />
          <Route exact path='/password-reset' component={PasswordResetInitiate} />
          <Route exact path='/password-reset-complete' component={PasswordResetComplete} />
          <Route exact path='/support' component={Support} />
          <AuthenticatedRoute exact path='/account' component={User} />
          <AuthenticatedRoute exact path='/transactions' component={ListTxns} />
          <AuthenticatedRoute exact path='/transaction/:id' component={ViewTxn} />
          <AuthenticatedRoute exact path='/transaction/:id/status' component={EditTxnStatus} />
          <AuthenticatedRoute exact path='/transaction/:id/refund' component={RefundTxn} />
          <AuthenticatedRoute exact path='/users' component={ListUsers} />
          <AuthenticatedRoute exact path='/user/:id' component={ViewUser} />
          <AuthenticatedRoute exact path='/merchants' component={ListMerchants} />
          <AuthenticatedRoute exact path='/merchant/:id' component={ViewMerchant} />
          <AuthenticatedRoute exact path='/merchant/:id/txn-stats' component={MerchantTxnStats} />
          <AuthenticatedRoute exact path='/create/merchant' component={CreateMerchant} />
          <AuthenticatedRoute exact path='/contracts' component={ListContracts} />
          <AuthenticatedRoute exact path='/contract/:id' component={ViewContract} />
          <AuthenticatedRoute exact path='/create/contract' component={CreateContract} />
          <AuthenticatedRoute path='/settings' component={Settings} />
          <AuthenticatedRoute exact path='/explorer/canachain' component={ViewBlock} />
          <AuthenticatedRoute exact path='/customers/by-txn/:id' component={ViewKYC} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Router>
    </OrgScopeProvider>
  </AuthProvider>
)

export default App
