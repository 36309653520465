import React from 'react'
import PropTypes from 'prop-types'

const truncateTo = (len, str) => {
  if (!str || str.length <= len) {
    return str
  }
  if (str.length > len) {
    return (
      str.substring(0, len / 2 - 1) +
      '...' +
      str.substring(str.length - len / 2 + 1, str.length - 1)
    )
  }
}

/** Renders a transaction ID.
 * TODO: Add icon to "copy to clipboard" when clicked
 */
const TxnID = ({ transaction_id, order_ref }) => (
  <div className='id-container'>
    <div className='txn_id' title='Internal Transaction ID'>
      <span className='id'>{transaction_id || '-'}</span>
    </div>
    {order_ref && (
      <div className='order_ref' title='Merchant-provided order reference'>
        <label>Order ref:</label>{' '}
        <span className='id' title={`Merchant-provided order ref: ${order_ref}`}>
          {truncateTo(20, order_ref)}
        </span>
      </div>
    )}
  </div>
)

TxnID.propTypes = {
  transaction_id: PropTypes.string,
  order_ref: PropTypes.string,
}

export default TxnID
