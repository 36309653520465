import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { getContracts, getMerchants, registerMerchant } from '../api'
import { useAuth } from '../auth'
import { split, toUpper, trim, compose, map, length } from 'ramda'
import { useOrgScope } from '../org-scope'

/** CreateMerchant probably renders a create merchant component */
const CreateMerchant = () => {
  const { token } = useAuth()
  const { refresh } = useOrgScope()

  const history = useHistory()

  const [saving, setSaving] = useState(false)
  const [failed, setFailed] = useState()
  const [registeredName, setRegisteredName] = useState('')
  const [tradingAs, setTradingAs] = useState('')
  const [contacts, setContacts] = useState('')
  const [website, setWebsite] = useState('')
  const [link, setLinkType] = useState('')
  const [hostName, setHostName] = useState(window.location.hostname)
  const [linkContract, setLinkContract] = useState('')
  const [linkMerchant, setLinkMerchant] = useState('')
  const [territories, setTerritories] = useState('')

  // All accessible merchants
  const [merchants, setMerchants] = useState([])
  const [loadingMerchants, setLoadingMerchants] = useState(false)
  const [failedMerchants, setFailedMerchants] = useState()
  // All accessible contracts
  const [contracts, setContracts] = useState([])
  const [loadingContracts, setLoadingContracts] = useState(false)
  const [failedContracts, setFailedContracts] = useState()

  // Fetch accessible merchants from the back-end
  const fetchMerchants = async () => {
    setLoadingMerchants(true)
    setFailedMerchants(false)
    try {
      const resp = await getMerchants(token, {})
      if (resp) {
        setMerchants(resp.results)
      }
      setLoadingMerchants(false)
    } catch (failed) {
      setFailedMerchants(failed)
      setLoadingMerchants(false)
    }
  }
  useEffect(() => {
    fetchMerchants()
  }, [])

  // Fetch contracts from the back-end
  const fetchContracts = async () => {
    setLoadingContracts(true)
    setFailedContracts(false)
    try {
      const resp = await getContracts(token, {})
      if (resp) {
        setContracts(resp.results)
      }
      setLoadingContracts(false)
    } catch (failed) {
      setLoadingContracts(false)
      setFailedContracts(failed)
    }
  }
  useEffect(() => {
    fetchContracts()
  }, [])

  // check parent link is valid, not set or set to contract/merchant and appropriate link is set
  const isLinkToParentValid =
    link === '' ||
    (link === 'contract' && linkContract !== '' && linkMerchant === '') ||
    (link === 'merchant' && linkMerchant !== '' && linkContract === '')

  const registerNewMerchant = async () => {
    setFailed(false)
    setSaving(true)
    try {
      // map to back-end style with contacts in the strange mailto format
      const emails = split(',', contacts)
      const hosts = split(',', hostName)
      const contactURIs = emails.map((e) => `mailto:${trim(e)}`)
      let allowedTerritories = compose(map(compose(trim, toUpper)), split(','))(territories)
      if (length(allowedTerritories) === 1 && allowedTerritories[0] === '') {
        allowedTerritories = []
      }

      const merchant = {
        registered_name: registeredName,
        trading_as: tradingAs,
        contact_uri: contactURIs,
        uri: website,
        host_names: hosts,
        parent_org_node_type: link,
        parent_org_node_id: linkContract || linkMerchant,
        allowed_territories: allowedTerritories,
      }
      const response = await registerMerchant(token, merchant)
      if (refresh) {
        await refresh()
      }
      history.push(`/merchant/${response.merchant.id}`)
    } catch (e) {
      setFailed(e)
    }
    setSaving(false)
  }

  return (
    <section className='create-merchant'>
      <Header />
      <Helmet>
        <title>Register a merchant - Canapay</title>
      </Helmet>
      <div className='content'>
        <div className='merchant'>
          <h3>Register a new merchant</h3>
          <table>
            <tbody>
              <tr className='merchant_name'>
                <td className='key'>Registered Name</td>
                <td className='val'>
                  <input
                    type='text'
                    value={registeredName}
                    onChange={(e) => setRegisteredName(e.target.value)}
                    autoFocus={true}
                  />
                </td>
              </tr>
              <tr className='trading_as'>
                <td className='key'>Trading As</td>
                <td className='val'>
                  <input
                    type='text'
                    value={tradingAs}
                    onChange={(e) => setTradingAs(e.target.value)}
                  />
                </td>
              </tr>
              <tr className='contact_uri'>
                <td className='key'>Contact Emails</td>
                <td className='val'>
                  <input
                    type='text'
                    value={contacts}
                    onChange={(e) => setContacts(e.target.value)}
                  />
                </td>
              </tr>
              <tr className='uri'>
                <td className='key'>Website</td>
                <td className='val'>
                  <input type='text' value={website} onChange={(e) => setWebsite(e.target.value)} />
                </td>
              </tr>
              <tr className='uri'>
                <td className='key'>Host Name</td>
                <td className='val'>
                  <input
                    type='text'
                    value={hostName}
                    onChange={(e) => setHostName(e.target.value)}
                  />
                </td>
              </tr>
              <tr className='uri'>
                <td className='key'>Allowed Territories</td>
                <td className='val'>
                  <input
                    type='text'
                    value={territories}
                    onChange={(e) => setTerritories(e.target.value)}
                  />
                </td>
              </tr>

              <tr className='uri'>
                <td className='key'>Link to parent</td>
                <td className='val'>
                  <select
                    value={link}
                    onChange={(e) => {
                      const value = e.target.value
                      setLinkType(value)
                      // clear any selected contracts/merchants that may have been set previously
                      if (value === 'contract') {
                        if (contracts && contracts.length !== 0) {
                          setLinkContract(contracts[0].id)
                        } else {
                          setLinkContract('')
                        }
                        setLinkMerchant('')
                      } else if (value === 'merchant') {
                        if (merchants && merchants.length !== 0) {
                          setLinkMerchant(merchants[0].id)
                        } else {
                          setLinkMerchant('')
                        }
                        setLinkContract('')
                      } else {
                        setLinkContract('')
                        setLinkMerchant('')
                      }
                    }}
                  >
                    <option value={''}>None</option>
                    <option value='contract'>Contract</option>
                    <option value='merchant'>Merchant</option>
                  </select>

                  {link && link === 'contract' && (
                    <select value={linkContract} onChange={(e) => setLinkContract(e.target.value)}>
                      {contracts &&
                        contracts.map((contract, idx) => (
                          <option key={idx} value={contract.id}>
                            {contract.description}
                          </option>
                        ))}
                    </select>
                  )}
                  {link && link === 'merchant' && (
                    <select value={linkMerchant} onChange={(e) => setLinkMerchant(e.target.value)}>
                      {merchants.map((merchant, idx) => (
                        <option key={idx} value={merchant.id}>
                          {merchant.registered_name}
                        </option>
                      ))}
                    </select>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {saving && <p className='saving'>Saving...</p>}
          {(loadingMerchants || loadingContracts) && <p className='loading'>Loading data...</p>}
          {(failed || failedMerchants || failedContracts) && (
            <p className='error'>
              {failed && failed.message ? (
                <span>Failed with message: {failed.message}</span>
              ) : (
                <span>
                  Something went wrong - please try again, or{' '}
                  <Link to='/support'>contact support</Link>
                </span>
              )}
            </p>
          )}

          <footer className='actions'>
            <button
              onClick={registerNewMerchant}
              disabled={!registeredName || !tradingAs || !contacts || !isLinkToParentValid}
            >
              <i className='fas fa-plus-circle' /> Create merchant
            </button>
          </footer>
        </div>
      </div>
    </section>
  )
}

export default CreateMerchant
