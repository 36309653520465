import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { renderStatusName } from '../TxnStatus.jsx'
import { majorErrorCodes, minorErrorCodes } from '../error-codes'

// All non-failed statuses - failed are handled in their own section with status codes etc
const statuses = ['ok', 'cust_verification_required', 'pending', 'refunded', 'charged_back']

const statusNames = {
  cust_verification_required: 'verification pending (3DS, KYC)',
  refunded: 'refunded (By request)',
}

const StatusFilter = ({ filter, setFilter }) => {
  // Quick simple version to filter based on one status
  const status = path(['status_any', 0], filter)
  const errorCode = filter?.error_code_major
  const errorCodeMinor = filter?.error_code_minor
  // Composite 'value' for select, since we're rolling two values (status, and potentially error code)
  // into this one control.
  const selectVal = status != null ? (errorCode != null ? `${status}:${errorCode}` : status) : null
  const changed = (e) => {
    const newStatus = e.target.value
    // Value could be single or composite, e.g. 'ok' or 'failed:6'
    const [status, error_code_major] = newStatus.split(':')
    setFilter({
      ...filter,
      status_any: status ? [status] : [],
      error_code_major,
      error_code_minor: errorCodeMinor && status !== 'failed' ? '' : errorCodeMinor,
    })
  }
  const minorCodeChanged = (e) => {
    const value = e.target.value
    setFilter({ ...filter, error_code_minor: value })
  }
  const errorCodes = Array.from(majorErrorCodes.keys()).sort((a, b) => a - b) // Numeric sort
  const errorCodesMinor = Array.from(minorErrorCodes.keys()).sort((a, b) => a - b) // Numeric sort
  return (
    <div className='filter status'>
      <select value={selectVal || ''} onChange={changed}>
        <option value=''>Any status</option>
        {statuses.map((s) => (
          <option key={s} value={s} className={s}>
            {statusNames[s] || renderStatusName(s)}
          </option>
        ))}
        <option value='failed' className='failed'>
          failed (any reason)
        </option>
        {errorCodes.map((code) => (
          <option key={code} value={`failed:${code}`} className='failed'>{`failed (${code} - ${
            majorErrorCodes.get(code) || '(no description)'
          })`}</option>
        ))}
      </select>
      {status === 'failed' && (
        <select value={errorCodeMinor || ''} onChange={minorCodeChanged}>
          <option value=''>any minor code</option>
          {errorCodesMinor.map((code) => (
            <option key={code} value={code} className='failed'>{`${code} - ${
              minorErrorCodes.get(code) || '(no description)'
            }`}</option>
          ))}
        </select>
      )}
    </div>
  )
}

StatusFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default StatusFilter
