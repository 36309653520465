import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { pathOr, toPairs, sortBy, path, reverse, compose, omit, reduce } from 'ramda'
import classnames from 'classnames'
import { statusNames } from './TxnStatus'
import { useAuth } from '../auth'
import { isController, isControllerNoMerchant, isOperator } from '../auth-roles'
import Country from '../Country'
import AmountWithCurrency from '../AmountWithCurrency'
import { Link } from 'react-router-dom'
import {
  acceptancePercent,
  statsSortedByCount,
  statsSortedByValueSettled,
  statsSortedByAcceptance,
} from './stats'
import { useOrgScope, TYPE_MERCHANT } from '../org-scope'

const TxnsDashboardHeader = ({ stats = {}, merchantByID = {}, filter = {} }) => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles) || isControllerNoMerchant(roles)
  return (
    <header className='dashboard'>
      {/* Modules - note, they are adaptive, each may elect to produce
        no output if not relevant to the given stats. */}
      <SuccessRateSettledValue {...stats} />
      <TxnAmounts {...stats} />
      {/*<CardHolderEmail filter={filter} setFilter={setFilter} />*/}
      {(isUserOperator || isUserController) && <CountByStatus {...stats} />}
      <TopCountries {...stats} />
      <TopMerchants merchantByID={merchantByID} {...stats} />
      <MerchantStatsLink filter={filter} />
    </header>
  )
}

TxnsDashboardHeader.propTypes = {
  // Status / summary object from which primary display is derived
  stats: PropTypes.object,
  // Full transactions list (probably not needed though!)
  txns: PropTypes.array,
  // Used to support any nested filtering operations, e.g.
  // click something to "isolate" the data for that thing.
  filter: PropTypes.object.isRequired,
  // Used to support any nested filtering operations, e.g.
  // click something to "isolate" the data for that thing.
  setFilter: PropTypes.func.isRequired,
  // map of visible merchants: {id : { id, registered_name }} - TODO: Replace with context
  merchantByID: PropTypes.object,
}

/** SuccessRateSettledValue displays a dashboard module that simply shows acceptance rate, or
 * nothing if insufficient data is present. */
const SuccessRateSettledValue = ({ cnt_by_status = {}, net_vol_by_settle_ccy = {} }) => {
  const rate = acceptancePercent(cnt_by_status)

  const showSettleTotalEstEUR = net_vol_by_settle_ccy && net_vol_by_settle_ccy.SUM_EST_EUR != null
  const showSettleTotalEstUSD = net_vol_by_settle_ccy && net_vol_by_settle_ccy.SUM_EST_USD != null
  const anySettledValue = showSettleTotalEstEUR || showSettleTotalEstUSD // TODO: Or single-value only
  const isEstimate = showSettleTotalEstEUR || showSettleTotalEstUSD

  return (
    <div className='module acceptance-rate'>
      {rate && (
        <>
          <label>Success rate</label>
          <div className='rate' title='Fraction of attempted transactions that are approved'>
            {rate.toLocaleString(undefined, { style: 'percent' })}
          </div>
        </>
      )}
      {anySettledValue && (
        <label title='Funds that were successfully processed by upstream'>
          {isEstimate && 'Est. '}Settled value
        </label>
      )}
      {showSettleTotalEstEUR && (
        <>
          <div className='settled-amount est-total'>
            ~ <AmountWithCurrency amount={net_vol_by_settle_ccy.SUM_EST_EUR} currency='EUR' />
          </div>
        </>
      )}
      {showSettleTotalEstUSD && (
        <>
          <div className='settled-amount est-total'>
            ~ <AmountWithCurrency amount={net_vol_by_settle_ccy.SUM_EST_USD} currency='USD' />
          </div>
        </>
      )}
      {/* TODO: Fallback: If there is on a single currency, and no estimate, show that single
          currency instead (as actual, not estimate) */}
    </div>
  )
}
SuccessRateSettledValue.propTypes = {
  cnt_by_status: PropTypes.object,
  net_vol_by_settle_ccy: PropTypes.object,
}

/** toSortedCounts produces a desceding, sorted list of [status, count] pairs. */
const toSortedCounts = compose(reverse, sortBy(path([1])), toPairs)

/** CountByStatus is a filter module that shows counts and percentage by status. */
const CountByStatus = ({ cnt_by_status = {} }) => {
  if (!cnt_by_status) {
    return null
  }
  // Sort from most to least
  const counts = toSortedCounts(cnt_by_status.value)
  // Calc percentage of each
  const countWithPer = counts.map(([status, count]) => [
    status,
    count,
    (1 / cnt_by_status.total) * count,
  ])

  // Don't render if not at least two statuses
  if (counts.length < 2) {
    return null
  }

  return (
    <div className='module count-by-status'>
      <label>Transactions by status:</label>
      {countWithPer.map(([status, count, percent]) => {
        // Show decimals for small percentages only
        const percentFormat =
          percent < 0.1
            ? {
                style: 'percent',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }
            : {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }

        return (
          <div key={status} className={classnames({ count: true, [status]: true })}>
            <span className='status'>{statusNames[status] || status}</span>
            <span className='percent'>{percent.toLocaleString(undefined, percentFormat)}</span>
            <span className='val'>{count != null ? count.toLocaleString(undefined) : '-'}</span>
          </div>
        )
      })}
    </div>
  )
}
CountByStatus.propTypes = {
  cnt_by_status: PropTypes.object,
}

/*
const toSortedCounts = compose(reverse, sortBy(path([1])), toPairs)
*/

const toSortedActuals = (byCCY = {}) =>
  sortBy(
    path([0]),
    toPairs(byCCY).filter(([ccy]) => !ccy.startsWith('SUM_EST_'))
  )

const TxnAmounts = ({ net_vol_by_settle_ccy = {}, net_vol_by_req_ccy = {} }) => {
  const settVolByCcy = toSortedActuals(net_vol_by_settle_ccy)
  const hasSett = settVolByCcy && settVolByCcy.length > 0
  const reqVolByCcy = toSortedActuals(net_vol_by_req_ccy)
  const hasReq = reqVolByCcy && reqVolByCcy.length > 0

  if (!hasSett && !hasReq) {
    return null // Nothing to display
  }

  // User-selectable type (settled, or requested)
  //const [type, setType] = useState('type', hasSett ? 'settled' : hasReq ? 'requested' : null)
  const [type, setType] = useState('settled')
  const volByCcy = type == 'settled' ? settVolByCcy : type == 'requested' ? reqVolByCcy : null

  // Every time the nature of which types of amounts we have changes,
  // auto-select an appropriate one. Designed to support a good experience
  // when people switch between e.g. successful or failed transaction types.
  useEffect(() => {
    if (settVolByCcy && (reqVolByCcy == null || settVolByCcy.length > reqVolByCcy.length)) {
      setType('settled')
    }
    if (reqVolByCcy && (settVolByCcy == null || reqVolByCcy.length > settVolByCcy.length)) {
      setType('requested')
    }
  }, [hasSett, hasReq])

  // User controls
  const onChangeType = (e) => {
    setType(e.target.value)
  }
  const typeSelector = (
    <select value={type} onChange={onChangeType}>
      {hasSett && <option value='settled'>Settled</option>}
      {hasReq && <option value='requested'>Requested</option>}
    </select>
  )

  return (
    <div className='module amt-by-currency'>
      <label>
        Total value
        <span className='controls'>{typeSelector}</span>
      </label>
      {volByCcy &&
        volByCcy.map(([ccy, amt]) => (
          <div key={ccy} className={classnames({ count: true, currencyamt: true })}>
            <span className='ccy'>{ccy}</span>
            <span className='val'>
              {amt.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </span>
          </div>
        ))}
    </div>
  )
}
TxnAmounts.propTypes = {
  net_vol_by_settle_ccy: PropTypes.object,
  net_vol_by_req_ccy: PropTypes.object,
}

/** TopCountries displays a small dashboard widget that summarises the top contries
 * (volumes, amounts, and a per-status breakdown). It displays a "Top X" and allows
 * the user to expand "the rest" which is rendered e.g. as a scrollable modal dialog
 * with CSS defined elsewhere. */
const TopCountries = ({ stats_by_bill_cntry = {}, displayCount = 3 }) => {
  // TODO: User-selectable "which countries" - use stats_by_ip_cntry and stats_by_bin_cntry

  const stats = stats_by_bill_cntry

  // User-selectable 'rank by'
  const [rankBy, setRankBy] = useState('count')
  const onChangeRankBy = (e) => {
    setRankBy(e.target.value)
  }
  // Produce ranked list of top countries
  const topCountries =
    rankBy === 'count'
      ? statsSortedByCount(stats)
      : rankBy === 'value-settled-eur'
      ? statsSortedByValueSettled('EST_EUR')(stats)
      : rankBy === 'value-settled-usd'
      ? statsSortedByValueSettled('EST_USD')(stats)
      : rankBy === 'acceptance'
      ? statsSortedByAcceptance(stats)
      : []

  // Trim to 'top X' which is displayed, with optional "Y more" button
  const topCountriesShow = topCountries.slice(0, displayCount)
  const moreCount = topCountries.length - topCountriesShow.length
  // Is user busy 'viewing more'
  const [viewMore, setViewMore] = useState(false)
  const toggleViewMore = () => setViewMore(!viewMore)

  // Adaptive - only render if there are 2+ countries
  if (topCountries.length <= 1) {
    return null
  }

  const rankSelector = (
    <select value={rankBy} onChange={onChangeRankBy}>
      <option value='count'>Transaction count</option>
      <option value='value-settled-eur'>Settled value ~EUR</option>
      <option value='value-settled-usd'>Settled value ~USD</option>
      <option value='acceptance'>Acceptance rate</option>
    </select>
  )

  return (
    <div className='module count-by-country'>
      <label>
        Top countries
        <span className='controls'>{rankSelector}</span>
      </label>
      {topCountriesShow.map(([country, val, byStatus = {}]) => (
        <div key={country} className={classnames({ count: true, country: true })}>
          <Country iso={country} />
          <RankedValue val={val} rankBy={rankBy} />
          <InlineStatusSummary statusCounts={byStatus.value} />
        </div>
      ))}
      {viewMore && (
        /* TODO: Close with ESC key */ <div className='view-more'>
          <h1>
            Top countries
            <span className='controls'>
              {rankSelector}
              <i className='fas fa-times clickable close' title='Hide' onClick={toggleViewMore} />
            </span>
          </h1>
          {/* TODO: Controls to switch mode */}
          <div className='content'>
            {topCountries.map(([country, val, byStatus = {}], n) => (
              <div key={country} className={classnames({ count: true, country: true })}>
                <span className='rank'>{n + 1}.</span>
                <Country iso={country} />
                <RankedValue val={val} rankBy={rankBy} />
                <InlineStatusSummary statusCounts={byStatus.value} />
              </div>
            ))}
          </div>
        </div>
      )}
      {moreCount > 0 && (
        <div className='count country more-indicator clickable' onClick={toggleViewMore}>
          {viewMore ? 'Hide' : 'See all'} ({moreCount} more)
        </div>
      )}
    </div>
  )
}
TopCountries.propTypes = {
  //stats_by_ip_cntry: PropTypes.object,
  //stats_by_bin_cntry: PropTypes.object,
  stats_by_bill_cntry: PropTypes.object,
  // How many to display (rest is rolled into a "more" display...)
  displayCount: PropTypes.number,
}

/** TopMerchants displays a small dashboard widget that summarises the top merchants
 * (volumes, amounts, and a per-status breakdown). It displays a "Top X" and allows
 * the user to expand "the rest" which is rendered e.g. as a scrollable modal dialog
 * with CSS defined elsewhere. */
const TopMerchants = ({ stats_by_merch_id = {}, displayCount = 3 }) => {
  const { byID } = useOrgScope()
  const stats = stats_by_merch_id || {}

  // TODO: Use context of 'all merchants'

  // User-selectable 'rank by'
  const [rankBy, setRankBy] = useState('count')
  const onChangeRankBy = (e) => {
    setRankBy(e.target.value)
  }
  // Produce ranked list of top merchants
  const top =
    rankBy === 'count'
      ? statsSortedByCount(stats)
      : rankBy === 'value-settled-eur'
      ? statsSortedByValueSettled('EST_EUR')(stats)
      : rankBy === 'value-settled-usd'
      ? statsSortedByValueSettled('EST_USD')(stats)
      : rankBy === 'acceptance'
      ? statsSortedByAcceptance(stats)
      : []

  // Trim to 'top X' which is displayed, with optional "Y more" button
  const topShow = top.slice(0, displayCount)
  const moreCount = top.length - topShow.length
  // Is user busy 'viewing more'
  const [viewMore, setViewMore] = useState(false)
  const toggleViewMore = () => setViewMore(!viewMore)

  // Adaptive - only render if there are 2+ merchants
  if (top.length <= 1) {
    return null
  }

  const rankSelector = (
    <select value={rankBy} onChange={onChangeRankBy}>
      <option value='count'>Transaction count</option>
      <option value='value-settled-eur'>Settled value ~EUR</option>
      <option value='value-settled-usd'>Settled value ~USD</option>
      <option value='acceptance'>Acceptance rate</option>
    </select>
  )

  return (
    <div className='module count-by-merchant'>
      <label>
        Top merchants
        <span className='controls'>{rankSelector}</span>
      </label>
      {topShow.map(([mid, val, byStatus = {}]) => {
        return (
          <div key={mid} className={classnames({ count: true, merchant: true })}>
            <span className='merchant'>{pathOr(mid, [mid, 'name'], byID)}</span>
            <RankedValue val={val} rankBy={rankBy} />
            <InlineStatusSummary statusCounts={byStatus.value} />
          </div>
        )
      })}
      {viewMore && (
        /* TODO: Close with ESC key */ <div className='view-more'>
          <h1>
            Top merchants
            <span className='controls'>
              {rankSelector}
              <i className='fas fa-times clickable close' title='Hide' onClick={toggleViewMore} />
            </span>
          </h1>
          {/* TODO: Controls to switch mode */}
          <div className='content'>
            {top.map(([mid, val, byStatus = {}], n) => {
              return (
                <div key={mid} className={classnames({ count: true, merchant: true })}>
                  <span className='rank'>{n + 1}.</span>
                  <span className='merchant'>{pathOr(mid, [mid, 'name'], byID)}</span>
                  <RankedValue val={val} rankBy={rankBy} />
                  <InlineStatusSummary statusCounts={byStatus.value} />
                </div>
              )
            })}
          </div>
        </div>
      )}
      {moreCount > 0 && (
        <div className='count merchant more-indicator clickable' onClick={toggleViewMore}>
          {viewMore ? 'Hide' : 'See all'} ({moreCount} more)
        </div>
      )}
    </div>
  )
}
TopMerchants.propTypes = {
  stats_by_merch_id: PropTypes.object,
  // How many to display (rest is rolled into a "more" display...)
  displayCount: PropTypes.number,
  // map of visible merchants: {id : { id, registered_name }} - TODO: Replace with context
  merchantByID: PropTypes.object,
}

/** Displays a single state value of the given type, which controls its
 * numeric format. */
const RankedValue = ({ val = 0, rankBy = '' }) => (
  <span className='val'>
    {val == null ? (
      '0'
    ) : rankBy == 'value-settled-eur' ? (
      <AmountWithCurrency amount={val} currency='EUR' />
    ) : rankBy == 'value-settled-usd' ? (
      <AmountWithCurrency amount={val} currency='USD' />
    ) : rankBy == 'acceptance' ? (
      val.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
    ) : (
      val.toLocaleString()
    )}
  </span>
)
RankedValue.propTypes = {
  val: PropTypes.number,
  rankBy: PropTypes.string,
}

/* Displays a small, inline summary of status counts,
 * e.g. 435 ok | 123 failed | 362 other
 */
const InlineStatusSummary = ({ statusCounts = {} }) => {
  const ok = statusCounts.ok
  const failed = statusCounts.failed
  const other = reduce(
    (total, pair) => {
      return total + pair[1]
    },
    0,
    toPairs(omit(['ok', 'failed'], statusCounts))
  )
  return (
    <span className='count-by-status'>
      <span className='ok' title='Successful transactions'>
        <i className='fas fa-check' />
        <span className='val'>{ok || '0'}</span>
      </span>
      <span className='failed' title='Failed transactions'>
        <i className='fas fa-times' />
        <span className='val'>{failed || '0'}</span>
      </span>
      <span className='other' title='Transactions with other status (pending, 3ds, abandoned, etc)'>
        <i className='fas fa-ellipsis-h' />
        <span className='val'>{other || '0'}</span>
      </span>
    </span>
  )
}
InlineStatusSummary.propTypes = {
  statusCounts: PropTypes.object.isRequired,
}

// Sorts any two objects lexicographically by `.name`
const byName = (a, b) => {
  if (a && a.name && b && b.name) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  }
  return a < b ? -1 : a > b ? 1 : 0
}

const MerchantStatsLink = ({ filter = {} }) => {
  // If current filter has picked one or more merchants, use that merchant
  // in the stats link. Otherwise, use the first merchant visible to the current user.
  const { byID } = useOrgScope()
  const visibleMerchs = Object.keys(byID)
    .map((key) => byID[key])
    .filter((o) => o && o.type == TYPE_MERCHANT)
    .sort(byName) // {name, id}
  const firstVisibleMerchID = pathOr(null, [0, 'id'], visibleMerchs)
  const u = new URLSearchParams()
  if (filter.from) {
    u.append('from', filter.from)
  }
  if (filter.to) {
    u.append('to', filter.to)
  }
  const queryStr = u.toString()
  const merchantID = pathOr(firstVisibleMerchID, ['merchant_any', 0], filter)

  // If we don't have a merchant, output nothing
  if (!merchantID) {
    return null
  }

  return (
    <div className='module link-to-stats'>
      <Link to={`/merchant/${merchantID}/txn-stats?${queryStr}`}>
        <i className='fas fa-chart-bar' />
        <div>Stats & Reports</div>
      </Link>
    </div>
  )
}
MerchantStatsLink.propTypes = {
  filter: PropTypes.object.isRequired,
}

export default TxnsDashboardHeader
