import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders a contract id
 */
const ContractID = ({ contract_id }) => (
  <div className='id-container'>
    <div className='merchant_id' title='Merchant ID'>
      <span className='id'>{contract_id || '-'}</span>
    </div>
  </div>
)
ContractID.propTypes = {
  contract_id: PropTypes.string,
}

export default ContractID
