import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Helmet from 'react-helmet'
import { useAuth } from './auth'
import { isPSPViewer } from './auth-roles'

const Support = () => {
  const { roles } = useAuth()
  const hasPSPViewerRole = isPSPViewer(roles)
  return (
    <section className='support'>
      <Header />
      <Helmet>
        <title>Contact support- Canapay</title>
      </Helmet>
      <div className='content'>
        <div>
          <h1>Contact support</h1>
          <p>
            Please contact <a href='mailto:support@canapay.io'>support@canapay.io</a> for assistance
            or queries.
          </p>
          <p>
            If you are an existing client, please ensure that we add you to your own dedicated{' '}
            <strong>Twist channel</strong> for access to real-time support.
          </p>
          {!hasPSPViewerRole && (
            <p>
              <h1>API Reference</h1>
              <p>
                If you or your team are busy integrating with Canapay, you may find the API
                reference at:
              </p>
              <p>
                <a href='https://dev.canapay.io/' target='_blank' rel='noreferrer'>
                  API docs
                </a>
              </p>
            </p>
          )}
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Support
