import React, { useState, useEffect } from 'react'
import { getTransactions } from '../api'
import { useAuth } from '../auth'
import { isOperator, isController, isControllerNoMerchant, isPSPViewer } from '../auth-roles'
import { useInterval } from '../timing/use-interval'
import { path, propOr } from 'ramda'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { parseTransactionFilters, updateSearchParamsWithFilters } from './filters'
import Country from '../Country'
import TxnStatus from './TxnStatus'
import TxnID from './TxnID'
import DateTime from '../DateTime'
import DownloadCSVLink from './DownloadCSVLink'
import Header from '../Header'
import Footer from '../Footer'
import AmountWithCurrency from '../AmountWithCurrency'
import Helmet from 'react-helmet'
import TxnsDashboardHeader from './TxnsDashboardHeader'

/* Filters */
import TxnIDFilter from './filters/TxnIDFilter'
import CardFilter from './filters/CardFilter'
import StatusFilter from './filters/StatusFilter'
import TimeFilter from './filters/TimeFilter'
import TimeSortBy from './sorting/TimeSortBy'
import CountryFilter from './filters/CountryFilter'
import PSPFilter from './filters/PSPFilter'
import MerchantFilter from './filters/MerchantFilter'
import AmountFilter from './filters/AmountFilter'
import CurrencyFilter from './filters/CurrencyFilter'
import EmailFilter from './filters/EmailFilter'
import { useOrgScope } from '../org-scope'
import CardRef from './CardRef'
import { SORT_BY_TIME_UPDATED_AT } from './filters'

const LIVE_UPDATE_INTERVAL = 10000 // ms
const ONE_WEEK = 1000 * 60 * 60 * 24 * 7 // ms

/** ListTxns renders a high-level transaction listing which displays a
 * filtered, paginated list of transactions. */
const ListTxns = () => {
  // User auth
  const { token, roles } = useAuth()
  const { byID } = useOrgScope()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles)
  const isUserControllerNoMerchant = isControllerNoMerchant(roles)
  const isUserPSPViewer = isPSPViewer(roles)

  // console.log(JSON.stringify(roles, null, 2), '   :   ', userHasContextContractID)
  // We either poll continuously in the background, or not,
  // depending on user setting.
  const [updateLive, setUpdateLive] = useState(true)

  // Transaction data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [txns, setTxns] = useState()

  // Parse parameters into filter object (which drives filters UI state)
  // Note: We are using the URL as the "state" here, and updates
  // perform a history replacement (so as not to mess up "back" button
  // behaviour too much - though we may want to apply a heuristic like
  // small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseTransactionFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // How we fetch transactions from the back-end
  const fetchTxns = async () => {
    setLoading(true)
    setFailed(false)
    try {
      const result = await getTransactions(token, { filter })
      setTxns(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  const allowUpdateLive =
    (filter.from == 'm1h' && filter.to == 'now') ||
    (filter.from == 'm24h' && filter.to == 'now') ||
    (filter.from == 'sd' && filter.to == 'ed')

  // (filter.from == 'm7d' && filter.to == 'now') ||
  // (filter.from == 'm30d' && filter.to == 'now') ||
  // (filter.from == 'sw' && filter.to == 'ew')

  // Short-term solution until we have proper pagination: This
  // powers a "Shot xxx more" button at the bottom, and appends it
  // to the current set. Importantly, it also disables live loading,
  // otherwise things would get interesting. And of course, if you scroll
  // to the top and change filters, you have to scroll down again and "Load more"
  // if you want to, i.e. data is replaced.
  const fetchMoreTxns = async (page) => {
    setUpdateLive(false) // To 'freeze' current view - TODO: Might cause re-fetch....
    setLoading(true)
    setFailed(false)
    try {
      const addTxns = await getTransactions(token, { filter, page })
      // Append data. Note: This totally ignores any 'newer' transactions that
      // may have appeared, so we also don't update top-level counts.
      const newTxns = { ...txns, results: [...txns.results, ...addTxns.results] }
      setTxns(newTxns)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // How many more txns are available to load?
  const loadableCount = Math.min(
    100,
    txns && txns.results && txns.results.length && txns.page && txns.page.total_count
      ? txns.page.total_count - txns.results.length
      : 100
  )

  // Event handler for 'Show xxx more' button that was clicked
  const handleLoadMore = () => {
    const lastID =
      txns && txns.results && txns.results.length
        ? txns.results[txns.results.length - 1].transaction_id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreTxns(page)
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    // Note: It might be useful to the user to auto-disable live updates
    // when applying a filter, but other times it's annoying.
    setUpdateLive(false)
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(async () => {
    await fetchTxns()
  }, [urlParams.toString()])

  // Then, poll these same results at an interval, based on whether "Update live" is checked
  // As a hack, for non-live updates, set a long (but non-infinite, which breaks) interval, e.g. a week
  useInterval(
    async () => {
      if (allowUpdateLive) {
        await fetchTxns()
      }
    },
    updateLive ? LIVE_UPDATE_INTERVAL : ONE_WEEK,
    false
  )

  // Every time 'Update live' is toggled to true, fetch txns
  const handleToggleUpdateLive = (e) => {
    setUpdateLive(e.target.checked)
    if (e.target.checked) {
      fetchTxns()
    }
  }

  return (
    <section className='transactions'>
      <Header />
      <Helmet>
        <title>Transactions - Canapay</title>
      </Helmet>
      <div className='content'>
        {loading && !txns && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && txns && txns.results && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>{txns.results.length.toLocaleString()}</strong>
                {txns.page && txns.results.length !== txns.page.total_count && (
                  <span>
                    of
                    <strong className='count total_count'>
                      {txns.page.total_count.toLocaleString()}
                    </strong>
                  </span>
                )}
                {loading && <span className='loading'>updating...</span>}
              </span>
              {allowUpdateLive && (
                <span className='update-live'>
                  <label>
                    <input type='checkbox' checked={updateLive} onChange={handleToggleUpdateLive} />
                    Update live
                  </label>
                </span>
              )}
              <span className='filter cust_email'>
                <EmailFilter filter={filter} setFilter={setFilter} />
              </span>
              <DownloadCSVLink displayCount={path(['page', 'total_count'], txns)} filter={filter} />
            </header>
            <TxnsDashboardHeader
              merchantByID={byID}
              stats={txns.stats}
              txns={txns.results}
              filter={filter}
              setFilter={setFilter}
            />

            <p className='note'>
              This view allows you to monitor and control Transactions, where each is a{' '}
              <strong>roll-up of all the events during that transaction&apos;s lifecycle</strong>. A
              Transaction that was Attempted, Approved, and later Refunded is represented as a{' '}
              <strong>single line item</strong>. Merchant settlement is, however, based on
              individual transaction events - see <strong>Stats &amp; Reports</strong>.
            </p>
            <table className='txns'>
              <thead>
                <tr>
                  {!updateLive && <th className='num'>#</th>}
                  <th className='time'>
                    <h6>
                      Time
                      <TimeSortBy filter={filter} setFilter={setFilter} />
                    </h6>
                    <TimeFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='country'>
                    <h6>Country</h6>
                    <CountryFilter filter={filter} setFilter={setFilter} />
                  </th>
                  {!isUserControllerNoMerchant && !isUserPSPViewer && (
                    <th className='merchant'>
                      <h6>Merchant</h6>
                      {byID && (
                        <MerchantFilter filter={filter} setFilter={setFilter} merchantByID={byID} />
                      )}
                    </th>
                  )}

                  {(isUserOperator || isUserController) && (
                    <th className='psp'>
                      <h6>PSP</h6>
                      <PSPFilter filter={filter} setFilter={setFilter} />
                    </th>
                  )}
                  <th className='id'>
                    <h6>ID</h6>
                    <TxnIDFilter
                      filter={filter}
                      setFilter={setFilter}
                      isOperatorOrController={isUserOperator || isUserController}
                    />
                  </th>
                  <th className='status'>
                    <h6>Status</h6>
                    <StatusFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='totreq'>
                    <h6>
                      Amt in
                      <CurrencyFilter filter={filter} setFilter={setFilter} />
                    </h6>
                    <AmountFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='pan'>
                    <h6>Card</h6>
                    <CardFilter filter={filter} setFilter={setFilter} />
                  </th>
                  {/*
                  <th className='kyc'>
                    <h6>KYC</h6>
                  </th>
                  */}
                  <th className='actions' />
                </tr>
              </thead>
              <tbody>
                {txns.results.map((txn, i) => {
                  const detailURI = `/transaction/${txn.transaction_id}`
                  const onCellClick = () => {
                    // Don't navigate if user is selecting text from cell
                    // TODO: How to trap and ignore double-click? (word-selection)
                    if (window.getSelection().toString()) {
                      return
                    }
                    history.push(detailURI)
                  }
                  return (
                    <tr className='txn' key={txn.transaction_id}>
                      {!updateLive && (
                        <td className='num clickable' onClick={onCellClick}>
                          {i + 1}.
                        </td>
                      )}
                      <td className='time clickable' onClick={onCellClick}>
                        <DateTime
                          at={
                            filter && filter.sort_by == SORT_BY_TIME_UPDATED_AT
                              ? txn.updated_at
                              : txn.created_at
                          }
                        />
                      </td>
                      <td className='country clickable' onClick={onCellClick}>
                        <Country iso={path(['meta', 'card_meta', 'issuing_country'], txn)} />
                      </td>
                      {!isUserControllerNoMerchant && !isUserPSPViewer && (
                        <td className='merchant clickable' onClick={onCellClick}>
                          {txn.merchant_name || '-'}
                        </td>
                      )}
                      {(isUserOperator || isUserController) && (
                        <td className='psp-id clickable' onClick={onCellClick}>
                          {txn.psp_id || '-'}
                        </td>
                      )}

                      <td className='id clickable' onClick={onCellClick}>
                        <TxnID transaction_id={txn.transaction_id} order_ref={txn.order_ref} />
                      </td>
                      <td className='status'>
                        <TxnStatus {...txn} />
                      </td>
                      <td className='totreq'>
                        <AmountWithCurrency {...txn.total_requested} />
                      </td>
                      <td className='pan'>
                        <CardRef {...propOr({}, 'card', txn)} />
                      </td>
                      {/*
                      <td className='kyc'>
                        <KYCBadge status={pathOr('', ['kyc'], txn)} />
                      </td>
                      */}
                      <td className='actions'>
                        <Link to={detailURI}>Detail</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <footer className='actions'>
              {txns.page && txns.results.length !== txns.page.total_count && (
                <>
                  <button onClick={handleLoadMore} disabled={loading && !updateLive}>
                    {loading && !updateLive ? 'Loading...' : `Show ${loadableCount} more`}
                  </button>
                  {' or '}
                </>
              )}
              <DownloadCSVLink displayCount={path(['page', 'total_count'], txns)} filter={filter} />
            </footer>
          </div>
        )}
        {!failed && txns && txns.results && txns.results.length === 0 && (
          <div className='no-results'>There are no transactions to show</div>
        )}
        <Footer />
      </div>
    </section>
  )
}

export default ListTxns
