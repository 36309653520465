import React from 'react'
import PropTypes from 'prop-types'
import DateTime from '../DateTime'
import { Link } from 'react-router-dom'
import TxnStatus from './TxnStatus'

const CardholderHistory = ({ history, success, email }) => {
  const hist = history || {}
  return (
    <div>
      <h2>History</h2>
      <table className='history'>
        <tbody>
          <tr className='first_seen'>
            <td className='key'>First Seen</td>
            <td className='val'>
              <DateTime at={hist.first_seen} />
            </td>
          </tr>
          <tr className='last_seen'>
            <td className='key'>Last Seen</td>
            <td className='val'>
              <DateTime at={hist.last_seen} />
            </td>
          </tr>
          <tr className='success_count'>
            <td className='key'>
              Previous <TxnStatus status='ok' /> at time of transaction
            </td>
            <td className='val'>
              {success}
              {email && (
                <Link
                  className='search-txns'
                  title='View previous successful transactions for this customer'
                  to={`/transactions?status_any=ok&email=${encodeURIComponent(email)}`}
                >
                  <i className='fas fa-search' />
                </Link>
              )}
            </td>
          </tr>
          <tr className='success_count'>
            <td className='key'>
              Total <TxnStatus status='ok' />
            </td>
            <td className='val'>
              {hist.total_success}
              {email && (
                <Link
                  className='search-txns'
                  title='View successful transactions for this customer'
                  to={`/transactions?status_any=ok&email=${encodeURIComponent(email)}`}
                >
                  <i className='fas fa-search' />
                </Link>
              )}
            </td>
          </tr>
          <tr className='failure_count'>
            <td className='key'>
              Total <TxnStatus status='failed' />
            </td>
            <td className='val'>
              {hist.total_failed}
              {email && (
                <Link
                  className='search-txns'
                  title='View failed transactions for this customer'
                  to={`/transactions?status_any=failed&email=${encodeURIComponent(email)}`}
                >
                  <i className='fas fa-search' />
                </Link>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

CardholderHistory.propTypes = {
  history: PropTypes.object.isRequired,
  email: PropTypes.string,
  success: PropTypes.number,
}

export default CardholderHistory
