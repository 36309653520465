import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import DateTimePicker from 'react-datetime-picker'
import { useDebouncedCallback } from 'use-debounce'
import { concretiseDate } from '../filters'
import DateRange from '../../DateRange'
/** Allows the user to select a time range. This is a single control that
 * effectively sets two filter values (from, to), either to a predefined,
 * dynamic time range as per `dateTimeProducer` in `filter.js`, or
 * a `custom` range where the user picks a start and end date/time.
 */
const TimeFilter = ({ filter, setFilter }) => {
  // Map `from` and `to` to a single value for select
  const from = filter.from
  const to = filter.to
  // Map from filter to classifier
  const classifier =
    !from && !to
      ? ''
      : from == 'm1h' && to == 'now'
      ? 'past1h'
      : from == 'm24h' && to == 'now'
      ? 'past24h'
      : from == 'm7d' && to == 'now'
      ? 'past7d'
      : from == 'm30d' && to == 'now'
      ? 'past30d'
      : from == 'm60d' && to == 'now'
      ? 'past60d'
      : from == 'm90d' && to == 'now'
      ? 'past90d'
      : from == 'm180d' && to == 'now'
      ? 'past180d'
      : from == 'sd' && to == 'ed'
      ? 'thisday'
      : from == 'sw' && to == 'ew'
      ? 'thisweek'
      : from == 'sm' && to == 'em'
      ? 'thismonth'
      : from == 'sy' && to == 'ey'
      ? 'thisyear'
      : from == 'syd' && to == 'eyd'
      ? 'yesterday'
      : from == 'sm3d' && to == 'eyd'
      ? 'prev3days'
      : from == 'slm' && to == 'sm'
      ? 'lastmonth'
      : from == 'scbw' && to == 'ecbw'
      ? 'currentbillweek'
      : from == 'spbw' && to == 'epbw'
      ? 'recentbillweek'
      : from == 'sppbw' && to == 'eppbw'
      ? 'prevbillweek'
      : 'custom'

  // Classifier selected, map to filter
  const classifierChanged = (e) => {
    const c = e.target.value
    if (c == '') {
      setFilter({ ...filter, from: null, to: null })
    } else if (c == 'past1h') {
      setFilter({ ...filter, from: 'm1h', to: 'now' })
    } else if (c == 'past24h') {
      setFilter({ ...filter, from: 'm24h', to: 'now' })
    } else if (c == 'past7d') {
      setFilter({ ...filter, from: 'm7d', to: 'now' })
    } else if (c == 'past30d') {
      setFilter({ ...filter, from: 'm30d', to: 'now' })
    } else if (c == 'past60d') {
      setFilter({ ...filter, from: 'm60d', to: 'now' })
    } else if (c == 'past90d') {
      setFilter({ ...filter, from: 'm90d', to: 'now' })
    } else if (c == 'past180d') {
      setFilter({ ...filter, from: 'm180d', to: 'now' })
    } else if (c == 'thisday') {
      setFilter({ ...filter, from: 'sd', to: 'ed' })
    } else if (c == 'thisweek') {
      setFilter({ ...filter, from: 'sw', to: 'ew' })
    } else if (c == 'thismonth') {
      setFilter({ ...filter, from: 'sm', to: 'em' })
    } else if (c == 'thisyear') {
      setFilter({ ...filter, from: 'sy', to: 'ey' })
    } else if (c == 'yesterday') {
      setFilter({ ...filter, from: 'syd', to: 'eyd' })
    } else if (c == 'prev3days') {
      setFilter({ ...filter, from: 'sm3d', to: 'eyd' })
    } else if (c == 'lastmonth') {
      setFilter({ ...filter, from: 'slm', to: 'sm' })
    } else if (c == 'currentbillweek') {
      setFilter({ ...filter, from: 'scbw', to: 'ecbw' })
    } else if (c == 'recentbillweek') {
      setFilter({ ...filter, from: 'spbw', to: 'epbw' })
    } else if (c == 'prevbillweek') {
      setFilter({ ...filter, from: 'sppbw', to: 'eppbw' })
    } else if (c == 'custom') {
      // Set initial date range - 1 week?
      const now = DateTime.local()
      // If we already have dates, we use those so user can just tweak
      // To do that, we have to first make them concrete, i.e. filter could say `sd` (start of day)
      const from = filter.from
        ? concretiseDate(filter.from)
        : now.minus({ days: 7 }).startOf('day').toISO()
      const to = filter.to ? concretiseDate(filter.to) : now.endOf('day').toISO()
      setFilter({ ...filter, from, to })
    }
  }

  // Track changes to custom date/time in local state - pre-populating
  // from URL parameters if we're in 'custom' mode already
  const [customFrom, setCustomFrom] = useState(null) // Date obj
  const [customTo, setCustomTo] = useState(null) // Date obj

  // Update parent filter from custom from/to in state
  const updateParentUsingCustomDebounced = useDebouncedCallback(() => {
    setFilter({ ...filter, from: customFrom.toISOString(), to: customTo.toISOString() })
  }, 1000)

  // Custom date changed, update filter
  const handleCustomChanged = (fromTo) => (date) => {
    if (fromTo === 'from') {
      setCustomFrom(date)
      if (customTo == null) {
        setCustomTo(new Date(filter.to))
      }
    } else if (fromTo === 'to') {
      setCustomTo(date)
      if (customFrom == null) {
        setCustomFrom(new Date(filter.from))
      }
    }
    updateParentUsingCustomDebounced.callback()
  }

  return (
    <div className='filter date'>
      <select value={classifier} onChange={classifierChanged}>
        <option value=''>All time</option>
        <optgroup label='Rolling'>
          <option value='past1h'>Past hour</option>
          <option value='past24h'>Past 24 hours</option>
          <option value='past7d'>Past 7 days</option>
          <option value='past30d'>Past 30 days</option>
          <option value='past60d'>Past 60 days</option>
          <option value='past90d'>Past 90 days</option>
          <option value='past180d'>Past 180 days</option>
        </optgroup>
        <optgroup label='Fixed'>
          <option value='thisday'>Today</option>
          <option value='yesterday'>Yesterday</option>
          <option value='prev3days'>Previous 3 days</option>
          <option value='thisweek'>This week</option>
          <option value='thismonth'>This month</option>
          <option value='lastmonth'>Last month</option>
          <option value='thisyear'>This year</option>
        </optgroup>
        <optgroup label='Billing cycle'>
          <option value='currentbillweek'>Current (week in progress)</option>
          <option value='recentbillweek'>Recent complete (last week)</option>
          <option value='prevbillweek'>Previous complete (2 weeks ago)</option>
        </optgroup>
        <option value='custom'>Custom dates</option>
      </select>
      {classifier != 'custom' && (
        <div className='tinysuffix'>
          <DateRange from={concretiseDate(filter.from)} to={concretiseDate(filter.to)} />
        </div>
      )}
      {classifier == 'custom' && (
        <div className='custom'>
          <div className='dateinp from'>
            <label>Fr</label>
            <DateTimePicker
              format='y-MM-dd HH:mm:ss'
              clearIcon={null}
              calendarIcon={null}
              value={customFrom || new Date(concretiseDate(filter.from))}
              maxDetail='second'
              onChange={handleCustomChanged('from')}
            />
          </div>
          <div className='dateinp from'>
            <label>To</label>
            <DateTimePicker
              format='y-MM-dd HH:mm:ss'
              clearIcon={null}
              calendarIcon={null}
              value={customTo || new Date(concretiseDate(filter.to))}
              maxDetail='second'
              onChange={handleCustomChanged('to')}
            />
          </div>
          {/*
          <input type='datetime-local' value={from} onChange={customChanged('from')} />
          <input type='datetime-local' value={to} onChange={customChanged('to')} />
          */}
        </div>
      )}
    </div>
  )
}

TimeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default TimeFilter
