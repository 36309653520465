import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  pathOr,
  values,
  propOr,
  prop,
  sortBy,
  compose,
  toLower,
  includes,
  propEq,
  filter as rFilter,
  all,
  uniq,
} from 'ramda'

const merchantsLabel = 'Merchants'
const enabledMerchantsLabel = 'Merchants (Active)'
const disabledMerchantsLabel = 'Merchants (Suspended)'

/**
 * MerchantFilter lets the user pick one or more merchants.
 */
const MerchantFilter = ({ filter, setFilter, merchantByID = {} }) => {
  const allMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'merchant')), values)(merchantByID)
    ) || []
  const activeMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('active', true)))(allMerchants)
    ) || []
  const suspendedMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('active', false)))(allMerchants)
    ) || []
  const availableContracts =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'contract')), values)(merchantByID)
    ) || []

  const onlyActiveMerchants = allMerchants.length === activeMerchants.length
  const selected = filter.merchant_any || []

  // The sequence of merchant IDs driving the UI (one per line)
  const uiseq = selected && selected.length > 0 ? selected : ['']

  const onChangeAtIndex = (index) => (e) => {
    const newSel = [...filter.merchant_any]
    const org = merchantByID[e.target.value]
    if (org && org.type === 'contract') {
      org?.children
        ?.filter((o) => o.type === 'merchant')
        ?.forEach((o, idx) => {
          newSel[index + idx] = o.id
        })
    } else {
      newSel[index] = e.target.value
    }
    setFilter({ ...filter, merchant_any: uniq(newSel) })
  }

  const onRemoveAtIndex = (index) => () => {
    setFilter({
      ...filter,
      merchant_any: [
        ...filter.merchant_any.slice(0, index),
        ...filter.merchant_any.slice(index + 1),
      ],
    })
  }

  const onAddOption = () => {
    setFilter({ ...filter, merchant_any: [...filter.merchant_any, ''] })
  }

  return (
    <div className='filter merchants'>
      {uiseq.map((sel, i) => {
        // If this is the last item, produce a set of available options that
        // excludes any selected options already. Note: Expected for selected options
        // to always be small, hence inefficient list traversal.
        // const availableMerchantsGivenSelected = [...allMerchants].filter(
        //   ({ id }) => id == sel || !includes(id, selected)
        // )
        const availableActiveMerchantsGivenSelected = [...activeMerchants].filter(
          ({ id }) => id == sel || !includes(id, selected)
        )
        const availableSuspendedMerchantsGivenSelected = [...suspendedMerchants].filter(
          ({ id }) => id == sel || !includes(id, selected)
        )

        const availableContractsGivenSelected = [...availableContracts].filter(({ children }) => {
          // go through selected merchants - find parents and if any are contracts then check if all children are selected
          // only do this if we have contracts in the scope
          // [1,2,3] = sel [1,2]
          if (children && children.length !== 0) {
            return !all((child) => includes(child.id, selected))(children)
          }
          return false
        })

        return (
          <div
            className={classnames({ 'merchant-option': true, unchosen: !sel && uiseq.length > 1 })}
            key={sel}
          >
            {i > 0 && <span className='or'>or</span>}
            {/* Only the last option is editable */}
            {i == uiseq.length - 1 ? (
              <select value={sel} onChange={onChangeAtIndex(i)} disabled={allMerchants.length <= 1}>
                {selected.length <= 1 ? (
                  <option value=''>All Merchants</option>
                ) : sel === '' ? (
                  <option value='' disabled={true}>
                    Please select one
                  </option>
                ) : null}
                {availableContracts &&
                availableContracts.length > 1 &&
                availableContractsGivenSelected &&
                availableContractsGivenSelected.length > 0 &&
                availableActiveMerchantsGivenSelected &&
                availableActiveMerchantsGivenSelected.length !== 0 ? (
                  <optgroup
                    key={'merchants-active'}
                    label={onlyActiveMerchants ? merchantsLabel : enabledMerchantsLabel}
                  >
                    {availableActiveMerchantsGivenSelected.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                ) : availableActiveMerchantsGivenSelected &&
                  availableActiveMerchantsGivenSelected.length !== 0 &&
                  !onlyActiveMerchants ? (
                  <optgroup
                    key={'merchants-active'}
                    label={onlyActiveMerchants ? merchantsLabel : enabledMerchantsLabel}
                  >
                    {availableActiveMerchantsGivenSelected.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  onlyActiveMerchants &&
                  availableActiveMerchantsGivenSelected.map((m) => (
                    <option value={m.id} key={m.id}>
                      {m.name}
                    </option>
                  ))
                )}
                {availableContracts &&
                  availableContracts.length > 1 &&
                  availableContractsGivenSelected &&
                  availableContractsGivenSelected.length > 0 && (
                    <optgroup key={'contracts'} label={'Contracts'}>
                      {availableContractsGivenSelected.map((m) => (
                        <option value={m.id} key={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </optgroup>
                  )}
                {availableContracts &&
                availableContracts.length > 1 &&
                availableContractsGivenSelected &&
                availableContractsGivenSelected.length > 0 &&
                availableSuspendedMerchantsGivenSelected &&
                availableSuspendedMerchantsGivenSelected.length !== 0 ? (
                  <optgroup key={'merchants-inactive'} label={disabledMerchantsLabel}>
                    {availableSuspendedMerchantsGivenSelected.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  availableSuspendedMerchantsGivenSelected &&
                  availableSuspendedMerchantsGivenSelected.length !== 0 && (
                    <optgroup key={'merchants-inactive'} label={disabledMerchantsLabel}>
                      {availableSuspendedMerchantsGivenSelected.map((m) => (
                        <option value={m.id} key={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </optgroup>
                  )
                )}
              </select>
            ) : (
              <span className='selected'>{pathOr(sel, [sel, 'name'], merchantByID)}</span>
            )}
            {/* If there are > 1 selected options, any can be removed */}
            {selected.length > 1 && (
              <button
                className='remove'
                onClick={onRemoveAtIndex(i)}
                title='Remove this merchant from selection'
              >
                <i className='fas fa-times' />
              </button>
            )}
            {/* If the last option is defined, button to add more */}
            {i === uiseq.length - 1 &&
              sel &&
              availableActiveMerchantsGivenSelected &&
              availableSuspendedMerchantsGivenSelected &&
              availableActiveMerchantsGivenSelected.length +
                availableSuspendedMerchantsGivenSelected.length >
                1 && (
                <button
                  className='add'
                  onClick={onAddOption}
                  title='Add another merchant to your selection'
                >
                  <i className='fas fa-plus' />
                </button>
              )}
          </div>
        )
      })}
    </div>
  )
}

MerchantFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  merchantByID: PropTypes.object.isRequired,
}

export default MerchantFilter
