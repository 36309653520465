import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { isOperator } from '../auth-roles'
import { getPSPs } from '../api'
import { useOrgScope, OrgScopeRequired, TYPE_MERCHANT, TYPE_CONTRACT, TYPE_PSP } from '../org-scope'

/** Drop-down selector to pick an org visible to the current user, of the given type. */
const OrgSelector = ({ orgType = TYPE_MERCHANT, value, onChange }) => (
  <OrgScopeRequired>
    <Selector orgType={orgType} value={value} onChange={onChange} />
  </OrgScopeRequired>
)

OrgSelector.propTypes = {
  orgType: PropTypes.oneOf([TYPE_MERCHANT, TYPE_CONTRACT, TYPE_PSP]),
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const byName = (a, b) => {
  if (a && a.name && b && b.name) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  }
  return a < b ? -1 : a > b ? 1 : 0
}

const byEmbeddedID = (a, b) => {
  if (a && a.id && b && b.id) {
    return a.id.toLowerCase() < b.id.toLowerCase()
      ? -1
      : a.id.toLowerCase() > b.id.toLowerCase()
      ? 1
      : 0
  }
  return a < b ? -1 : a > b ? 1 : 0
}

const Selector = ({ orgType, value, onChange }) => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const change = onChange != null ? (e) => onChange(e.target.value) : undefined

  // Selecting PSP - special, operator-only mode
  // Note: We may wish to rather move this into the org scope tree in general (on the back-end)
  const [psps, setPsps] = useState({ loading: false, failed: null, data: [] })
  const fetchPsps = async () => {
    if (isUserOperator) {
      setPsps({ ...psps, failed: null, loading: true })
      try {
        const data = await getPSPs(token)
        setPsps({ ...psps, failed: null, loading: false, data: data.sort(byEmbeddedID) })
      } catch (failed) {
        console.warn('Failed to load PSPs', failed)
        setPsps({ ...psps, failed, loading: false })
      }
    }
  }
  useEffect(() => {
    fetchPsps()
  }, [])

  if (orgType === TYPE_PSP && (!psps.data || !psps.data.length) && psps.loading) {
    return <span className='loading'>Loading...</span>
  }
  if (orgType === TYPE_PSP && psps.failed) {
    return <span className='error'>Failed to load</span>
  }

  if (orgType === TYPE_PSP) {
    return (
      <select
        className={classnames({ org: true, [orgType]: orgType })}
        value={value || ''}
        onChange={change}
      >
        {!value && <option value=''>Select acquirer</option>}
        {psps.data.map((psp) => (
          <option key={psp.id} value={psp.id}>
            {psp.id}
          </option>
        ))}
        {/* TODO: If not present in this scope (e.g. type), just display by ID */}
      </select>
    )
  }

  // Selecting merchant or contract
  const { byID } = useOrgScope()
  const ms = Object.keys(byID)
    .map((key) => byID[key])
    .filter((o) => o && o.type == orgType)
    .sort(byName) // {name, id}
  return (
    <select
      className={classnames({ org: true, [orgType]: orgType })}
      value={value || ''}
      onChange={change}
    >
      {!value && <option value=''>Select {orgType}</option>}
      {ms.map((m) => (
        <option key={m.id} value={m.id}>
          {m.name}
        </option>
      ))}
      {/* TODO: If not present in this scope (e.g. type), just display by ID */}
    </select>
  )
}

Selector.propTypes = {
  orgType: PropTypes.oneOf([TYPE_MERCHANT, TYPE_CONTRACT, TYPE_PSP]),
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default OrgSelector
