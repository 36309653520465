import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getCASContent } from '../api'

const TxnPSPResponse = ({ txnId, casId, token }) => {
  const [expanded, setExpanded] = useState(false)
  const [response, setResponse] = useState('')

  const expand = async () => {
    setExpanded(true)
    const res = await getCASContent(token, { txnId, casId })
    if (res?.format === 'json') {
      const raw = JSON.parse(res?.raw_response)
      res.raw_response = raw
    }
    setResponse(res)
  }

  const title = response ? `${casId} ${response?.type} ${response.at}` : `${casId}`

  return !expanded ? (
    <div className={'txn-req-json'}>
      <div className={'collapsed'} onClick={() => expand()}>
        {title}
      </div>
    </div>
  ) : (
    <div className='txn-req-json'>
      <div className={'expanded'} onClick={() => setExpanded(false)}>
        {title}
      </div>
      <JSONInput
        placeholder={response?.raw_response || { Message: 'Loading' }}
        locale={locale}
        theme='light_mitsuketa_tribute'
        colors={{ background: '#f3f3f3' }}
        width='100%'
      />
    </div>
  )
}

TxnPSPResponse.propTypes = {
  txnId: PropTypes.string.isRequired,
  casId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

export default TxnPSPResponse
